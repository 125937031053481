/*
 *  Import views
 */
import FormView from './views/form'
import FormsView from './views/forms'
import DashboardView from './views/dashboard'
import TemplatesView from './views/templates'
import ReportView from './views/reports'
import LoginView from './views/login'
import OrgView from './views/organisation'
import CreateView from './views/create'
import InspectView from './views/inspect'
import NotFound from './views/notfound'
import Error from './views/error'
import Loading from './views/loading'
import Onboarding from './views/onboarding'
import Demo from './views/demo'
import ProductCompareView from './views/product_comparison'
import LatenttiAdminView from './views/admin'
import AcceptInviteView from './views/acceptinvite'

/*
 *  Viewstates icons can be found here: https://material.io/resources/icons/?style=baseline
 */
const Config = {
  viewstates: [
    {
      path: "form",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_FORM",  //Remember to create a viewstate below and link it here.
      view: FormView,               //View as a React Component
      navigation: false,             //Show up in navigationbar, params below required if this is true
      icon: "dashboard",                 //Material.io icon
      label: "Form",                //Label for navigation
      windowTitle: "Mutko SelfService", //Window title
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_FORM"
      ]
    },
    {
      path: "login",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_LOGIN",  //Remember to create a viewstate below and link it here.
      view: LoginView,               //View as a React Component
      navigation: false,             //Show up in navigationbar, params below required if this is true
      icon: "account",                 //Material.io icon
      label: "Login",                //Label for navigation
      header: false,
      sidebar: false,
      windowTitle: "Mutko SelfService", //Window title
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_LOGIN"
      ]
    },
    {
      path: "create",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_CREATE",  //Remember to create a viewstate below and link it here.
      view: CreateView,               //View as a React Component
      navigation: false,             //Show up in navigationbar, params below required if this is true
      icon: "dashboard",                 //Material.io icon
      label: "Create Form",                //Label for navigation
      header: true,
      sidebar: true,
      windowTitle: "Mutko SelfService", //Window title
      requireLogin: true,
      requireOrganisation: true,
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_CREATE",
        "VIEWSTATE_TEMPLATES"
      ]
    },
    {
      path: "dashboard",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_DASHBOARD",  //Remember to create a viewstate below and link it here.
      view: DashboardView,               //View as a React Component
      navigation: false,             //Show up in navigationbar, params below required if this is true
      icon: "dashboard",                 //Material.io icon
      label: "Dashboard",                //Label for navigation
      header: true,
      sidebar: true,
      windowTitle: "Mutko SelfService", //Window title
      requireLogin: true,
      requireOrganisation: true,
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_DASHBOARD"
      ]
    },
    {
      path: "",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_TEMPLATES",  //Remember to create a viewstate below and link it here.
      view: TemplatesView,               //View as a React Component
      navigation: true,             //Show up in navigationbar, params below required if this is true
      icon: "description",                 //Material.io icon
      label: "Templates",                //Label for navigation
      header: true,
      sidebar: true,
      windowTitle: "Mutko SelfService", //Window title
      requireLogin: true,
      requireOrganisation: true,
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_TEMPLATES",
        "VIEWSTATE_CREATE"
      ]
    },
    {
      path: "forms",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_FORMS",  //Remember to create a viewstate below and link it here.
      view: FormsView,               //View as a React Component
      navigation: true,             //Show up in navigationbar, params below required if this is true
      icon: "assignment",                 //Material.io icon
      label: "Forms",                //Label for navigation
      header: true,
      sidebar: true,
      windowTitle: "Mutko SelfService", //Window title
      requireLogin: true,
      requireOrganisation: true,
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_FORMS",
        "VIEWSTATE_INSPECT"
      ]
    },
    {
      path: "reports",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_REPORTS",  //Remember to create a viewstate below and link it here.
      view: ReportView,               //View as a React Component
      navigation: true,             //Show up in navigationbar, params below required if this is true
      icon: "assessment",                 //Material.io icon
      label: "Reports",                //Label for navigation
      header: true,
      sidebar: true,
      windowTitle: "Mutko SelfService", //Window title
      requireLogin: true,
      requireOrganisation: true,
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_REPORTS"
      ]
    },
    {
      path: "organisation",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_ORGANISATION",  //Remember to create a viewstate below and link it here.
      view: OrgView,               //View as a React Component
      navigation: true,             //Show up in navigationbar, params below required if this is true
      icon: "business",                 //Material.io icon
      label: "Organisation",                //Label for navigation
      header: true,
      sidebar: true,
      windowTitle: "Mutko SelfService", //Window title
      requireLogin: true,
      requireOrganisation: true,
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_ORGANISATION"
      ]
    },
    {
      path: "latentti-admin",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_LATENTTI_ADMIN",  //Remember to create a viewstate below and link it here.
      view: LatenttiAdminView,               //View as a React Component
      navigation: true,             //Show up in navigationbar, params below required if this is true
      icon: "build",                 //Material.io icon
      label: "Admin",                //Label for navigation
      header: true,
      sidebar: true,
      windowTitle: "Mutko SelfService", //Window title
      requireLogin: true,
      requireLatenttiAdmin: true,
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_LATENTTI_ADMIN"
      ]
    },
    {
      path: "invoices",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_INVOICES",  //Remember to create a viewstate below and link it here.
      view: CreateView,                 //View as a React Component
      navigation: false,                 //Show up in navigationbar, params below required if this is true
      icon: "payment",                  //Material.io icon
      label: "Invoices",                //Label for navigation
      header: true,
      sidebar: true,
      windowTitle: "Mutko SelfService", //Window title
      requireLogin: true,
      requireOrganisation: true,
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_INVOICES"
      ]
    },
    {
      path: "inspect",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_INSPECT",  //Remember to create a viewstate below and link it here.
      view: InspectView,               //View as a React Component
      navigation: false,               //Show up in navigationbar, params below required if this is true
      icon: "payment",                 //Material.io icon
      label: "Inspect",                //Label for navigation
      header: true,
      sidebar: true,
      windowTitle: "Mutko SelfService", //Window title
      requireLogin: true,
      requireOrganisation: true,
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_INSPECT"
      ]
    },
    {
      path: "invite",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_ACCEPT_INVITE",  //Remember to create a viewstate below and link it here.
      view: AcceptInviteView,               //View as a React Component
      navigation: false,               //Show up in navigationbar, params below required if this is true
      icon: "payment",                 //Material.io icon
      label: "Inspect",                //Label for navigation
      header: true,
      sidebar: true,
      windowTitle: "Mutko SelfService", //Window title
      requireLogin: true,
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_ACCEPT_INVITE"
      ]
    },
    {
      path: "product-comparison",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_PRODUCT_COMPARE",  //Remember to create a viewstate below and link it here.
      view: ProductCompareView,               //View as a React Component
      navigation: false,             //Show up in navigationbar, params below required if this is true
      icon: "dashboard",                 //Material.io icon
      label: "Products",                //Label for navigation
      windowTitle: "Mutko SelfService", //Window title
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_PRODUCT_COMPARE"
      ]
    },
    {
      path: "onboarding",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_ONBOARDING",  //Remember to create a viewstate below and link it here.
      view: Onboarding,                 //View as a React Component
      navigation: false,                 //Show up in navigationbar, params below required if this is true
      icon: "account",                  //Material.io icon
      label: "Onboarding",                //Label for navigation
      header: false,
      sidebar: false,
      windowTitle: "Mutko SelfService", //Window title
      requireLogin: true,
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_ONBOARDING"
      ]
    },
    {
      path: "demo",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_DEMO",  //Remember to create a viewstate below and link it here.
      view: Demo,                 //View as a React Component
      navigation: false,                 //Show up in navigationbar, params below required if this is true
      icon: "account",                  //Material.io icon
      label: "Onboarding",                //Label for navigation
      header: false,
      sidebar: false,
      windowTitle: "Mutko SelfService Demo", //Window title
      requireLogin: false,
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_DEMO"
      ]
    },
    {
      path: "admin-notfound",                 // URL path. %homepage%/send
      viewstate: "VIEWSTATE_NOTFOUND_ADMIN",  //Remember to create a viewstate below and link it here.
      view: NotFound,               //View as a React Component
      navigation: false,               //Show up in navigationbar, params below required if this is true
      icon: "payment",                 //Material.io icon
      label: "Inspect",                //Label for navigation
      header: true,
      sidebar: true,
      windowTitle: "Mutko SelfService", //Window title
      requireLogin: true,
      activeViewstates: [           //Viewstates that shows navigation as active. Can be multiple.
        "VIEWSTATE_NOTFOUND_ADMIN"
      ]
    },
  ],
  VIEWSTATE_NOTFOUND: NotFound,
  VIEWSTATE_ERROR: Error,
  VIEWSTATE_LOADING: Loading,
  VIEWSTATE_FORM: FormView,
  VIEWSTATE_LOGIN: LoginView
}

export default Config;

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap'

//Common Components
import InfoBox from '../../components/infobox'

import FormLogs from './components/formLogs'
import ActivityPath from './components/activityPath'

import FormPreview from '../form'

import Config from '../../Config.js'

import { apiRequest } from '../../utils/api'
import translate from '../../utils/translate'
import { formatDate } from '../../utils/helper'


import './style.css';

class Organisation extends Component {
  constructor(){
    super();
    this.state = {
      loading: true,
      form: null,
      error: false,
      submitting: false,
      approved: false,
    }
  }

  async componentDidMount(){
    const hash = this.props.args[1]
    this.fetchForm(hash)
  }

  async fetchForm(hash){
    try{
      const template = await apiRequest({
        method: "GET",
        url: `/admin/form/${hash}`
      })
      return this.setState({
        form: template.template,
        theme: template.theme,
        data: template.data || {},
        submit: template.submit || template.data || {},
        automations: template.automations,
        templateName: template.templateName,
        templateId: template.templateId,
        activity: template.activity,
        adminActivity: template.adminActivity,
        automationActivity: template.automationActivity,
        createdOn: template.createdOn,
        openedOn: template.openedOn,
        submittedOn: template.submittedOn,
        approvedOn: template.approvedOn,
        loading: false,
      })
    }catch(ex){
      console.log(ex)
      this.setState({
        error: ex,
        loading: false
      })
    }
  }

  async approve(){
    try{
      console.log("Approving form")
      this.setState({submitting: true})
      const template = await apiRequest({
        method: "post",
        url: `/admin/form/${this.props.args[1]}/approve`
      })
      this.setState({
        approved: true,
      })
      console.log("Form succesfully approved")
    }catch(ex){
      console.log(ex)
      console.log("Form could not be approved")
      this.setState({
        error: ex,
        loading: false,
        submitting: false,
        approved: true,
      })
    }
  }

  render() {
    const { theme, error, loading } = this.state
    if(loading){
      return(
        <div className="loading center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    }
    if(error){
      return(
        <div className="loading center">
          <div className="errorMessage">
            {error.message}
          </div>
          {(error.requestId !== null)?
            <div className="errorCode">
              Virhekoodi: '{error.requestId}'
            </div>
            :
            ""
          }
        </div>
      )
    }
    return(
      <div className="inspectContainer">
        <div className="infoContainer">
          {InfoBox({header: translate("FORMS_COUNT"), class: "confirmedBorder whiteBG", value: (this.state.createdOn)? formatDate(new Date(this.state.createdOn)) : null})}
          {InfoBox({header: translate("FORMS_OPENED"), class: "holdBorder whiteBG", value: (this.state.openedOn)? formatDate(new Date(this.state.openedOn)) : "-"})}
          {InfoBox({header: translate("FORMS_SUBMITTED"), class: "successBorder whiteBG", value: (this.state.submittedOn)? formatDate(new Date(this.state.submittedOn)) : "-"})}
        </div>
        <div className="previewInspectContainer">
          <FormPreview form={{
              form: this.state.form,
              theme: {
                noLogo: true,
                formContainer: {
                  background: "rgb(247 247 247)",
                  marginTop: "5px",
                  width: "500px",
                  boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)",
                  border: "1px solid rgba(0,0,0,0)"
                }
              },
              data: this.state.data,
              submit: this.state.submit,
            }}
            isPreview={true}
            approved={(!this.state.submittedOn || this.state.approvedOn || this.state.approved)? true : false}
            inspect={true}
            approve={() => this.approve()}
            submitting={this.state.submitting}
            admin={true}
          />
          <ActivityPath
            createdOn = {this.state.createdOn}
            activity={this.state.activity}
            adminActivity={this.state.adminActivity}
            automationActivity={this.state.automationActivity}
            logs={
              [
                ...this.state.activity.map((row) => {return {...row, logType: "client"}}),
                ...this.state.adminActivity.map((row) => {return {...row, logType: "admin"}}),
                ...this.state.automationActivity.map((row) => {return {...row, logType: "automation"}})
              ].sort((a, b) => b.ts - a.ts)
            }
          />
        </div>
      </div>
    )
  }
}

export default Organisation;

import React, { Component } from 'react';
import { Spinner, Button } from 'react-bootstrap'

import Config from '../../Config.js'

import { apiRequest } from '../../utils/api'
import { createWelcomeMessage } from '../../utils/helper'
import translate from '../../utils/translate'

import LatenttiLogo from '../../resources/latentti-white-bg.webp'

import './style.css';

class JoinOrganisation extends Component {
  constructor(){
    super();
    this.state = {
      invite: null,
      error: null
    }
  }

  async componentDidMount(){
    this.fetchInvite()
  }

  async fetchInvite(){
    try{
      const invite = await apiRequest({
        method: "GET",
        url: `auth/organisation/invite/${this.props.args[1]}`
      })
      this.setState({
        invite
      })
    }catch(ex){
      return false
    }
  }

  async acceptInvite(){
    try{
      const invite = await apiRequest({
        method: "POST",
        url: `auth/organisation/join/${this.props.args[1]}`
      })
      window.location.href = "/"
    }catch(ex){
      return false
    }
  }

  async discardInvite(){
    try{
      const invite = await apiRequest({
        method: "POST",
        url: `auth/organisation/discard/${this.props.args[1]}`
      })
      window.location.href = "/"
    }catch(ex){
      return false
    }
  }

  render() {
    const { invite, error } = this.state
    if(!invite){
      return(
        <div className="loading center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    }
    if(error){
      console.log(error)
      return(
        <div className="loading center">
          <div className="errorMessage">
            {translate(error.message)}
          </div>
          {(error.requestId !== null)?
            <div className="errorCode">
              Virhekoodi: '{error.trace}'
            </div>
            :
            ""
          }
        </div>
      )
    }
    return (
      <div className="acceptInvitationContainer">
        <div className="acceptInvitationContainerLogo">
          <img src={LatenttiLogo} alt="Latentti" />
        </div>
        <div className="acceptInvitationContainerHeader">
          {createWelcomeMessage(translate("ACCEPT_INVITATION_WELCOME"), { invitee: invite.createdByName || invite.createdBy, organisation: invite.organisationName })}
        </div>
        <div className="acceptInvitationContainerActions">
          <Button variant="success" type="submit" onClick={() => this.acceptInvite()}>
            {translate("BUTTON_APPROVE")}
          </Button>
          <Button variant="danger" type="submit" onClick={() => this.discardInvite()}>
            {translate("BUTTON_DISCARD")}
          </Button>
        </div>
      </div>
    );
  }
}

export default JoinOrganisation;

import React from 'react';
import Icon from '@material-ui/core/Icon';
import { Button, Modal } from 'react-bootstrap'

import Field from '../../../components/field'
import translate from '../../../utils/translate'
import { formatDate } from '../../../utils/helper'

function basicInfoBox(header, value){
  return(
    <div className="basicInfoData">
      <div className="basicInfoDataHeader">
        {header}
      </div>
      <div className="basicInfoDataValue">
        {value}
      </div>
    </div>
  )
}

function apiBox(active, name, key, activity, deactivate){
  return(
    <div className={(active)? "apiKeyData" : "apiKeyDataInactive"}>
      <div className="basicInfoDataHeader">
        {translate("API_NAME")}
      </div>
      <div className="basicInfoDataValue">
        {name}
      </div>
      <div className="apiKeyLastRow">
        <div className="apiKey">
          <div className="basicInfoDataHeader">
            {translate("API_KEY_LASTACTIVITY")}
          </div>
          <div className="basicInfoDataValue">
            {formatDate(new Date(activity))}
          </div>
        </div>
        <div className="apiKeyRevokeButtonContainer">
          <div className="apiKeyRevokeButton">
            {(active)?
              <Button variant="danger" size="sm" type="submit" onClick={ () => { deactivate() } }>
                {translate("BUTTON_REVOKE")}
              </Button>
              :
              <Button variant="danger" size="sm" type="submit" disabled>
                {translate("BUTTON_REVOKED")}
              </Button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

function apiModal(props){
  return (
    <Modal style={{color:"black"}} size="lg" show={props.apiModalVisible} onHide={() => props.closeAddApiKey()}>
      <Modal.Header closeButton>
        <Modal.Title style={{color:"black"}}>{translate("NEWAPIKEY_HEADER")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Field
          label={translate("NEWAPIKEY_NAME")}
          name="name"
          onChange={(evnt) => {props.nameChange(evnt.target.value)}}
        />
        {(props.newApiKey)?
          <div className="newApiKey">
            <Field
              label={translate("API_KEY")}
              name="apiKey"
              value={props.newApiKey.apiKey}
              disabled
            />
            <Field
              label={translate("API_SECRET")}
              name="apiSecret"
              value=""
              value={props.newApiKey.apiSecret}
              disabled
            />
          </div>
          :
          ""
        }
      </Modal.Body>
      <Modal.Footer>
        {(props.newApiKey)?
          <Button variant="primary" onClick={() => props.closeAddApiKey()}>{translate("BUTTON_CLOSE")}</Button>
          :
          <Button variant="primary" onClick={() => props.addApiKey()}>{translate("BUTTON_CREATE")}</Button>
        }
      </Modal.Footer>
    </Modal>
  )
}

function BasicInfo(props) {

  return (
    <div className="apiKeyInfo">
      {apiModal(props)}
      <div className="basicInfoHeader">
        {translate("ORGANISATION_APIKEY_HEADER")}
        <div className="basicInfoDataHeaderAction">
          <div className="basicInfoDataHeaderActionButton" title={translate("ORGANISATION_APIKEY_HEADER_ADD")} onClick={() => {props.openAddApiKey()}}>
            <Icon>add</Icon>
          </div>
        </div>
      </div>
      <div className="basicInfoValues apiKeysList">
        {(props.apiKeys)?
          props.apiKeys.map((row) => {
            return apiBox(row.active, row.name, row.apiKey, row.lastActivity, () => props.deactivate(row.apiKey))
          })
          :
          ""
        }
      </div>
    </div>
  );
}

export default BasicInfo;

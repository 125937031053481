import React, { Component } from 'react';

import Config from './Config';
import Router from './Routing';

import Header from './components/header'
import Sidebar from './components/sidebar'

import { apiRequest } from './utils/api'

import './resources/App.css';

class App extends Component {
  constructor(){
    super();
    this.state = {
      view: Router.VIEWSTATE_LOADING,
      viewstate: null,
      params: null,
      args: null,
      pageView: null,
      user: null,
      organisation: null,
      apiVersion: ""
    };
  }

  componentDidMount(){
    this.init();
    this.popListener = window.onpopstate = () => {
      this.init();
    }
  }

  componentWillUnmount(){
    this.popListener = undefined;
  }

  async updateAPIVersion(){
    const version = await apiRequest({
      method: "GET",
      url: "version"
    })
    this.setState({
      apiVersion: version
    })
  }

  async fetchMe(){
    try{
      this.updateAPIVersion()
      const user = await apiRequest({
        method: "GET",
        url: "auth/me"
      })
      const organisation = (user.organisation)? await apiRequest({
        method: "GET",
        url: "auth/organisation"
      }) : null
      this.setState({
        user,
        organisation
      })
      return true
    }catch(ex){
      if(ex.status === 401){
        console.log("Responded with 401")
      }
      return false
    }
  }

  setHeaderTitle(title){
    this.setState({
      headerTitle: title
    })
  }

  /*
   *  We push the new page to history and init again.
   *  This way we don't refresh the page and preserve the current state.
   */
  navigate(data){
    const { name, path, windowTitle } = data
    window.history.pushState(name, windowTitle, `${Config.homepage}/${path}`);
    this.init();
  }

  async init(){
    const path = window.location.pathname.replace(Config.homefolder, "")
    const args = (path.startsWith("/")) ? path.substring(1).split("/") : path.split("/")
    const page = args[0].toLowerCase()

    let pageView = Router.viewstates.find((state) => {
      return state.path === page
    })

    if(!pageView || pageView.requireLogin){
      if(!this.state.user && !await this.fetchMe()){
        //Need to login!
        pageView = Router.viewstates.find((state) => {
          return state.path === "login"
        })
      }
      if(!this.state.user){
        //Need to login!
        pageView = Router.viewstates.find((state) => {
          return state.path === "login"
        })
      }
    }

    if(pageView && pageView.requireLatenttiAdmin){
      if(!this.state.user || !this.state.user.latenttiAdmin){
        //Need to login!
        pageView = (this.state.user)? Router.viewstates.find((state) => {
          return state.path === "admin-notfound"
        }) : Router.VIEWSTATE_NOTFOUND
      }
    }

    if(pageView && pageView.requireOrganisation){
      if(!this.state.organisation){
        //Need organisation!
        pageView = (this.state.user)? Router.viewstates.find((state) => {
          return state.path === "admin-notfound"
        }) : Router.VIEWSTATE_NOTFOUND
      }
    }

    const viewState = ((pageView === undefined)?
        Router.VIEWSTATE_FORM : pageView.viewstate
    );

    const view = ((pageView === undefined)?
        Router.VIEWSTATE_NOTFOUND : pageView.view
    );

    this.setState({
      args,
      viewState,
      view,
      pageView,
      params: null
    })
  }

  setViewState(viewState, params){
    let _viewState = Router.viewstates.find((obj) => {
      return obj.viewstate === viewState
    })
	if(_viewState === undefined){
      return this.setState({
        params,
        viewState: undefined,
        view: undefined
      })
    }
    this.setState({
      params,
      viewState,
      view: _viewState.view
    })
  }

  getView(){
    try {
      const View = this.state.view
      return (View === undefined)? <Router.VIEWSTATE_NOTFOUND /> : <View organisation={this.state.organisation} user={this.state.user} init={() => this.init()} params={this.state.params} args={this.state.args} setViewState={(viewState, params) => this.setViewState(viewState, params)} navigate={(data) => this.navigate(data)} setHeaderTitle={(title) => this.setHeaderTitle(title)} />
    }catch(ex){
      console.log(JSON.stringify(ex));
      return <Router.VIEWSTATE_ERROR params={ex} />
    }
  }

  render(){
    const { viewState, headerTitle, pageView } = this.state
    return (
      <div className="App">
        {(pageView && pageView.header)?
          <Header user={this.state.user} organisation={this.state.organisation} />
          :
          ""
        }
        {(pageView && pageView.sidebar)?
          <Sidebar user={this.state.user} apiVersion={this.state.apiVersion} organisation={this.state.organisation} viewState={viewState} init={() => this.init()} />
          :
          ""
        }
        <div className="content" style={(pageView && pageView.header)? {marginTop: "50px"} : {}}>
          {this.getView()}
        </div>
      </div>
    );
  }
}

export default App;

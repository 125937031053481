import React, { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import { Button, Form, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap'

import Field from '../../../components/field'
import translate, { FieldTypes, FieldTypeValue, FieldTypeName, AutomationTriggers, AutomationTriggerName, AutomationTriggerValue }  from '../../../utils/translate'

function formatKey(str){
  return str
          .replace(/ /g, '_')
          .replace(/å/g, 'a')
          .replace(/ä/g, 'a')
          .replace(/ö/g, 'o')
          .replace(/[^a-zA-Z0-9_]/g, '')
}

function getEditableFieldOptions(props){
  const { key, options } = props
  if(!options){
    return (
      <div>
        <div className="optionsTooltipLabel">Onpa tyhjää...</div>
        <Button variant="primary" type="submit" onClick={ () => { props.addOption(key) } }>
          {translate("FIELD_OPTIONSADD_BUTTON")}
        </Button>
      </div>
    )
  }
  const optionElements = options.map((row, index) => {
    return (
      <div name={`OPTION_${index}_CONTAINER`} className="editableOption" key={`OPTION_${index}`}>
        <div className="editableOptionField" key={`OPTION_${index}_FIELD`}>
          <Field
            name={`OPTION_${index}_FIELD`}
            value={row}
            onChange={ (evnt) => { props.setOptionValue(key, index, evnt.target.value) } }
          />
        </div>
        <div key={`OPTION_${index}_BUTTON`} className="editableOptionRemoveButton" onClick={() => props.removeOption(key, index)}>
          <Icon style={{fontSize: "2rem"}}>delete</Icon>
        </div>
      </div>
    )
  })
  return (
    <div>
      {optionElements}
      <Button variant="primary" type="submit" onClick={ () => { props.addOption(key) } }>
        {translate("FIELD_OPTIONSADD_BUTTON")}
      </Button>
    </div>
  )
}

function getEditableColumnsOptions(props){
  const { key, columns } = props
  if(!columns){
    return (
      <div>
        <div className="optionsTooltipLabel">Onpa tyhjää...</div>
        <Button variant="primary" type="submit" onClick={ () => { props.addColumn(key) } }>
          {translate("FIELD_OPTIONSADD_BUTTON")}
        </Button>
      </div>
    )
  }
  const columnElements = columns.map((row, index) => {
    return (
      <div name={`OPTION_${index}_CONTAINER`} className="editableOption" key={`OPTION_${index}`}>
        <div className="editableOptionField editableOptionFieldWide" key={`OPTION_${index}_FIELD`}>
          <Field
            name={`OPTION_${index}_FIELD`}
            value={row.name}
            onChange={ (evnt) => { props.setColumnValue(key, index, {name: evnt.target.value, width: row.width, cKey: row.cKey || false, key: (!row.cKey)? formatKey(evnt.target.value) : row.key }) } }
          />
        </div>
        <div className="columnOptionsSpacer" />
        <div className="editableOptionField editableOptionFieldMed" key={`OPTION_${index}_FIELD_KEY`}>
          <Form.Control
            name={`OPTION_${index}_FIELD_KEY`}
            type="text"
            value={row.key}
            onChange={(evnt) => { props.setColumnValue(key, index, {name: row.name, width: row.width, key: formatKey(evnt.target.value), cKey: true}) }}
            />
        </div>
        <div className="columnOptionsSpacer" />
        <div className="editableOptionField editableOptionFieldmedSmall" key={`OPTION_${index}_FIELD_WIDTH`}>
          <Field
            name={`OPTION_${index}_FIELD_WIDTH`}
            value={row.width}
            onChange={ (evnt) => { props.setColumnValue(key, index, {name: row.name, width: parseInt(evnt.target.value), key: row.key, cKey: row.cKey || false}) } }
          />
        </div>
        <div key={`OPTION_${index}_BUTTON`} className="editableOptionRemoveButton" onClick={() => props.removeColumn(key, index)}>
          <Icon style={{fontSize: "2rem"}}>delete</Icon>
        </div>
      </div>
    )
  })
  return (
    <div>
      <div name={`OPTION_CONTAINER_HEADER`} className="editableOption" key={`OPTION_HEADER`}>
        <div className="editableOptionField editableOptionFieldWide" key={`OPTION_FIELD_HEADER`}>
          <h5>{translate("FIELD_COLUMNS_NAME_HEADER")}</h5>
        </div>
        <div className="columnOptionsSpacer" />
        <div className="editableOptionField editableOptionFieldMed" key={`OPTION_FIELD_HEADER_KEY`}>
          <h5>{translate("FIELD_COLUMNS_APIKEY_HEADER")}</h5>
        </div>
        <div className="columnOptionsSpacer" />
        <div className="editableOptionField editableOptionFieldmedSmall" key={`OPTION_FIELD_HEADER_WIDTH`}>
          <h5>{translate("FIELD_COLUMNS_WIDTH_HEADER")}</h5>
        </div>
        <div className="columnOptionsButtonSpacer" />
      </div>
      {columnElements}
      <Button variant="primary" type="submit" onClick={ () => { props.addColumn(key) } }>
        {translate("FIELD_OPTIONSADD_BUTTON")}
      </Button>
    </div>
  )
}

function fieldTypes(active) {
  return [
    "label",
    "paragraph",
    "divider",
    "text",
    "textbox",
    "currency",
    "divider",
    "select",
    "select-searchable",
    "date",
    "time",
    "switch",
    "table",
    "divider",
    "attachment",
    "signaturecanvas",
    "divider",
    "json"
  ].map((row) => {
    return (row === "divider")?
      <Dropdown.Divider />
    :
      <Dropdown.Item eventKey={row}>{translate(row)}</Dropdown.Item>
  })
}

function ActiveField(props) {
  return (
    <div className="editableFieldActive"
      onMouseUp={(evnt) => {props.setDragging(false)}}
    >
    {(props.dragging)?
    <div className="draggingField" />
      :
    <div>
      <div className="editableFieldRow">
        <div className="editableFieldContainer">
          <div className="editableFieldField">
            <Field
              label={(props.type !== "label" && props.type !== "paragraph")? translate("FIELD_LABEL_LABEL") : translate("FIELD_CONTENT_LABEL")}
              name="label"
              value={props.label}
              onChange={ (evnt) => { props.onChange(props.id, "label", evnt.target.value) } }
              type={(props.type === "paragraph")? "textbox" : "text"}
            />
          </div>
        </div>
        {(props.type !== "label" && props.type !== "paragraph") &&
          <div className="editableFieldApiNameContainer">
            <div className="editableFieldField">
              <Form.Group key="label" controlId="label">
                <Form.Label><div className="labelRow">{translate("FIELD_APINAME_LABEL")}</div></Form.Label>
                <div>
                  <Form.Control name="label" title={props.key} type="text" value={props.key} onChange={(evnt) => { props.onChange(props.id, "key", evnt.target.value) }} />
                </div>
              </Form.Group>
            </div>
          </div>
        }
        <div className="editableFieldTypeContainer">
          <div className="editableFieldField">
            <Form.Group key={`form_group_activeField_${props.id}`} controlId={`form_group_activeField_${props.id}`}>
              <Form.Label><div className="fieldTypeLabel">{translate("FIELD_TYPE_LABEL")}</div></Form.Label>
              <div className="fieldTypeDropdown">
                <DropdownButton
                  key={`fieldtype_selector_${props.key}`}
                  id={`fieldtype-selector-${props.key}`}
                  variant="info"
                  title={(props.type)? translate(props.type) : translate("text")}
                  onSelect={(value) => {props.onChange(props.id, "type", value)}}
                >
                  {fieldTypes()}
                </DropdownButton>
              </div>
            </Form.Group>
          </div>
        </div>
      </div>
      {(props.type === "json")?
        (
          <div className="jsonVariableField">
            <Form.Group key="label" controlId="label">
              <Form.Label><div className="labelRow">{translate("FIELD_JSON_VARIABLE")}</div></Form.Label>
              <div>
                <Form.Control name="content" title={props.content} rows={3} as="textarea" value={props.content} onChange={(evnt) => { props.onChange(props.id, "content", evnt.target.value) }} />
              </div>
            </Form.Group>
          </div>
        )
        :
        ""
      }
      {(props.type === "select")?
        (
          <div className="editableFieldOptions">
            <div className="label">
              <h5>
                {translate("FIELD_OPTIONS_LABEL")}
              </h5>
            </div>
            {getEditableFieldOptions(props)}
          </div>
        )
        :
        ""
      }
      {(props.type === "table")?
        (
          <div className="editableFieldOptions">
            <div className="label">
              <h5>
                {translate("FIELD_COLUMNS_LABEL")}
              </h5>
            </div>
            {getEditableColumnsOptions(props)}
          </div>
        )
        :
        ""
      }
      {(props.type === "select-searchable")?
        (
          <div className="editableFieldOptions">
            <div className="editableFieldField">
              <Field
                name="dataVariable"
                label={translate("FIELD_SELECTSEARCHABLE_DATA_VARIABLE")}
                onChange={(evnt) => {props.onChange(props.id, "dataVariable", evnt.target.value)}}
                value={(props.dataVariable !== undefined && props.dataVariable !== null)? props.dataVariable : null}
                type="select"
                options={
                  props.state.form.fields.filter((row) => row.id !== props.id).map((row) => row.key)
                }
              />
              <Field
                name="dataFilter"
                label={translate("FIELD_SELECTSEARCHABLE_DATA_FILTER")}
                onChange={(evnt) => {props.onChange(props.id, "dataFilter", evnt.target.value)}}
                value={(props.dataFilter !== undefined && props.dataFilter !== null)? props.dataFilter : null}
                type="select"
                options={
                  props.state.form.fields.filter((row) => row.id !== props.id).map((row) => row.key)
                }
              />
            </div>
          </div>
        )
        :
        ""
      }
      <div style={{marginTop: "10px"}} />
      <div className="actionRow">
        <div className="leftActionRow">
          <div>
            <div className="draggable" onMouseDown={(evnt) => {props.setDragging(true)}} title={translate("REARANGE")}>
              <Icon>drag_indicator</Icon>
            </div>
          </div>
          <div className="leftActionRowButton" title={translate("ADVANCED_SETTINGS")} onClick={() => props.openAdvancedOptions()}>
            <Icon>settings</Icon>
          </div>
        </div>
        <div className="actionSwitch">
          <Form.Check
            type="switch"
            id="hidden"
            name="hidden"
            label={translate("FIELD_HIDDEN_LABEL")}
            onChange={(evnt) => {props.onChange(props.key, "hidden", evnt.target.checked)}}
            checked={props.hidden || false}
          />
        </div>
        <div className="actionSwitch">
          <Form.Check
            type="switch"
            id="disabled"
            name="disabled"
            label={translate("FIELD_DISABLED_LABEL")}
            onChange={(evnt) => {props.onChange(props.key, "disabled", evnt.target.checked)}}
            checked={props.disabled || false}
          />
        </div>
        <div className="actionSwitch">
          <Form.Check
            type="switch"
            id="required"
            name="required"
            label={translate("FIELD_REQUIRED_LABEL")}
            onChange={(evnt) => {props.onChange(props.key, "required", evnt.target.checked)}}
            checked={props.required || false}
          />
        </div>
        <div className="diagonalLine" />
        <Button variant="danger" type="submit" onClick={ () => { props.remove(props.id) } }>
          {translate("BUTTON_REMOVE")}
        </Button>
      </div>
      </div>
      }
    </div>
  );
}

export default ActiveField;

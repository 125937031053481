export function formatDate(date){
  const mm = date.getMonth() + 1; // getMonth() is zero-based
  const dd = date.getDate();
  const h = date.getHours();
  const m = date.getMinutes();

  return [
    (dd>9 ? '' : '0') + dd,
    (mm>9 ? '' : '0') + mm,
    date.getFullYear(),
  ].join('.') + " " + [
    (h>9 ? '' : '0') + h,
    (m>9 ? '' : '0') + m,
  ].join(':');
}

export function formatDateToMonth(date){
  const mm = date.getMonth() + 1; // getMonth() is zero-based
  const dd = date.getDate();
  const h = date.getHours();
  const m = date.getMinutes();

  return [
    (dd>9 ? '' : '0') + dd,
    (mm>9 ? '' : '0') + mm,
    date.getFullYear(),
  ].join('.');
}

export function fromatFromUTCString(dateString){
  let tokens = dateString.split("-")
  return `${tokens[2]}.${tokens[1]}.${tokens[0]}`
}

import React from 'react';
import Icon from '@material-ui/core/Icon';
import { Popover, Overlay, OverlayTrigger, Button } from 'react-bootstrap';

import Field from '../../../components/field'

import translate from '../../../utils/translate'

function getTemplateValue(templates, value){
  const templateValue = templates.find(row => row.name === value)
  return (templateValue)? templateValue : null
}


function Filter(props) {

  return (
    <div style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
      <OverlayTrigger
        trigger="click"
        placement="right"
        onEntering={() => props.onEntered()}
        onExiting={() => props.onExited()}
        overlay={
          <Popover id="filters">
            <Popover.Title as="h3" style={{color: "black"}}>{translate("FORMS_FILTER")}</Popover.Title>
            <Popover.Content>
              {(props.templates)?
                (
                  <Field
                    name="TemplateFilter"
                    type="select"
                    label={translate("FORMS_FILTER_TEMPLATE")}
                    options={props.templates.map(row => row.name)}
                    onChange={(evnt) => props.templateChanged(getTemplateValue(props.templates, evnt.target.value))}
                  />
                )
                :
                ""
              }
              <Field
                name="TemplateFilter"
                type="select"
                label={translate("FORMS_FILTER_STATUS")}
                options={[
                  translate("Created"),
                  translate("Opened"),
                  translate("Submitted"),
                  translate("Approved"),
                  translate("Expired"),
                  translate("Deleted"),
                ]}
                onChange={(evnt) => props.statusChanged(evnt.target.value)}
              />
            </Popover.Content>
          </Popover>
        }
      >
      <div className={(props.show)? "searchActive" : ""}title={translate("FORMS_FILTER")} style={{display:"flex", justifyContent: "center", alignItems: "center"}}><Icon>filter_list</Icon></div>
      </OverlayTrigger>
    </div>
  );
}

export default Filter;

import fi from '../translation/fi'
import en from '../translation/en'

var lang = fi;
var language = "fi";

export const FieldTypes = lang.FIELD_TYPES.map((row) => row.name)

export function FieldTypeName(key){
  const fieldType = lang.FIELD_TYPES.find((row) => row.key === key)
  return (fieldType)? fieldType.name : key
}

export function FieldTypeValue(name){
  const fieldType = lang.FIELD_TYPES.find((row) => row.name === name)
  return fieldType.key
}

export const AutomationTriggers = lang.AUTOMATION_TRIGGERS.map((row) => row.name)

export function AutomationTriggerName(key){
  const fieldType = lang.AUTOMATION_TRIGGERS.find((row) => row.key === key)
  return (fieldType)? fieldType.name : key
}

export function AutomationTriggerValue(name){
  const fieldType = lang.AUTOMATION_TRIGGERS.find((row) => row.name === name)
  return fieldType.key
}

export function setLanguage(langString){
  switch(langString){
    case "en":
      lang = en
      language = "en"
      return console.log("Set language to:", langString)
    default:
      language = "fi"
      lang = fi
  }
}

export default function translate(key){
  return lang[key] || key
}

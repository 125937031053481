export function createWelcomeMessage(message, data){
  const fields = message.split('{{')

  if(fields.length > 1){
    let i = 1
    while(i < fields.length){
      const field = fields[i].split('}}')[0]
      message = message.replace(`{{${field}}}`, data[field])
      i++
    }
  }

  return message
}

export function enumState(state){
  switch(state){
    case "Created":
    case "Luotu":
      return 0
    case "Opened":
    case "Avattu":
      return 1
    case "Submitted":
    case "Palautettu":
      return 2
    case "Approved":
    case "Hyväksytty":
      return 3
    case "Expired":
    case "Vanhentunut":
      return 4
    case "Deleted":
    case "Poistettu":
    case "Evätty":
      return 5
    default:
      return null
  }
}

export function resolveState(state){
  switch(state){
    case 0:
      return "Created"
    case 1:
      return "Opened"
    case 2:
      return "Submitted"
    case 3:
      return "Approved"
    case 4:
      return "Expired"
    case 5:
      return "Deleted"  //Auto
    case 6:
      return "Deleted" //Manually
    default:
      return "Unknown"
  }
}

export function resolveStateBadgeVariant(state){
  switch(state){
    case 0:
      return "secondary"
    case 1:
      return "warning"
    case 2:
      return "success"
    case 3:
      return "success"
    case 4:
      return "danger"
    case 5:
      return "dark"  //Auto
    case 6:
      return "dark" //Manually
    default:
      return "dark"
  }
}

export function formatDate(date){
  const mm = date.getMonth() + 1; // getMonth() is zero-based
  const dd = date.getDate();
  const h = date.getHours();
  const m = date.getMinutes();

  return [
    (dd>9 ? '' : '0') + dd,
    (mm>9 ? '' : '0') + mm,
    date.getFullYear(),
  ].join('.') + " " + [
    (h>9 ? '' : '0') + h,
    (m>9 ? '' : '0') + m,
  ].join(':');
}

export function formatDateToMonth(date){
  const mm = date.getMonth() + 1; // getMonth() is zero-based
  const dd = date.getDate();
  const h = date.getHours();
  const m = date.getMinutes();

  return [
    (dd>9 ? '' : '0') + dd,
    (mm>9 ? '' : '0') + mm,
    date.getFullYear(),
  ].join('.');
}

import React from 'react';
import Icon from '@material-ui/core/Icon';
import { Badge } from "react-bootstrap";

import Field from '../../../components/field'
import translate from '../../../utils/translate'
import { formatDate } from '../../../utils/helper'

function getEmailCard(row){
  return(
    <div className="emailDetails">
      <div className="basicInfoDataHeader">
        {translate("EMAIL_TO")}
      </div>
      <div className="basicInfoDataValue">
        {(row.data.content)? row.data.content.to : "Unknown"}
      </div>
      <div className="basicInfoDataHeader fieldSpace">
        {translate("AUTOMATION_TS")}
      </div>
      <div className="basicInfoDataValue">
        {formatDate(new Date(row.ts))}
      </div>
    </div>
  )
}

function getWebhookAutomation(row){
  return(
    <div className="genericDetails">
      <div className="basicInfoDataHeader">
        {translate("AUTOMATION_WEBHOOK_URL")}
      </div>
      <div className="basicInfoDataValue">
        <Badge variant="secondary" style={{marginRight: "6px"}}>{(row.method)? row.method.toUpperCase() : ""}</Badge> {(row.url)? row.url.split("://")[1] : "-"}
      </div>
      <div className="basicInfoDataHeader fieldSpace">
        {translate("AUTOMATION_TS")}
      </div>
      <div className="basicInfoDataValue">
        {formatDate(new Date(row.ts))}
      </div>
      {(row.failed)?
        <div className="fieldSpace">
          <div className="basicInfoDataHeader">
            {translate("AUTOMATION_WEBHOOK_ERRORMESSAGE")}
          </div>
          <div className="basicInfoDataValue fieldSpace">
            <Field
              name={`${row.key}_error_field`}
              value={row.error}
              groupStyle={{width: "100%"}}
              disabled
            />
          </div>
        </div>
        :
        ""
      }
    </div>
  )
}

function getGenericQueuedAutomation(row){
  return(
    <div className="genericDetails">
      <div className="basicInfoDataHeader">
        {translate("AUTOMATION_STATE")}
      </div>
      <div className="basicInfoDataValue">
        {translate(row.state)}
      </div>
      <div className="basicInfoDataHeader">
        {translate("AUTOMATION_TRIGGER")}
      </div>
      <div className="basicInfoDataValue">
        {translate(row.trigger)}
      </div>
      <div className="basicInfoDataHeader">
        {translate("AUTOMATION_TS")}
      </div>
      <div className="basicInfoDataValue">
        {formatDate(new Date(row.ts))}
      </div>
    </div>
  )
}

function getGenericAutomation(row){
  return(
    <div className="genericDetails">
      <div className="basicInfoDataHeader">
        {translate("AUTOMATION_TRIGGER")}
      </div>
      <div className="basicInfoDataValue">
        {translate(row.trigger)}
      </div>
      <div className="basicInfoDataHeader">
        {translate("AUTOMATION_TS")}
      </div>
      <div className="basicInfoDataValue">
        {formatDate(new Date(row.ts))}
      </div>
      {(row.failed)?
        <div className="fieldSpace">
          <div className="basicInfoDataHeader">
            {translate("AUTOMATION_WEBHOOK_ERRORMESSAGE")}
          </div>
          <div className="basicInfoDataValue fieldSpace">
            <Field
              name={`${row.key}_error_field`}
              value={row.error}
              groupStyle={{width: "100%"}}
              disabled
            />
          </div>
        </div>
        :
        ""
      }
    </div>
  )
}

function getAutomationCard(row, index, props){
  return (
    <div key={props.key || index} className={(row.failed)? "automationCardFailed" : (row.state === "queued")? "automationCardQueued" : "automationCard"}>
      <div className="logCardHeader">
        {translate("form")} {translate(row.trigger).toLowerCase()} - {translate(row.type)} {(row.failed)? <Badge variant="danger">ERROR</Badge> : ""} {(row.state === "queued")? <Badge variant="warning">{translate("QUEUED")}</Badge> : ""}
      </div>
      {(row.state === "queued")?
        getGenericQueuedAutomation(row)
        :
      (row.type === "email")?
        getEmailCard(row)
        :
      (row.type === "webhook")?
        getWebhookAutomation(row)
        :
        getGenericAutomation(row)
      }
    </div>
  )
}

function getClientCard(row, index, props){
  return (
    <div key={props.key || index} className="clientCard">
      <div className="logCardHeader">
        {translate("form")} {translate(row.action).toLowerCase()}
      </div>
      <div className="clientDetails">
        <div className="basicInfoDataHeader">
          {translate("CLIENT_DEVICE")}
        </div>
        <div className="basicInfoDataValue">
          {(row.userAgent.isMobile)? translate("CLIENT_DEVICE_PHONE") : translate("CLIENT_DEVICE_COMPUTER")} - {row.userAgent.browser} v.{row.userAgent.version}
        </div>
        <div className="basicInfoDataHeader">
          {translate("AUTOMATION_TS")}
        </div>
        <div className="basicInfoDataValue">
          {formatDate(new Date(row.ts))}
        </div>
      </div>
    </div>
  )
}

function getAdminCard(row, index, props){

}

function BasicInfo(props) {
  const cards = props.logs.map((row, index) => {
    switch(row.logType){
      case "client":
        return getClientCard(row, index, props)
      case "admin":
        return getAdminCard(row, index, props)
      case "automation":
        return getAutomationCard(row, index, props)
      default:
        return ""
    }
  })
  return (
    <div className="formLogs">
      <div className="basicInfoHeader">
        {translate("ACTIVITYLOG")}
      </div>
      {cards}
    </div>
  );
}

export default BasicInfo;

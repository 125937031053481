import React from 'react';
import { Spinner } from 'react-bootstrap';

import './style.css';

function InfoBox(props) {
  const loading = (
    <Spinner animation="grow" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  )
  return (
    <div className={(props.class)? `infoBox ${props.class}` : "infoBox"}>
      <div className={(props.centered)? "infoBoxHeader centeredBox" : "infoBoxHeader"}>
        {props.header}
      </div>
      <div className={(props.centered)? "infoBoxValue centeredBox" : "infoBoxValue"}>
        {(props.value === undefined || props.value === null)? loading : props.value}
      </div>
    </div>
  );
}

export default InfoBox;

import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap'
import Icon from '@material-ui/core/Icon';

import Field from '../../../components/field'
import translate, { FieldTypes, FieldTypeValue, FieldTypeName, AutomationTriggers, AutomationTriggerName, AutomationTriggerValue }  from '../../../utils/translate'

function themeChanged(evnt, props){
  props.themeChanged(props.organisation.themes.find((row) => row.name === evnt.target.value))
}

function getToolTip(text) {
  let popover = (
    <Popover >
      <Popover.Content>
        {text}
      </Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger placement={"right"} overlay={popover}>
      <Icon fontSize="small" color="primary">info</Icon>
    </OverlayTrigger>
  )
}

function NotFound(props) {
  return (
    <div className="basicData">
      <h3>{translate("HEADER_BASIC")}</h3>
      {(props.organisation && props.organisation.active)?
        <div className="editableFieldRow">
          <div className="editableFieldContainer editableFieldContainerWide">
            <div className="editableFieldField">
              <Field
                label={translate("FIELD_FORM_NAME")}
                name="name"
                onChange={(evnt) => {props.setState({name: evnt.target.value, changed: true})}}
                value={props.state.name}
              />
            </div>
          </div>
          <div className="editableFieldContainer">
            <div className="editableFieldField">
              <Field
                label={translate("FIELD_THEME_LABEL")}
                name="theme"
                value={props.state.theme.name}
                type="select"
                options={props.organisation.themes.map((row) => row.name)}
                onChange={(evnt) => themeChanged(evnt, props)}
              />
            </div>
          </div>
          <div className="editableFieldContainer editableFieldContainerSmall">
            <div className="editableFieldField">
              <Field
                label={translate("FIELD_LANGUAGE_LABEL")}
                name="lang"
                value={props.state.form.lang || "fi"}
                type="select"
                options={[
                  "fi",
                  "en"
                ]}
                onChange={(evnt) => {props.setTemplateProp("lang", evnt.target.value)}}
              />
            </div>
          </div>
        </div>
        :
        <div title="Ei valittavissa demossa" className="editableFieldRow basicDataRow">
          <div className="editableFieldContainer">
            <div className="editableFieldField">
              <Field
                label={translate("FIELD_THEME_LABEL")}
                name="type"
                value="Demo"
                title="Ei valittavissa Demossa"
                type="select"
                options={[
                  "Demo",
                ]}
                onChange={() => {}}
                disabled
              />
            </div>
          </div>
          <div className="editableFieldContainer">
            <div className="editableFieldField">
              <Field
                label={translate("FIELD_FORM_NAME")}
                name="name"
                title="Ei valittavissa Demossa"
                value={translate("FIELD_FORM_NAME_DEFAULT")}
                onChange={() => {}}
                disabled
              />
            </div>
          </div>
        </div>
      }
      <div className="editableFieldContainer">
        <div className="editableFieldField">
          <Field
            label={translate("FIELD_FORM_DESCRIPTION")}
            name="description"
            onChange={(evnt) => {props.setTemplateProp("description", evnt.target.value)}}
            value={props.state.form.description}
            type="textbox"
          />
        </div>
      </div>
      <div className="editableFieldContainer">
        <div className="editableFieldField">
          <Field
            label={translate("FIELD_FORM_WELCOME")}
            name="welcome"
            onChange={(evnt) => {props.setTemplateProp("welcome", evnt.target.value)}}
            value={props.state.form.welcome}
          />
        </div>
      </div>
      <div className="editableFieldContainer">
        <div className="editableFieldField">
          <Field
            label={translate("FIELD_FORM_REDIRECTONSUBMIT")}
            name="postSubmitRedirect"
            onChange={(evnt) => {props.setTemplateProp("postSubmitRedirect", (evnt.target.value && evnt.target.value.trim() !== "")? evnt.target.value : null)}}
            value={props.state.form.postSubmitRedirect}
            tooltip={getToolTip(translate("FIELD_FORM_REDIRECTONSUBMIT_TOOLTIP"))}
          />
        </div>
      </div>
    </div>
  );
}

export default NotFound;

export function createWelcomeMessage(message, data){
  const fields = message.split('{{')

  if(fields.length > 1){
    let i = 1
    while(i < fields.length){
      const field = fields[i].split('}}')[0]
      message = message.replace(`{{${field}}}`, data[field])
      i++
    }
  }

  return message
}

export function formatDate(date){
  const mm = date.getMonth() + 1; // getMonth() is zero-based
  const dd = date.getDate();
  const h = date.getHours();
  const m = date.getMinutes();

  return [
    (dd>9 ? '' : '0') + dd,
    (mm>9 ? '' : '0') + mm,
    date.getFullYear(),
  ].join('.') + " " + [
    (h>9 ? '' : '0') + h,
    (m>9 ? '' : '0') + m,
  ].join(':');
}

export function formatDateToMonth(date){
  const mm = date.getMonth() + 1; // getMonth() is zero-based
  const dd = date.getDate();
  const h = date.getHours();
  const m = date.getMinutes();

  return [
    (dd>9 ? '' : '0') + dd,
    (mm>9 ? '' : '0') + mm,
    date.getFullYear(),
  ].join('.');
}

import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import { Button } from 'react-bootstrap'

import Field from '../../components/field';
import InfoBox from '../../components/infobox'
import ConfirmationModal from '../../components/confirmationmodal'

import { apiRequest } from '../../utils/api'
import { formatDate } from '../../utils/helper'
import translate, { FieldTypes, FieldTypeValue, FieldTypeName, AutomationTriggers, AutomationTriggerName, AutomationTriggerValue }  from '../../utils/translate'

import './style.css';

class Templates extends Component {
  constructor(){
    super();
    this.state = {
      templates: null,
      confirmRemove: false,
      confirmRemoveId: null,
    }
  }

  new(){
    this.props.navigate({
      name: "Mutko SelfService",
      path: "create",
      windowTitle: "Mutko SelfService"
    })
  }

  edit(id){
    this.props.navigate({
      name: "Mutko SelfService",
      path: `create/${id}`,
      windowTitle: "Mutko SelfService"
    })
  }

  async componentDidMount(){
    this.fetchStats();
    this.fetchTemplates();
  }

  async fetchStats(){
    const stats = await apiRequest({
      method: "GET",
      url: "admin/monthly-stats"
    })
    this.setState({
      stats,
    })
  }

  async fetchTemplates(){
    const templates = await apiRequest({
      method: "GET",
      url: "admin/templates"
    })
    this.setState({
      templates
    })
  }

  async confirmRemove(){
    this.setState({
      templates: null,
      confirmRemove: false,
      confirmRemoveId: null,
    })
    const templates = await apiRequest({
      method: "POST",
      url: `admin/template/${this.state.confirmRemoveId}/remove`
    })
    this.fetchTemplates()
  }

  cancelRemove(){
    this.setState({
      confirmRemove: false,
      confirmRemoveId: null,
    })
  }

  getTemplateTableRows(){
    if(!this.state.templates){
      return
    }
    return this.state.templates.map((row, index) => {
      return (
        <div className={(index + 1 === this.state.templates.length)? "templateTableRow templateTableRowLast" : "templateTableRow"}>
          <div className="templateTableFieldSmall">
            {row.eid}
          </div>
          <div className="templateTableField templateTableFieldAction" onClick={() => this.edit(row.eid)}>
            {row.name}
          </div>
          <div className="templateTableField">
            {row.createdBy}
          </div>
          <div className="templateTableFieldSmall">
            {formatDate(new Date(row.modifiedOn))}
          </div>
          <div className="templateTableFieldLogo" onClick={() => {this.setState({
              confirmRemove: true,
              confirmRemoveId: row.eid,
          })}}>
            <Icon>delete</Icon>
          </div>
        </div>
      )
    })
  }

  getTemplatesTable(){
    if(!this.state.templates || this.state.templates.length < 1){
      return
    }
    return(
      <div className="templateTable">
        <div className="templateTableHeader">
          <div className="templateTableFieldSmall">
            {translate("TEMPLATE_ID")}
          </div>
          <div className="templateTableField">
            {translate("TEMPLATE_NAME")}
          </div>
          <div className="templateTableField">
            {translate("TEMPLATE_OWNER")}
          </div>
          <div className="templateTableFieldSmall">
            {translate("TEMPLATE_MODIFIED")}
          </div>
          <div className="templateTableFieldLogo">

          </div>
        </div>
        <div className="templateTableContent">
          {this.getTemplateTableRows()}
        </div>
      </div>
    )
  }

  getTemplateAmount(){
    if(this.props.organisation.planId === 3){
      return (
        <div className="templateAmount">
          {this.state.templates.length} / <span className="inclusiveLogo"><Icon>all_inclusive</Icon></span>
        </div>
      )
    }
    return <div className="templateAmount">{`${this.state.templates.length} / ${this.props.organisation.maxTemplates}`}</div>
  }

  render() {
    return (
      <div className="templatesContainer">
        <ConfirmationModal
          onCancel={() => {this.cancelRemove()}}
          onHide={() => {this.cancelRemove()}}
          onConfirm={() => {this.confirmRemove()}}
          visible={this.state.confirmRemove}
          body={translate("CONFIRMATIONMODAL_REMOVETEMPLATE_BODY")}
          continueText={translate("CONFIRMATIONMODAL_REMOVE")}
        />
        <div className="templateInfoContainer">
          {InfoBox({header: translate("TEMPLATE_AMOUNTUSED"), centered: true, class: "confirmedBorder templatesUsed", value: (this.state.templates)? this.getTemplateAmount() : null})}
          {InfoBox({header: translate("FORMS_AMOUNTUSED"), centered: true, class: "confirmedBorder templatesUsed", value: (this.state.stats)? `${this.state.stats.created.toLocaleString("fi-FI")} / ${this.props.organisation.maxMonthlyForms.toLocaleString("fi-FI")}` : null})}
        </div>
        {this.getTemplatesTable()}
        <div className="templatesNew" title={(!(this.state.templates && this.props.organisation.maxTemplates > this.state.templates.length))? translate("TEMPLATE_LIMIT_REACHED") : null}>
          <Button
            variant="primary"
            disabled={!(this.state.templates && this.props.organisation.maxTemplates > this.state.templates.length)}
            type="submit"
            onClick={() => this.new()}
          >
            <div className="templatesNewButton">
              <div className="templatesNewButtonIcon">
                <Icon>add</Icon>
              </div>
              {translate("BUTTON_NEW")}
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default Templates;

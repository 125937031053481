import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import { Button } from 'react-bootstrap'

import Field from '../../components/field';
import InfoBox from '../../components/infobox'
import ConfirmationModal from '../../components/confirmationmodal'

import { apiRequest } from '../../utils/api'
import { formatDate } from '../../utils/helper'
import translate, { FieldTypes, FieldTypeValue, FieldTypeName, AutomationTriggers, AutomationTriggerName, AutomationTriggerValue }  from '../../utils/translate'

import './style.css';

class Templates extends Component {
  constructor(){
    super();
    this.state = {
      organisations: null,
      confirmRemove: false,
      confirmRemoveId: null,
    }
  }

  new(){
    this.props.navigate({
      name: "Mutko SelfService",
      path: "create",
      windowTitle: "Mutko SelfService"
    })
  }

  edit(id){
    this.props.navigate({
      name: "Mutko SelfService",
      path: `latentti-admin-edit-organisation/${id}`,
      windowTitle: "Mutko SelfService"
    })
  }

  async componentDidMount(){
    this.fetchOrganisations();
  }

  async fetchOrganisations(){
    const organisations = await apiRequest({
      method: "GET",
      url: "auth/organisations"
    })
    this.setState({
      organisations
    })
  }

  async confirmRemove(){
    this.setState({
      organisations: null,
      confirmRemove: false,
      confirmRemoveId: null,
    })
    await apiRequest({
      method: "POST",
      url: `auth/organisation/${this.state.confirmRemoveId}/deactivate`
    })
    this.fetchOrganisations()
  }

  cancelRemove(){
    this.setState({
      confirmRemove: false,
      confirmRemoveId: null,
    })
  }

  async switchOrganisation(org){
    await apiRequest({
      method: "post",
      url: `auth/organisation/set/${org}`
    })
    window.location.href = "/"
  }

  getTemplateTableRows(){
    if(!this.state.organisations){
      return
    }
    return this.state.organisations.map((row, index) => {
      return (
        <div className={(index + 1 === this.state.organisations.length)? "templateTableRow templateTableRowLast" : "templateTableRow"}>
          <div className="templateTableField templateTableFieldAction" onClick={() => this.edit(row.eid)}>
            {row.name}
          </div>
          <div className="templateTableFieldSmall">
            {row.businessId}
          </div>
          <div className="templateTableField">
            {row.createdBy || row.contactEmail}
          </div>
          <div className="templateTableFieldSmall templateTableFieldCentered">
            {formatDate(new Date(row._ts))}
          </div>
          <div className="templateTableFieldSmall templateTableFieldCentered">
            {(row.active)? translate("YES") : translate("NO")}
          </div>
          <div className="templateTableFieldLogo" title={translate("ORGANISATION_SWITCH_TO")} onClick={() => { this.switchOrganisation(row.eid) }}>
            <Icon>launch</Icon>
          </div>
          <div className="templateTableFieldLogoSpacer">

          </div>
          {(row.active)? (
            <div className="templateTableFieldLogo" style={{color: "darkred"}} title={translate("ORGANISATION_DEACTIVATE")} onClick={() => {this.setState({
                confirmRemove: true,
                confirmRemoveId: row.eid,
            })}}>
              <Icon>cancel</Icon>
            </div>
          ):(
            <div className="templateTableFieldLogo" title={translate("ORGANISATION_ACTIVATE")} onClick={() => {this.setState({
                confirmRemove: true,
                confirmRemoveId: row.eid,
            })}}>
              <Icon>check_circle</Icon>
            </div>
          )}

        </div>
      )
    })
  }

  getTemplatesTable(){
    if(!this.state.organisations || this.state.organisations.length < 1){
      return
    }
    return(
      <div className="templateTable">
        <div className="templateTableHeader">
          <div className="templateTableField">
            {translate("ADMIN_ORGANISATION_NAME")}
          </div>
          <div className="templateTableFieldSmall">
            {translate("ADMIN_ORGANISATION_BUSINESSID")}
          </div>
          <div className="templateTableField">
            {translate("ADMIN_ORGANISATION_CREATEDBY")}
          </div>
          <div className="templateTableFieldSmall templateTableFieldCentered">
            {translate("ADMIN_ORGANISATION_CREATEDAT")}
          </div>
          <div className="templateTableFieldSmall templateTableFieldCentered">
            {translate("ADMIN_ORGANISATION_ACTIVE")}
          </div>
          <div className="templateTableFieldLogo">

          </div>
          <div className="templateTableFieldLogoSpacer">

          </div>
          <div className="templateTableFieldLogo">

          </div>
        </div>
        <div className="templateTableContent">
          {this.getTemplateTableRows()}
        </div>
      </div>
    )
  }

  getTemplateAmount(){
    if(this.props.organisation.planId === 3){
      return (
        <div className="templateAmount">
          {this.state.templates.length} / <span className="inclusiveLogo"><Icon>all_inclusive</Icon></span>
        </div>
      )
    }
    return <div className="templateAmount">{`${this.state.templates.length} / ${this.props.organisation.maxTemplates}`}</div>
  }

  render() {
    return (
      <div className="templatesContainer">
        <ConfirmationModal
          onCancel={() => {this.cancelRemove()}}
          onHide={() => {this.cancelRemove()}}
          onConfirm={() => {this.confirmRemove()}}
          visible={this.state.confirmRemove}
          body={translate("CONFIRMATIONMODAL_REMOVEORGANISATION_BODY")}
          continueText={translate("CONFIRMATIONMODAL_REMOVE")}
        />
        {this.getTemplatesTable()}
        <div className="templatesNew" title={(!(this.state.templates && this.props.organisation.maxTemplates > this.state.templates.length))? translate("ORGANISATION_LIMIT_REACHED") : null}>
          <Button
            variant="primary"
            disabled={!(this.state.templates && this.props.organisation.maxTemplates > this.state.templates.length)}
            type="submit"
            onClick={() => this.new()}
          >
            <div className="templatesNewButton">
              <div className="templatesNewButtonIcon">
                <Icon>add</Icon>
              </div>
              {translate("BUTTON_NEW")}
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default Templates;

import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';

import Router from '../../Routing';
import Config from '../../Config';

import translate from '../../utils/translate'

import './style.css';

class Sidebar extends Component {

  /*
   *  We push the new page to history and init again.
   *  This way we don't refresh the page and preserve the current state.
   */
  navigate(data){
    const { name, path, windowTitle } = data
    console.log({message: "Navigating", navigation: data})
    window.history.pushState(name, windowTitle, `${Config.homepage}/${path}`);
    this.props.init();
  }

  getNavigationButton(data, index){
    const { activeViewstates, icon, label } = data
    if(data.requireLatenttiAdmin && (!this.props.user || !this.props.user.latenttiAdmin)){
      return;
    }
    if(data.requireOrganisation && (!this.props.user || !this.props.user.organisation)){
      return <div key={index} className="navigationButton navigationBlocked">
        <Icon>{icon}</Icon>
        <div className="navigationButtonLabel">
          {translate(label)}
        </div>
      </div>
    }
    if(this.props.viewState !== undefined && activeViewstates.includes(this.props.viewState)){
      return <div key={index} className="navigationButton navigationActive" onClick={() => {this.navigate(data)}}>
        <Icon>{icon}</Icon>
        <div className="navigationButtonLabel">
          {translate(label)}
        </div>
      </div>
    }
    return <div key={index} className="navigationButton" onClick={() => {this.navigate(data)}}>
      <Icon>{icon}</Icon>
      <div className="navigationButtonLabel">
        {translate(label)}
      </div>
    </div>
  }

  getNavigationButtons(){
    return Router.viewstates.filter(item => item.navigation === true).map((navigationItem, index) => {
      return this.getNavigationButton(navigationItem, index)
    })
  }

  render() {
    return (
      <div className="sidebarContainer">
        <div className="navigationContainer">
          { this.getNavigationButtons() }
        </div>
        <div className="sidebarFooter">
          <div className="sidebarVersionTag">
            {
              //`${Config.Version}/${this.props.apiVersion}`
            }
          </div>
          <div className="footerText">
            {
              //<span>by <a href="https://www.latentti.fi/mutko" target="_blank">LATENTTI</a></span>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;

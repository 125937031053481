import React, { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import { Popover, Overlay, OverlayTrigger, Button } from 'react-bootstrap';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

import Field from '../../../components/field'

import translate from '../../../utils/translate'

function getTemplateValue(templates, value){
  const templateValue = templates.find(row => row.name === value)
  return (templateValue)? templateValue : null
}


function Filter(props) {
  const [focusedInput, setFocusedInput] = useState(null);
  const [startDate, setStartDate] = useState((props.start)? moment(props.start) : null);
  const [endDate, setEndDate] = useState(props.end || moment(Date.now()));

  return (
    <div style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
      <OverlayTrigger
        trigger="click"
        placement="right"
        onEntering={() => props.onEntered()}
        onExiting={() => props.onExited()}
        overlay={
          <Popover id="filters">
            <Popover.Title as="h3" style={{color: "black"}}>{translate("FORMS_FILTER")}</Popover.Title>
            <Popover.Content>
              {(props.templates)?
                (
                  <Field
                    name="TemplateFilter"
                    type="select"
                    label={translate("FORMS_FILTER_TEMPLATE")}
                    options={props.templates.map(row => row.name)}
                    onChange={(evnt) => props.templateChanged(getTemplateValue(props.templates, evnt.target.value))}
                  />
                )
                :
                ""
              }
              <div className="date-picker-header">{translate("TIMEFRAME")}</div>
              <DateRangePicker
                startDatePlaceholderText="Start"
                endDatePlaceholderText="End"
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId="start-date" // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId="end-date" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                  props.timeChanged(startDate.valueOf(), endDate.valueOf())
                }} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                small={true}
                displayFormat="DD.MM.YYYY"
                hideKeyboardShortcutsPanel={true}
                isOutsideRange={() => false}
              />
            </Popover.Content>
          </Popover>
        }
      >
      <div className={(props.show)? "searchActive" : ""}title={translate("FORMS_FILTER")} style={{display:"flex", justifyContent: "center", alignItems: "center"}}><Icon>filter_list</Icon></div>
      </OverlayTrigger>
    </div>
  );
}

export default Filter;

import axios from 'axios'
import Config from '../Config'

export const apiRequest = async (config) => {
  try {
    const options = {
      withCredentials: true,
      baseURL: Config.apiEndpoint,
      headers: {
        'accept': 'application/json',
        'UI-Version': Config.Version
      },
    }
    const req = await axios({...options, ...config})
    return req.data
  }catch(error){
    if(error.response !== undefined && error.response.status === 401){
      throw(error.response)
    }
    if(error.response){
      throw({status: error.response.status, message: error.response.data.message, trace: error.response.data.requestId})
    }
    throw({status: 400, message: "Unknown"})
  }
}

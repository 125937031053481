import React, { Component } from 'react';
import { Button } from 'react-bootstrap'

import Field from '../../components/field'
import Config from '../../Config.js'
import translate, { setLanguage } from '../../utils/translate'

import './style.css';

class Onboarding extends Component {


  render() {
    return (
      <div className="onboardingContainer">
        <h2>{translate("ONBOARDING_ORGANISATION_TITLE_NEWORGANISATION")}</h2>
        <div className="onboardingFormContainer">
          <div className="onboardingFormIcon">
            <img class="onboardingFormIconImg" src="https://static.wixstatic.com/media/b282e7_774dc080f20b43259cc3ed3610d775b2~mv2.png/v1/fill/w_146,h_119,al_c,q_85,usm_0.66_1.00_0.01/latentti-white-bg.webp" />
          </div>
          <div className="onboardingFormInfoText">
            <div className="formParagraph">
              {translate("ONBOARDING_ORGANISATION_INFO_WELCOME")}
            </div>
          </div>
          <div className="onboardingFormFieldsContainer">
            <Field name="name" label={translate("ONBOARDING_ORGANISATION_FIELD_NAME")} />
            <Field name="businessid" label={translate("ONBOARDING_ORGANISATION_FIELD_BUSINESSID")} />
            <Field name="contactname" label={translate("ONBOARDING_ORGANISATION_FIELD_CONTACTNAME")} />
            <Field name="contactemail" label={translate("ONBOARDING_ORGANISATION_FIELD_CONTACTEMAIL")} />
            <Button variant="primary" type="submit">
              {translate("ONBOARDING_ORGANISATION_BUTTON_SUBMIT")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Onboarding;

import React from 'react';

function NotFound(props) {
  return (
    <div className="errorContainer">
      <p className="App-intro center">
        Requested page was not found.
      </p>
    </div>
  );
}

export default NotFound;

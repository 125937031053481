import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';

//Common Components
import InfoBox from '../../components/infobox'
import Field from '../../components/field'

//Components
import Filter from './components/filter'
import SubmittedChart from './components/submittedChart'
import RadialChart from './components/radialChart'

import { apiRequest } from '../../utils/api'
import { formatDateToMonth, resolveState, resolveStateBadgeVariant, enumState } from '../../utils/helper'

import translate from '../../utils/translate'

import './style.css';

const DATAPOINTS = 10;

class Reports extends Component {
  constructor(){
    super();
    this.state = {
      data: null,
      templateId: null,
      statusFilter: null,
      searchFilter: null,
      startFilter: null,
      endFilter: null,
      userAgents: null,
    }
  }

  async componentDidMount(){
    let startDate = new Date(Date.now())
    startDate.setDate(1)
    startDate.setMonth(startDate.getMonth() - 2)
    this.setState({
      startFilter: startDate.valueOf()
    }, () => {
      this.fetchData()
      this.fetchTemplates()
    })
  }

  async fetchTemplates(){
    const templates = await apiRequest({
      method: "GET",
      url: "admin/templates"
    })
    this.setState({
      templates
    })
  }

  async fetchData(){
    let queryParams = `?`;
    if(this.state.templateId){
      queryParams += `&templateId=${this.state.templateId}`
    }
    if(this.state.statusFilter != undefined && this.state.statusFilter != null){
      queryParams += `&state=${this.state.statusFilter}`
    }
    if(this.state.searchFilter != undefined && this.state.searchFilter != null){
      queryParams += `&search=${this.state.searchFilter}`
    }
    if(this.state.startFilter != undefined && this.state.startFilter != null){
      queryParams += `&start=${this.state.startFilter}`
    }
    if(this.state.endFilter != undefined && this.state.endFilter != null){
      queryParams += `&end=${this.state.endFilter}`
    }
    const data = await apiRequest({
      method: "GET",
      url: "admin/template-datapoints" + encodeURI(queryParams)
    })
    const created = this.formatData(data.data.filter(row => (row.openedOn == null && row.submittedOn == null)), new Date(this.state.startFilter), new Date(this.state.endFilter || Date.now()), "createdOn")
    const opened = this.formatData(data.data.filter(row => (row.openedOn != null && row.submittedOn == null)), new Date(this.state.startFilter), new Date(this.state.endFilter || Date.now()), "openedOn")
    const submitted = this.formatData(data.data.filter((row) => (row.submittedOn != null)), new Date(this.state.startFilter), new Date(this.state.endFilter || Date.now()), "submittedOn");

    let os = []
    let browsers = []

    for(const prop in data.submittedUserAgents.os){
      os = [
        ...os,
        {
          name: prop,
          value: data.submittedUserAgents.os[prop]
        }
      ]
    }

    const osAmount = (os.length > 0)? os.map((row) => row.value).reduce((acc, cv) => acc + cv) : 0
    os = os.map((row) => {
      return {
        ...row,
        percent: Math.floor((row.value / osAmount) * 100)
      }
    })

    for(const prop in data.submittedUserAgents.browsers){
      browsers = [
        ...browsers,
        {
          name: prop,
          value: data.submittedUserAgents.browsers[prop]
        }
      ]
    }
    const browsersAmount = (browsers.length > 0)? browsers.map((row) => row.value).reduce((acc, cv) => acc + cv) : 0
    browsers = browsers.map((row) => {
      return {
        ...row,
        percent: Math.floor((row.value / browsersAmount) * 100)
      }
    })

    this.setState({
      data,
      statsData: {
        created,
        submitted,
        opened,
      },
      userAgents: {
        os,
        browsers,
        mobile: [
          {
            name: "Mobile",
            value: data.submittedUserAgents.mobile,
            percent: Math.floor((data.submittedUserAgents.mobile / (data.submittedUserAgents.mobile + data.submittedUserAgents.computer)) * 100)
          },
          {
            name: "Computer",
            value: data.submittedUserAgents.computer,
            percent: Math.floor((data.submittedUserAgents.computer / (data.submittedUserAgents.mobile + data.submittedUserAgents.computer)) * 100)
          }
        ]
      }
    })
  }

  openTemplate(id){
    this.setState({
      templateId: id,
      data: null,
      userAgents: null,
      statsData: null,
    }, () => {
      this.fetchData()
    })
  }

  templateChanged(value){
    this.setState({
      templateId: (value)? value.eid : null,
    }, () => {
      this.fetchData()
    })
  }

  statusChanged(value){
    this.setState({
      statusFilter: enumState(value),
    }, () => {
      this.fetchData()
    })
  }

  timeChanged(startDate, endDate){
    this.setState({
      startFilter: startDate,
      endFilter: endDate || Date.now(),
    }, () => {
      this.fetchData()
    })
  }

  formatData(data, start, end, actionField){
    let dates = []
    let currentDate = start
    let accumulated = 0
    while(currentDate.valueOf() < end.valueOf()){
      let count = data.filter(row => formatDateToMonth(new Date(row[actionField])) === formatDateToMonth(currentDate)).length
      accumulated += count
      dates = [
        ...dates,
        {
          x: formatDateToMonth(currentDate),
          y: accumulated
        }
      ]
      currentDate.setDate(currentDate.getDate() + 1)
    }

    if(dates.length > DATAPOINTS){
      const skipAmnt = dates.length / DATAPOINTS
      const toRemove = dates.length - DATAPOINTS
      let i = 1
      let skipped = 0
      let newDates = [
        dates[0]
      ]
      while(i < DATAPOINTS){
        const next = (skipped >= toRemove)? skipped + (i + 1) : Math.ceil((i + 1) * skipAmnt);
        if(next >= dates.length){
          newDates = [
            ...newDates,
            dates[dates.length - 1]
          ]
          break;
        }
        newDates = [
          ...newDates,
          dates[next]
        ]
        skipped += (Math.ceil(skipAmnt) - 1);
        i++;
      }
      return newDates
    }

    return dates
  }

  render() {
    return (
      <div className="dashboardContainer">
        <div className="infoContainer">
          {InfoBox({header: translate("FORMS_COUNT"), class: "confirmedBorder whiteBG", value: (this.state.data)? this.state.data.count : null})}
          {InfoBox({header: translate("FORMS_SUBMITTED"), class: "successBorder whiteBG", value: (this.state.data)? this.state.data.submitted : null})}
          {InfoBox({header: translate("FORMS_APPROVED"), class: "doneBorder whiteBG", value: (this.state.data)? this.state.data.approved : null})}
        </div>
        {(this.state.search) &&
        (
          <div className="searchBar">
            <div className="searchField">
              <Field
                name="search"
                type="search"
                value={this.state.searchFilter}
                placeholder="Hae..."
                onChange={(evnt) => this.setState({searchFilter: evnt.target.value})}
                onClick={() => {
                  this.fetchData()
                }}
              />
            </div>
          </div>
        )
        }
        <div className="reportContainer">
          <div className="reportContainerHeader">
            <div className="templateTableFieldLogo">
              <div title={translate("FORMS_DOWNLOAD")} style={{display:"flex", justifyContent: "center", alignItems: "center"}} onClick={() => this.download()}><Icon>get_app</Icon></div>
            </div>
            <div className="templateTableFiller" />
            <div className="templateTableFieldLogo">
              <div className={(this.state.search? "searchActive" : "")} title={translate("FORMS_SEARCH")} style={{display:"flex", justifyContent: "center", alignItems: "center"}} onClick={() => this.setState((state) => {return {search: !state.search}})}><Icon>search</Icon></div>
            </div>
            <div className="templateTableFiller" />
            <div className="templateTableFieldLogo">
              {this.state.startFilter &&
                <Filter
                  show={this.state.filterVisible}
                  onClick={() => {this.setState(state => { return { filterVisible: !state.filterVisible } })}}
                  templates={this.state.templates}
                  templateChanged={(value) => this.templateChanged(value)}
                  statusChanged={(value) => this.statusChanged(value)}
                  onEntered={() => this.setState({filterVisible: true})}
                  onExited={() => this.setState({filterVisible: false})}
                  start={this.state.startFilter || null}
                  end={this.state.endFilter || null}
                  timeChanged={(startDate, endDate) => this.timeChanged(startDate, endDate)}
                />
              }
            </div>
            <div className="templateTableFiller" />
          </div>
          {(!this.state.data)?
            (
              <div className="reportLoading">
                <Spinner size="lg" animation="border" role="status" variant="info">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                <div className="reportLoadingHeader">
                  Raporttia ladataan
                </div>
              </div>
            )
            :
            (
              <div className="renderedReport">
                <div className="pieCharts">
                  <RadialChart header="Laite" data={(this.state.userAgents)? this.state.userAgents.mobile.map((row) => {return {angle: row.value, label: row.name, percent: row.percent}}) : null} />
                  <RadialChart header="Käyttöjärjestelmä" data={(this.state.userAgents)? this.state.userAgents.os.map((row) => {return {angle: row.value, label: row.name, percent: row.percent}}) : null} />
                  <RadialChart header="Selain" data={(this.state.userAgents)? this.state.userAgents.browsers.map((row) => {return {angle: row.value, label: row.name, percent: row.percent}}) : null} />
                </div>
                <div className="reportContent">
                  <div className="reportHeader">
                    <div className="reportHeaderSide" />
                    <div className="reportHeaderCenter">
                      <div className="chartHeader">
                        {translate("FORMS_STATUS")}
                      </div>
                    </div>
                    <div className="reportHeaderSide">
                      <div className="chartLegends">
                        <div className="chartLegend">
                          <div className="createdLegend" />
                          {translate("FORMS_COUNT")}
                        </div>
                        <div className="chartLegend">
                          <div className="openedLegend" />
                          {translate("FORMS_OPENED")}
                        </div>
                        <div className="chartLegend">
                          <div className="submittedLegend" />
                          {translate("FORMS_SUBMITTED")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <SubmittedChart stats={this.state.statsData} start={this.state.startFilter} end={this.state.endFilter} />
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default Reports;

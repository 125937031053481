import React, { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';

import { apiRequest } from '../../utils/api'
import Config from '../../Config'

import './style.css';

async function getBase64(content){
  const reader = new FileReader()
    return new Promise(resolve => {
      reader.onload = ev => {
        resolve(ev.target.result)
      }
      reader.readAsDataURL(content)
    })
}

class Attachment extends Component {
  constructor(){
    super()

    this.state = {
      isLoading: false,
      attachments: []
    }

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount(){
    this.setState({
      attachments: this.props.value || []
    })
  }
  openAttachment(key){
    window.open(`${Config.apiEndpoint}admin/attachment/${key}`, "_blank")
  }

  removeAttachment(attachmentId){
    this.setState((state) => {
      return {
        attachments: state.attachments.filter((row) => row.key !== attachmentId)
      }
    }, () => {
      this.props.onChange({target: { value: this.state.attachments, name: this.props.name }})
    })
  }

  addAttachment(attachment){
    this.setState((state) => {
      return {
        attachments: [...state.attachments, attachment]
      }
    }, () => {
      this.props.onChange({target: { value: this.state.attachments, name: this.props.name }})
    })
  }

  async onChangeHandler(event){
    const maxSize = 25000000; //25 000 000 bytes, 25mb
    this.setState({
      isLoading: true,
      filesLoading: Array.from(event.target.files).length,
      filesLoaded: 0
    })
    Array.from(event.target.files).forEach(async (file, index) => {
      if(file.size > maxSize){
        return console.log("Tiedosto on liian iso!")
      }
      try{
        const fileContent = await getBase64(file);
        const response = await apiRequest({
          method: 'post',
          url: `/form/attachment`,
          headers: {'Accept': 'application/json'},
          data: {content: fileContent, filename: file.name},
        })

        this.addAttachment({key: response.key, fileName: file.name, fileType: file.type, fileSize: file.size})

        this.setState((state) => {
          return {
            isLoading: (state.filesLoading === state.filesLoaded),
            filesLoaded: state.filesLoaded + 1
          }
        })
      }catch(ex){
        console.log(ex);
      }
    })
  }

  getRow(attachment, index){
    return (
      <div key={index} className={(index % 2 === 0) ? "dataTableRow uneven" : "dataTableRow even"}>
          <div className="dataTableFieldWide">
            {this.props.admin?
              <a target="_blank" rel="noopener noreferrer" href={`${Config.apiEndpoint}admin/attachment/${attachment.key}/${attachment.fileName}`}>{attachment.fileName}</a>
              :
              attachment.fileName
            }
          </div>
          {(this.props.disabled)?
            ""
            :
            <div title="Poista" className="dataTableFieldSmall tableCenter tableButton" onClick={() => this.removeAttachment(attachment.key)}>
              <Icon fontSize="small" color="action">delete</Icon>
            </div>
          }
      </div>
    )
  }

  getTable(){
    return (
      <div className="dataTable">
        <div className="dataTableContent">
          {
            this.state.attachments.map((row, index) => {
              return this.getRow(row, index)
            })
          }
        </div>
      </div>
    )
  }

  render() {
    const { isLoading, itemsCount, itemsCompleted, items, filesLoading, filesLoaded } = this.state

    if(isLoading){
      return (
        <div className="container">
          <div className="attachmentsInput containerBox">
            <div className="loadingContainer">
              <h4>Tiedostoja käsitellään</h4>
              <div className="uploadProgressBar">
                <ProgressBar animated now={((filesLoaded/filesLoading) * 100)} label={`${filesLoaded}/${filesLoading}`} />
              </div>
            </div>
          </div>
        </div>
      )
    }

    if(this.props.disabled){
      return (
        <div className="attachmentsContainer">
          <div className="attachmentsInput">
            <div className="fileTable">
              {(this.state.attachments.length > 0)?
                this.getTable()
                :
                "Ei tiedostoja"
              }
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="attachmentsContainer">
        <div className="attachmentsInput">
          <div className="files">
            <input type="file" name="file" onChange={this.onChangeHandler} multiple disabled={this.props.disabled}/>
          </div>
          <div className="removeTop" />
            {(this.state.attachments.length > 0) &&
              <div className="fileTable">
                {this.getTable()}
              </div>
            }
        </div>
      </div>
    );
  }
}

export default Attachment;

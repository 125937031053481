import React, { Component } from 'react';
import { Spinner, Badge, Button } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import Icon from '@material-ui/core/Icon';

//Common Components
import InfoBox from '../../components/infobox'
import Field from '../../components/field'
import ConfirmationModal from '../../components/confirmationmodal'

//Components
import Filter from './components/filter'

//Utils
import { apiRequest } from '../../utils/api'
import translate from '../../utils/translate'
import { formatDate, resolveState, resolveStateBadgeVariant, enumState } from '../../utils/helper'

import './style.css';

class Dashboard extends Component {
  constructor(){
    super();
    this.state = {
      forms: null,
      data: null,
      limit: 15,
      page: 0,
      templateId: null,
      pages: 0,
      count: 0,
      stats: null,
      filterVisible: false,
      templates: null,
      statusFilter: null,
      searchFilter: null,
      search: false,
      confirmRemove: false,
      confirmRemoveId: null,
    }
  }

  async componentDidMount(){
    this.fetchForms()
    this.fetchStats()
    this.fetchTemplates()
  }

  async fetchTemplates(){
    const templates = await apiRequest({
      method: "GET",
      url: "admin/templates"
    })
    this.setState({
      templates
    })
  }

  async fetchStats(){
    //Fetch stats
    let statQueryParams = (this.state.templateId)? `?templateId=${this.state.templateId}` : ""
    if(this.state.searchFilter != undefined && this.state.searchFilter != null){
      statQueryParams += (statQueryParams === "")? `?search=${this.state.searchFilter}` : `&search=${this.state.searchFilter}`
    }
    const stats = await apiRequest({
      method: "GET",
      url: "admin/template-stats" + encodeURI(statQueryParams)
    })
    this.setState({
      stats: {
        Created: stats.Created || 0,
        Opened: stats.Opened || 0,
        Submitted: stats.Submitted || 0,
      },
    })
  }

  async fetchForms(){
    let queryParams = `?limit=${this.state.limit}&page=${this.state.page}`;
    if(this.state.templateId){
      queryParams += `&templateId=${this.state.templateId}`
    }
    if(this.state.statusFilter != undefined && this.state.statusFilter != null){
      queryParams += `&state=${this.state.statusFilter}`
    }
    if(this.state.searchFilter != undefined && this.state.searchFilter != null){
      queryParams += `&search=${this.state.searchFilter}`
    }
    const data = await apiRequest({
      method: "GET",
      url: "admin/forms" + encodeURI(queryParams)
    })
    this.setState({
      forms: data.data,
      pages: data.pageCount,
      count: data.count,
    })
  }

  openTemplate(id){

    /*
    this.props.navigate({
      name: "Mutko SelfService",
      path: `create/${id}`,
      windowTitle: "Mutko SelfService"
    })
    */

    this.setState({
      templateId: id,
      forms: null,
      data: null,
      stats: null,
      page: 0,
    }, () => {
      this.fetchForms()
      this.fetchStats()
    })
  }

  openRecipient(recipient){
    this.setState({
      searchFilter: (recipient !== "")? recipient : null,
      statusFilter: null,
      forms: null,
      data: null,
      stats: null,
      page: 0,
      search: true,
    }, () => {
      this.fetchForms()
      this.fetchStats()
    })
  }

  openForm(hash){
    this.props.navigate({
      name: "Mutko SelfService",
      path: `inspect/${hash}`,
      windowTitle: "Mutko SelfService"
    })
  }

  templateChanged(value){
    this.setState({
      templateId: (value)? value.eid : null,
      forms: null,
      data: null,
      stats: null,
      page: 0,
    }, () => {
      this.fetchForms()
      this.fetchStats()
    })
  }

  statusChanged(value){
    this.setState({
      statusFilter: enumState(value),
      forms: null,
      data: null,
    }, () => {
      this.fetchForms()
      this.fetchStats()
    })
  }

  download(){

  }

  getFormsTableRows(){
    if(!this.state.forms){
      return (
        <div className="center" style={{paddingTop: "10px", paddingBottom: "10px"}}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    }
    if(this.state.forms.length < 1){
      return (
        <div className="templateNotFoundMessage">
          {translate("TEMPLATE_NOFOUND")}
        </div>
      )
    }
    return this.state.forms.map((row, index) => {
      let className = "templateTableRow"
      if(index + 1 === this.state.forms.length){
        className += " templateTableRowLast"
      }
      className += (index % 2 === 0) ? " even" : " uneven"
      return (
        <div className={className}>
          <div className="templateTableFieldFixedSmall">
            <Badge variant={resolveStateBadgeVariant(row.state)}>{translate(resolveState(row.state))}</Badge>
          </div>
          <div className="templateTableFieldSmall templateTableFieldAction" onClick={() => this.openForm(row.hash)}>
            {row.eid.split("-")[0]}
          </div>
          <div className="templateTableField templateTableFieldAction" onClick={() => this.openTemplate(row.templateId)}>
            {row.templateName}
          </div>
          <div className="templateTableField templateTableFieldAction" onClick={() => this.openRecipient(row.recipient)}>
            {row.recipient}
          </div>
          <div className="templateTableField">
            {formatDate(new Date(row.createdOn))}
          </div>
          <div className="templateTableFieldLogo" />
          <div className="templateTableFiller" />
          <div className="templateTableFieldLogo">
            <div title={translate("FORMS_REMIND")} style={{display:"flex", justifyContent: "center", alignItems: "center"}} onClick={() => this.remind(row)}><Icon>redo</Icon></div>
          </div>
          <div className="templateTableFiller" />
          <div className="templateTableFieldLogo">
            <div title={translate("FORMS_REVOKE")} style={{color: "darkred", display:"flex", justifyContent: "center", alignItems: "center"}} onClick={() => {this.setState({
                confirmRemove: true,
                confirmRemoveId: row.hash,
            })}}>
              <Icon>highlight_off</Icon>
            </div>
          </div>
          <div className="templateTableFiller" />
        </div>
      )
    })
  }

  getFormsTable(){
    return(
      <div className="templateTable formTable">
        <div className="templateTableHeader">
          <div className="templateTableFieldFixedSmall">
            {translate("FORM_STATE")}
          </div>
          <div className="templateTableFieldSmall">
            {translate("FORM_TEMPLATE")}
          </div>
          <div className="templateTableField">
            {translate("FORM_TEMPLATENAME")}
          </div>
          <div className="templateTableField">
            {translate("FORM_RECIPIENT")}
          </div>
          <div className="templateTableField">
            {translate("TEMPLATE_CREATEDON")}
          </div>
          <div className="templateTableFieldLogo">
            <div title={translate("FORMS_DOWNLOAD")} style={{display:"flex", justifyContent: "center", alignItems: "center"}} onClick={() => this.download()}><Icon>get_app</Icon></div>
          </div>
          <div className="templateTableFiller" />
          <div className="templateTableFieldLogo">
            <div className={(this.state.search? "searchActive" : "")} title={translate("FORMS_SEARCH")} style={{display:"flex", justifyContent: "center", alignItems: "center"}} onClick={() => this.setState((state) => {return {search: !state.search}})}><Icon>search</Icon></div>
          </div>
          <div className="templateTableFiller" />
          <div className="templateTableFieldLogo">
            <Filter
              show={this.state.filterVisible}
              onClick={() => {this.setState(state => { return { filterVisible: !state.filterVisible } })}}
              templates={this.state.templates}
              templateChanged={(value) => this.templateChanged(value)}
              statusChanged={(value) => this.statusChanged(value)}
              onEntered={() => this.setState({filterVisible: true})}
              onExited={() => this.setState({filterVisible: false})}
            />
          </div>
          <div className="templateTableFiller" />
        </div>
        <div className="templateTableContent">
          {this.getFormsTableRows()}
        </div>
      </div>
    )
  }

  confirmRemove(){
    this.setState({
      confirmRemove: false,
      confirmRemoveId: null,
    })
  }

  cancelRemove(){
    this.setState({
      confirmRemove: false,
      confirmRemoveId: null,
    })
  }

  handlePageClick(click){
    this.setState({
      page: click.selected,
      forms: null,
      data: null,
    }, () => {
      this.fetchForms()
      this.fetchStats()
    })
  }

  render() {
    const { limit, page, search, pages } = this.state

    const paginationComponent = (pages > 1)? <ReactPaginate
      previousLabel={"Previous"}
      nextLabel={"Next"}
      breakLabel={"..."}
      pageCount={pages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={(click) => this.handlePageClick(click)}
      containerClassName={'pagination'}
      subContainerClassName={'pages pagination'}
      activeClassName={'active'}
      forcePage={page}
    /> : ""

    return (
      <div className="dashboardContainer">
        <ConfirmationModal
          onCancel={() => {this.cancelRemove()}}
          onHide={() => {this.cancelRemove()}}
          onConfirm={() => {this.confirmRemove()}}
          visible={this.state.confirmRemove}
          body={translate("CONFIRMATIONMODAL_REMOVEFORM_BODY")}
          continueText={translate("CONFIRMATIONMODAL_FORBID")}
          size="md"
        />
        <div className="infoContainer">
          {InfoBox({header: translate("FORMS_COUNT"), class: "confirmedBorder", value: (this.state.stats)? this.state.stats.Created + this.state.stats.Opened + this.state.stats.Submitted : null})}
          {InfoBox({header: translate("FORMS_OPENED"), class: "holdBorder", value: (this.state.stats)? this.state.stats.Opened : null})}
          {InfoBox({header: translate("FORMS_SUBMITTED"), class: "successBorder", value: (this.state.stats)? this.state.stats.Submitted : null})}
        </div>
        {(this.state.search)?
        (
          <div className="searchBar">
            <div className="searchField">
              <Field
                name="search"
                type="search"
                value={this.state.searchFilter}
                placeholder="Hae..."
                onChange={(evnt) => this.setState({searchFilter: evnt.target.value})}
                onClick={() => {
                  this.fetchForms()
                  this.fetchStats()
                }}
              />
            </div>
          </div>
        )
        :
        ""
        }
        {this.getFormsTable()}
        <div className="tablePagination">
          {paginationComponent}
        </div>

      </div>
    );
  }
}

export default Dashboard;

import React, { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import { Popover, Overlay, OverlayTrigger, Button } from 'react-bootstrap';

import Field from '../../../components/field'

import translate from '../../../utils/translate'
import {
  RadialChart,
  DiscreteColorLegend,
  Hint,
} from 'react-vis';

function getColor(i){
  switch(i){
    case 0:
      return "rgb(121, 199, 227)"
    case 1:
      return "#3c98b6"
    case 2:
      return "#6c757d"
    case 3:
      return "rgb(18, 147, 154)"
    case 4:
      return "rgb(26, 49, 119)"
    case 5:
      return "#28a745"
    case 6:
      return "rgb(121, 199, 227)"
    default:
      return "rgb(121, 199, 227)"
  }
}

function getChartLegend(row){
  return (
    <div className="chartLegend">
      <div className="submittedLegend" style={{backgroundColor: row.color}} />
      {translate(row.label)}
    </div>
  )
}

function getHV(name, data){
  return data.find((row) => row.label === name) || {}
}

function PieChart(props) {
  const [hintValue, setHintValue] = useState(false);
  if(!props.data){
    return(
      <div className="radiusChartLoading">
        Loading...
      </div>
    )
  }
  const data = props.data.filter((row) => row.angle > 0).map((row, i) => {
    return {
      ...row,
      color: getColor(i)
    }
  })
  return (
      <div className="radialChart">
        <div className="chartHeader">
          {props.header}
        </div>
        <RadialChart
          data={data}
          width={250}
          height={250}
          innerRadius={75}
          radius={115}
          colorType="literal"
          animation={true}
          onValueMouseOver={v => setHintValue({...v, ...getHV(v.label, data)})}
          onSeriesMouseOut={v => setHintValue(false)}
        >
          {(hintValue !== false) &&
            <Hint value={{[props.header]: hintValue.label, [translate("AMOUNT")]: hintValue.angle, [translate("AMOUNTPERCENT")]: `${hintValue.percent} %`}} />
          }
        </RadialChart>
        <div className="chartLegends">
          {data.map((row) => {
            return getChartLegend(row)
          })}
        </div>
      </div>
    );
}

export default PieChart;

import React from 'react';
import { Button, Modal, OverlayTrigger, Popover, Form } from 'react-bootstrap'
import Icon from '@material-ui/core/Icon';

import BasicData from './basicData'
import Security from './security'

import translate  from '../../../utils/translate'
import Field from '../../../components/field'

const mapConditionTranslation = (term) => {
  switch(term){
    case translate("notempty"):
      return "notempty"
    case translate("includes"):
      return "includes"
    case translate("equals"):
      return "equals"
    case translate("notequals"):
      return "notequals"
    default:
      return term
  }
}

const getSettingsConditionValueSettings = (fieldKey, props) => {
  const field = props.state.form.fields.find(row => row.key === fieldKey)
  if(!field || !field.options || field.options.length < 1){
    return []
  }
  return field.options
}

const getSelectedFieldType = (fieldKey, props) => {
  const field = props.state.form.fields.find(row => row.key === fieldKey)
  if(!field){
    return null
  }
  return field.type
}

const getSettingsConditionSettings = (fieldKey, props) => {
  const field = props.state.form.fields.find(row => row.key === fieldKey)
  if(!field){
    return []
  }
  const type = field.type || "text"
  switch(type){
    case "select":
      return [
        translate("equals"),
        translate("notequals"),
        translate("notempty")
      ]
    case "text":
      return [
        translate("notempty"),
        translate("includes")
      ]
    default:
      return [
        translate("notempty")
      ]
  }
}

const commonModal = (field, onChange, props) => {
  return (
    <div className="advancedSettingsTextModal">
      <h3>{translate("FIELD_ADVANCED_HEADER_FIELD_COMMON")}</h3>
      <Field
        label={translate("FIELD_ADVANCED_COMMON_VISIBLEONTERM")}
        name="visibleOnTerm"
        onChange={(evnt) => {onChange(field.id, "visibleOnTerm", evnt.target.checked)}}
        value={(field.visibleOnTerm !== undefined && field.visibleOnTerm !== null)? field.visibleOnTerm : false}
        type="switch"
      />
      {(field.visibleOnTerm &&
        <div className="visibleOnTermSettings">
          <span className="visibleOnTermText">{translate("FIELD_ADVANCED_COMMON_VISIBLEONTERM_WHEN")}</span>
          <Field
            name="visibleOnTermField"
            onChange={(evnt) => {onChange(field.id, "visibleOnTermField", evnt.target.value); onChange(field.id, "visibleOnTermCondition", null)}}
            value={(field.visibleOnTermField !== undefined && field.visibleOnTermField !== null)? field.visibleOnTermField : null}
            type="select"
            options={
              props.state.form.fields.map((row) => row.key)
            }
          />
          <span className="visibleOnTermText"/>
          <Field
            name="visibleOnTermCondition"
            onChange={(evnt) => {onChange(field.id, "visibleOnTermCondition", mapConditionTranslation(evnt.target.value))}}
            value={(field.visibleOnTermCondition !== undefined && field.visibleOnTermCondition !== null)? translate(field.visibleOnTermCondition) : null}
            type="select"
            options={getSettingsConditionSettings(field.visibleOnTermField, props)}
          />
          <span className="visibleOnTermText"/>
          {(field.visibleOnTerm && (field.visibleOnTermCondition === "equals" || field.visibleOnTermCondition === "notequals") &&
            <Field
              name="visibleOnTermConditionValue"
              onChange={(evnt) => {onChange(field.id, "visibleOnTermConditionValue", evnt.target.value)}}
              value={(field.visibleOnTermConditionValue !== undefined && field.visibleOnTermConditionValue !== null)? field.visibleOnTermConditionValue : null}
              type="select"
              options={getSettingsConditionValueSettings(field.visibleOnTermField, props)}
            />
          )}
          {(field.visibleOnTerm && field.visibleOnTermCondition === "includes" &&
            <Field
              name="visibleOnTermConditionValue"
              onChange={(evnt) => {onChange(field.id, "visibleOnTermConditionValue", evnt.target.value)}}
              value={(field.visibleOnTermConditionValue !== undefined && field.visibleOnTermConditionValue !== null)? field.visibleOnTermConditionValue : null}
              type="text"
            />
          )}
        </div>
      )}
      <Field
        name="infoText"
        label={translate("FIELD_ADVANCED_INFOTEXT_LABLE")}
        onChange={(evnt) => {onChange(field.id, "infoText", (evnt.target.value && evnt.target.value !== "")? evnt.target.value : null)}}
        value={(field.infoText !== undefined && field.infoText !== null)? field.infoText : null}
        type="text"
      />
    </div>
  )
}

const handleModalHeaderChange = (field, pairEvent, onChange, props) => {
  let headers = field.fetchDataHeaders;
  headers[pairEvent.index] = {...headers[pairEvent.index], [pairEvent.name]: pairEvent.value}
  onChange(field.id, "fetchDataHeaders", headers)
}

const textModal = (field, onChange, props) => {
  return (
    <div className="advancedSettingsTextModal">
      {commonModal(field, onChange, props)}
      <h3>{translate("FIELD_ADVANCED_HEADER_FIELD_SPECIFIC")}</h3>
      <Field
        label={translate("FIELD_ADVANCED_TEXTBOX_SUBTYPE_VALIDATION")}
        name="subType"
        onChange={(evnt) => {onChange(field.id, "subType", evnt.target.value)}}
        value={(field.subType !== undefined && field.subType !== null)? field.subType : null}
        type="select"
        options={[
          "text",
          "number"
        ]}
      />
      <Field
        label={translate("FIELD_ADVANCED_TEXT_FETCHENABLED")}
        name="fetchData"
        onChange={(evnt) => {onChange(field.id, "fetchData", evnt.target.checked)}}
        value={(field.fetchData !== undefined && field.fetchData !== null)? field.fetchData : false}
        type="switch"
      />
      {(field.fetchData &&
        <div className="fetchDataAdvancedSettings">
          <h6>Variable</h6>
          <Field
            name="fetchDataInsert"
            onChange={(evnt) => {onChange(field.id, "fetchDataInsert", evnt.target.value)}}
            value={(field.fetchDataInsert !== undefined && field.fetchDataInsert !== null)? field.fetchDataInsert : null}
            type="select"
            options={
              props.state.form.fields.filter((row) => row.id !== props.state.focus).map((row) => row.key)
            }
          />
          <h6>Endpoint</h6>
          <div className="fetchDataAdvancedSettingsUrl">
            <div className="fetchDataAdvancedSettingsUrlMethod">
              <Field
                name="fetchDataMethod"
                onChange={(evnt) => {onChange(field.id, "fetchDataMethod", evnt.target.value)}}
                value={(field.fetchDataMethod !== undefined && field.fetchDataMethod !== null)? field.fetchDataMethod : null}
                type="select"
                options={[
                  "GET",
                  "POST"
                ]}
              />
            </div>
            <div className="fetchDataAdvancedSettingsUrlEndpoint">
              <Field
                name="fetchDataEndpoint"
                onChange={(evnt) => {onChange(field.id, "fetchDataEndpoint", evnt.target.value)}}
                value={(field.fetchDataEndpoint !== undefined && field.fetchDataEndpoint !== null)? field.fetchDataEndpoint : null}
                type="text"
              />
            </div>
          </div>
          <h6>Headers</h6>
          <div className="textPairContainer">
            <div className="headerNameLabel">
              Header
            </div>
            <div className="pairSpacer" />
              Value
          </div>
          {(field.fetchDataHeaders)?
              field.fetchDataHeaders.map((row, index) => {
                return (
                  <Form.Group key={index + "_" + props.name} controlId={`header_control_${index}`}>
                    <div className="textPairContainer">
                      <div className="headerName">
                        <Form.Control name={`header_header_${index}`} key={`header_header_${index}`} defaultValue={row.header} onChange={(evnt) => handleModalHeaderChange(field, { index, name: "header", value: evnt.target.value }, onChange, props)} />
                      </div>
                      <div className="pairSpacer" />
                      <Form.Control name={`header_value_${index}`} key={`header_value_${index}`} defaultValue={row.value} onChange={(evnt) => handleModalHeaderChange(field, { index, name: "value", value: evnt.target.value }, onChange, props)} />
                    </div>
                  </Form.Group>
                )
              })
            :
              ""
          }
          <Button variant="primary" onClick={() => {
            onChange(field.id, "fetchDataHeaders", (field.fetchDataHeaders)? [...field.fetchDataHeaders, {header: "", value: ""}] : [{header: "", value: ""}])
          }}>{translate("BUTTON_ADD")}</Button>
        </div>
      )}
    </div>
  )
}

const textboxModal = (field, onChange, props) => {

  return (
    <div className="advancedSettingsDateModal">
      {commonModal(field, onChange, props)}
      <h3>{translate("FIELD_ADVANCED_HEADER_FIELD_SPECIFIC")}</h3>
      <Field
        label={translate("FIELD_ADVANCED_TEXTBOX_ROWS")}
        name="rows"
        onChange={(evnt) => {onChange(field.id, "rows", parseInt(evnt.target.value))}}
        value={(field.rows)? field.rows : 2}
        type="text"
      />
      <Field
        label={translate("FIELD_ADVANCED_TEXTBOX_INITIAL")}
        name="content"
        onChange={(evnt) => {onChange(field.id, "content", evnt.target.value)}}
        value={(field.content)? field.content : null}
        type="textbox"
      />
    </div>
  )
}

const selectModal = (field, onChange, props) => {

  return (
    <div className="advancedSettingsDateModal">
      {commonModal(field, onChange, props)}
      <h3>{translate("FIELD_ADVANCED_HEADER_FIELD_SPECIFIC")}</h3>
      <Field
        label={translate("FIELD_ADVANCED_SELECT_SEARCHABLE")}
        name="searchable"
        onChange={(evnt) => {onChange(field.id, "searchable", evnt.target.checked)}}
        value={(field.searchable !== undefined && field.searchable !== null)? field.searchable : false}
        type="switch"
      />
    </div>
  )
}

const dateModal = (field, onChange, props) => {
  const rangeStartPopover = (
    <Popover >
      <Popover.Content>
        {translate("FIELD_ADVANCED_DATE_RANGESTART_TOOLTIP")}
      </Popover.Content>
    </Popover>
  )

  const rangeEndPopover = (
    <Popover >
      <Popover.Content>
        {translate("FIELD_ADVANCED_DATE_RANGEEND_TOOLTIP")}
      </Popover.Content>
    </Popover>
  )

  const rangeStartTooltip = (
    <OverlayTrigger placement={"right"} overlay={rangeStartPopover}>
      <Icon fontSize="small" color="primary">info</Icon>
    </OverlayTrigger>
  )

  const rangeEndTooltip = (
    <OverlayTrigger placement={"right"} overlay={rangeEndPopover}>
      <Icon fontSize="small" color="primary">info</Icon>
    </OverlayTrigger>
  )

  return (
    <div className="advancedSettingsDateModal">
      {commonModal(field, onChange, props)}
      <h3>{translate("FIELD_ADVANCED_HEADER_FIELD_SPECIFIC")}</h3>
      <Field
        label={translate("FIELD_ADVANCED_DATE_WEEKENDISBLOCKED")}
        name="weekendIsBlocked"
        onChange={(evnt) => {onChange(field.id, "weekendIsBlocked", evnt.target.checked)}}
        value={(field.weekendIsBlocked !== undefined && field.weekendIsBlocked !== null)? field.weekendIsBlocked : false}
        type="switch"
      />
      <Field
        label={translate("FIELD_ADVANCED_DATE_DISABLEPASTDATES")}
        name="pastIsOutside"
        onChange={(evnt) => {onChange(field.id, "pastIsOutside", evnt.target.checked)}}
        value={(field.pastIsOutside !== undefined && field.pastIsOutside !== null)? field.pastIsOutside : true}
        type="switch"
      />
      <Field
        label={translate("FIELD_ADVANCED_DATE_RANGESTART")}
        name="rangeStart"
        onChange={(evnt) => {onChange(field.id, "rangeStart", parseInt(evnt.target.value))}}
        value={(field.rangeStart !== undefined && field.rangeStart !== null)? field.rangeStart : -1}
        type="number"
        tooltip={rangeStartTooltip}
        disabled={(field.pastIsOutside !== undefined && field.pastIsOutside !== null)? !field.pastIsOutside : false}
      />
      <Field
        label={translate("FIELD_ADVANCED_DATE_DISABLEFUTUREDATES")}
        name="futureIsOutside"
        onChange={(evnt) => {onChange(field.id, "futureIsOutside", evnt.target.checked)}}
        value={(field.futureIsOutside !== undefined && field.futureIsOutside !== null)? field.futureIsOutside : false}
        type="switch"
      />
      <Field
        label={translate("FIELD_ADVANCED_DATE_RANGEEND")}
        name="rangeEnd"
        onChange={(evnt) => {onChange(field.id, "rangeEnd", parseInt(evnt.target.value))}}
        value={(field.rangeEnd !== undefined && field.rangeEnd !== null)? field.rangeEnd : 0}
        type="number"
        tooltip={rangeEndTooltip}
        disabled={(field.futureIsOutside !== undefined && field.futureIsOutside !== null)? !field.futureIsOutside : true}
      />
    </div>
  )
}

const getModalContent = (field, onChange, props) => {
  switch(field.type){
    case "date":
      return dateModal(field, onChange, props)
    case "textbox":
      return textboxModal(field, onChange, props)
    case "select":
      return selectModal(field, onChange, props)
    case "switch":
    default:
      return textModal(field, onChange, props)
  }
}

const modal = (props) => {
  const field = props.state.form.fields.find(row => row.id === props.state.focus)
  if(!field){
    return;
  }
  return (
    <Modal style={{color:"black"}} size="lg" show={props.visible} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title style={{color:"black"}}>{translate("FIELD_ADVANCED_SETTINGS_HEADER")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {getModalContent(field, props.onChange, props)}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => props.onHide()}>{translate("BUTTON_CLOSE")}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default modal;

import React from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap'

import translate  from '../../../utils/translate'
import Field from '../../../components/field'

const modal = (props) => {
  return (
    <Modal style={{color:"black"}} size="lg" show={props.visible} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title style={{color:"black"}}>{translate("TEMPLATE_CREATETEST_HEADER")}</Modal.Title>
      </Modal.Header>
      {(props.creating)?
        <Modal.Body>
          <div className="loading center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </Modal.Body>
        :
      (props.created)?
        <Modal.Body>
          <Field
            label={translate("TEMPLATE_CREATETEST_LINK")}
            name={`CREATETEST_LINK`}
            value={props.created.url}
            type={"openablelink"}
          />
        </Modal.Body>
        :
        <Modal.Body>
          <h3>{translate("TEMPLATE_CREATETEST_INFO")}</h3>
          <Field
            label={translate("TEMPLATE_CREATETEST_RECIPIENT")}
            name={`CREATETEST_RECIPIENT`}
            onChange={(evnt) => props.setState({createRecipient: evnt.value})}
            value={(props && props.user)? props.user.username : ""}
            type={"text"}
          />
          <h3>{translate("TEMPLATE_CREATETEST_DATA")}</h3>
          {props.state.form.fields.map((row, index) => {
            return (
              <Field
                label={row.label}
                name={`field_value_${index}`}
                onChange={(evnt) => { const value = evnt.target.value; props.setState((state) => { return { createData: {...state.createData, [row.key]: value} } })}}
                type={row.type || "text"}
              />
            )
          })}
        </Modal.Body>
      }
      {(props.created || props.creating)?
        <Modal.Footer>
          <Button variant="primary" onClick={() => props.onHide()}>{translate("BUTTON_CLOSE")}</Button>
        </Modal.Footer>
        :
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.onHide()}>{translate("BUTTON_CANCEL")}</Button>
          <Button variant="primary" onClick={() => props.onCreate()}>{translate("BUTTON_CREATE")}</Button>
        </Modal.Footer>
      }

    </Modal>
  )
}

export default modal;

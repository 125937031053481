import React from 'react';

function Error() {
  return (
    <div className="errorContainer">
      <p className="App-intro center">Oh no! Something went wrong!</p>
    </div>
  );
}

export default Error;

import React, { Component } from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap'
import Icon from '@material-ui/core/Icon';
import translate from '../../utils/translate';

import products from './products';

import './style.css';

class Products extends Component {
  getToolTip(text) {
    let popover = (
      <Popover >
        <Popover.Content>
          {text}
        </Popover.Content>
      </Popover>
    )

    return (
      <OverlayTrigger placement={"right"} overlay={popover}>
        <Icon fontSize="small" color="primary">info</Icon>
      </OverlayTrigger>
    )
  }

  getRowHeader(header){
    switch(header){
      case "productName":
        return (
          <div className="productHeaderName">
            Mutko
          </div>
        )
      case "cost":
        return (
          <div className="productHeaderSlogan">
            Selfservice
          </div>
        )
      case "exceedingFormsPricing":
        return (
          <div className="inwards">{translate(header)}</div>
        )
      case "whiteLabels":
        return(
          <div>{translate(header)} {this.getToolTip(translate("WhiteLabelInfoText"))}</div>
        )
      case "encryptionAtRest":
        return(
          <div>{translate(header)} {this.getToolTip(translate("encryptionAtRestInfoText"))}</div>
        )
      case "advancedReports":
        return(
          <div>{translate(header)} {this.getToolTip(translate("advancedReportsInfoText"))}</div>
        )
      default:
        return translate(header)
    }
  }

  getProductAttribute(attribute, value){
    switch(attribute){
      case "productName":
        return (
          <div className="productName">{value}</div>
        )
      case "cost":
        return (
          <div className="cost">{value}</div>
        )
      case "exceedingFormsPricing":
        return (
          <div className="inwardsSub">{translate(value)}</div>
        )
      case "advancedReports":
      case "linkedDomain":
      case "whiteLabel":
      case "encryptionAtRest":
        return (value)? (<Icon style={{fontSize: "25px", color: "green"}}>check</Icon>) : (<Icon style={{fontSize: "25px", color: "#dc3545"}}>close</Icon>)
      default:
        return translate(value)
    }
  }

  getRow(attribute, odd){
    return (
      <div className="productsTableRow">
        <div className={(odd)? "productsTableHeader odd" : "productsTableHeader"}>
          {this.getRowHeader(attribute)}
        </div>
        <div className={(odd)? "productsTableFieldFirst odd" : "productsTableFieldFirst"}>
          {this.getProductAttribute(attribute, products.Preview[attribute])}
        </div>
        <div className={(odd)? "productsTableField odd" : "productsTableField"}>
          {this.getProductAttribute(attribute, products.Starter[attribute])}
        </div>
        <div className={(odd)? "productsTableField odd" : "productsTableField"}>
          {this.getProductAttribute(attribute, products.Basic[attribute])}
        </div>
        <div className={(odd)? "productsTableField odd" : "productsTableField"}>
          {this.getProductAttribute(attribute, products.Premium[attribute])}
        </div>
      </div>
    )
  }

  getProductsTable(){
    return (
      <div className="productsTable">
        {this.getRow("productName")}
        {this.getRow("cost")}
        {this.getRow("includedForms", true)}
        {this.getRow("exceedingFormsPricing")}
        {this.getRow("smsPricing", true)}
        {this.getRow("templateAmount")}
        {this.getRow("encryptionAtRest", true)}
        {this.getRow("advancedReports")}
        {this.getRow("whiteLabel", true)}
      </div>
    )
  }

  render() {
    return (
      <div className="productsContainer">
        {this.getProductsTable()}
      </div>
    );
  }
}

export default Products;

import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';

import InfoBox from '../../components/infobox'

import { apiRequest } from '../../utils/api'
import translate from '../../utils/translate'

import './style.css';

class Dashboard extends Component {
  constructor(){
    super();
    this.state = {
      templates: null,
      stats: null,
    }
  }

  async componentDidMount(){
    this.fetchStats();
    this.fetchTemplates();
  }

  async fetchStats(){
    const stats = await apiRequest({
      method: "GET",
      url: "admin/template/stats"
    })
    this.setState({
      stats,
    })
  }

  async fetchTemplates(){
    const templates = await apiRequest({
      method: "GET",
      url: "admin/templates"
    })
    this.setState({
      templates
    })
  }

  getTemplateAmount(){
    if(this.props.organisation.planId === 3){
      return (
        <div className="templateAmount">
          {this.state.templates.length} / <span className="inclusiveLogo"><Icon>all_inclusive</Icon></span>
        </div>
      )
    }
    return <div className="templateAmount">`${this.state.templates.length} / ${this.props.organisation.maxTemplates}`</div>
  }

  render() {
    return (
      <div className="dashboardContainer">
        <div className="dashboardInfoContainer">
          {InfoBox({header: translate("TEMPLATE_AMOUNTUSED"), centered: true, class: "confirmedBorder templatesUsed", value: (this.state.templates)? this.getTemplateAmount() : null})}
          {InfoBox({header: translate("FORMS_AMOUNTUSED"), centered: true, class: "confirmedBorder templatesUsed", value: (this.state.stats)? `${this.state.stats.created.toLocaleString("fi-FI")} / ${this.props.organisation.maxMonthlyForms.toLocaleString("fi-FI")}` : null})}
        </div>
      </div>
    );
  }
}

export default Dashboard;

import React, { Component } from 'react';
import moment from 'moment';
import { Spinner, Form, Button, Popover, OverlayTrigger, InputGroup, FormControl } from 'react-bootstrap'
import { SingleDatePicker } from 'react-dates';
import SignatureCanvas from 'react-signature-canvas'
import { fromatFromUTCString, formatDateToMonth } from './helper'
import Icon from '@material-ui/core/Icon';
import Select from 'react-select';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import translate from '../../utils/translate'
import Config from '../../Config'

import './style.css';

class Field extends Component {
  constructor(){
    super();

    this.state = {
      date: null,
      currencyValue: "0,00"
    }

    this.sigPad = null;
    this.onChange = this.onChange.bind(this);
    this.textArea = null;
  }

  copyToClipboard = (e) => {
    if(!this.textArea) {
      return console.log("Textarea ref not set")
    }
    console.log(this.textArea)
    this.textArea.select();
    document.execCommand('copy');
    this.setState({ copied: true });
    return console.log("Copied text")
  };

  componentDidMount(){
    if(this.props.type && this.props.type === "date"){
      this.setState({
        date: (this.props.value)? moment(this.props.value) : null
      })
    }
    if(this.props.type && this.props.type === "currency"){
      this.setState({
        currencyValue: this.props.value || "0,00"
      })
    }
  }

  getDisabled(){
    const { date } = this.state

    if(this.props.type && this.props.type === "button"){
      return (
        <Button variant={this.props.variant || "primary"} type="submit" disabled>
          {this.props.label}
        </Button>
      )
    }
    if(this.props.type && this.props.type === "switch"){
      return(
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="switch"
            id={this.props.name}
            name={this.props.name}
            label={<Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>}
            onChange={this.props.onChange}
            checked={this.props.value || false}
            disabled
          />
        </Form.Group>
      )
    }
    if(this.props.type && this.props.type === "signaturecanvas"){
      return(
        <div key={this.props.name} >
          <div className="date-picker-header">{this.props.label}</div>
          <img
            className="signatureImage"
            src={`${Config.apiEndpoint}form/attachment/${this.props.value}`}
            alt="Loading"
          />
        </div>
      )
    }
    if(this.props.type && this.props.type === "select"){
      return (
        <Form.Group key={this.props.name} controlId={this.props.name}>
          <Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>
          <div className={(this.props.isInvalid && this.props.validationError && this.props.validationError === this.props.isInvalid) ? "invalid" : ""}>
            <Form.Control name={this.props.name} defaultValue={this.props.value || "Valitse..."} style={{height: "100%"}} as="select" disabled>
              <option>{translate("OPTION_EMPTY")}</option>
              {(this.props.options) ? this.props.options.map((row) => { return <option>{row}</option>}) : ""}
            </Form.Control>
          </div>
        </Form.Group>
      )
    }
    if(this.props.type && this.props.type === "textbox"){
      return(
        <Form.Group controlId={this.props.name} key={this.props.name}>
          {(this.props.label) &&
            <Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>
          }
          <InputGroup key={this.props.name} controlId={this.props.name} hasValidation={this.props.required || undefined}>
            <div style={{width: "100%"}}>
              <Form.Control
                title={this.props.title}
                name={this.props.name}
                as="textarea"
                rows={this.props.rows || "2"}
                defaultValue={this.props.value}
                placeholder={this.props.placeholder}
                disabled
              />
            </div>
            {(this.props.feedback &&
              <Form.Control.Feedback type={(this.props.feedback && this.props.feedback.type)? this.props.feedback.type : undefined}>{this.props.feedback.message}</Form.Control.Feedback>
            )}
            {(this.props.infoText &&
              <Form.Text className="text-muted">{this.props.infoText}</Form.Text>
            )}
          </InputGroup>
        </Form.Group>
      )
    }
    if(this.props.type && this.props.type === "date"){
      return (
        <div key={this.props.name} >
          <div className="date-picker-header">{this.props.label}</div>
              <div className="date-picker">
                  <div className={(this.props.isInvalid && this.props.validationError && this.props.validationError === this.props.isInvalid) ? "invalid" : ""}>
                    <SingleDatePicker
                      placeholder="Valitse..."
                      date={date} // momentPropTypes.momentObj or null
                      onDateChange={date => {this.setState({ date }, () => this.dateChange())}} // PropTypes.func.isRequired
                      focused={this.state.datePickerFocused} // PropTypes.bool
                      onFocusChange={({ focused }) => this.setState({ datePickerFocused: focused })} // PropTypes.func.isRequired
                      showDefaultInputIcon={true}
                      required={true}
                      numberOfMonths={1}
                      inputIconPosition="after"
                      id="taskDatePicker" // PropTypes.string.isRequired,
                      displayFormat="DD.MM.YYYY"
                      hideKeyboardShortcutsPanel={true}
                      openDirection="up"
                      disabled
                    />
                </div>
              </div>
        </div>
      )
    }
    if(this.props.type && this.props.type === "datetime"){
      return(
        <div className="fieldContainer" key={this.props.name}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>
            <Form.Control title={formatDateToMonth(new Date(this.props.value))} type="text" value={formatDateToMonth(new Date(this.props.value))} placeholder={this.props.placeholder} disabled />
          </Form.Group>
        </div>
      )
    }
    if(this.props.type && this.props.type === "currency"){
      return (
        <Form.Group key={this.props.name} controlId={this.props.name} style={this.props.groupStyle || {}}>
          {(this.props.label)?
            (
              <Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>
            )
            :
            ""
          }
          <Form.Control title={(this.props.suffix)? this.props.value + this.props.suffix : this.props.value} type="text" value={parseFloat(this.props.value).toLocaleString('fi-FI', { style: 'currency', currency: 'EUR' })} placeholder={this.props.placeholder} disabled />
        </Form.Group>
      );
    }
    if(this.props.type && this.props.type === "time"){
      const _value = (this.props.value)? moment(this.props.value).format("HH:mm") : null
      return (
        <Form.Group key={this.props.name} controlId={this.props.name} style={this.props.groupStyle || {}}>
          {(this.props.label)?
            (
              <Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>
            )
            :
            ""
          }
          <div style={{width: "100%"}}>
            <Form.Control title={(this.props.suffix)? _value + this.props.suffix : _value} type={"text"} value={(this.props.suffix)? _value + this.props.suffix : _value} placeholder={this.props.placeholder} disabled />
            {(this.props.feedback &&
              <Form.Control.Feedback type={(this.props.feedback && this.props.feedback.type)? this.props.feedback.type : undefined}>{this.props.feedback.message}</Form.Control.Feedback>
            )}
          </div>
          {(this.props.infoText &&
            <Form.Text className="text-muted">{this.props.infoText}</Form.Text>
          )}
        </Form.Group>
      );
    }
    return (
      <Form.Group key={this.props.name} controlId={this.props.name} style={this.props.groupStyle || {}}>
        {(this.props.label)?
          (
            <Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>
          )
          :
          ""
        }
        <div style={{width: "100%"}}>
          <Form.Control title={(this.props.suffix)? this.props.value + this.props.suffix : this.props.value} type={this.props.type || "text"} value={(this.props.suffix)? this.props.value + this.props.suffix : this.props.value} placeholder={this.props.placeholder} disabled />
          {(this.props.feedback &&
            <Form.Control.Feedback type={(this.props.feedback && this.props.feedback.type)? this.props.feedback.type : undefined}>{this.props.feedback.message}</Form.Control.Feedback>
          )}
        </div>
        {(this.props.infoText &&
          <Form.Text className="text-muted">{this.props.infoText}</Form.Text>
        )}
      </Form.Group>
    );
  }

  onChange(evnt){
    if(this.props.type && this.props.type === "currency"){
      if(evnt.target.value === ""){
        this.setState({
          currencyValue: evnt.target.value
        })
        return this.props.onChange({
          target: {
            name: evnt.target.name,
            value: 0
          }
        })
      }
      if(
        (evnt.target.value.split(",").length > 1 && evnt.target.value.split(",")[1].length > 2)
        ||
        (evnt.target.value.split(".").length > 1 && evnt.target.value.split(".")[1].length > 2)
      ){
        return;
      }
      if(!(new RegExp('^[0-9]*$').test(evnt.target.value.replaceAll(" ", "").replace(",", "").replace(".", "")))){
        console.log("Illegal character in currency field", evnt.target.name)
        return;
      }
      const value = parseFloat(evnt.target.value.replaceAll(" ", "").replace(",", "."))
      this.setState({
        currencyValue: evnt.target.value
      })
      return this.props.onChange({
        target: {
          name: evnt.target.name,
          value: parseFloat(value)
        }
      })
    }

    if(evnt.target.type === "checkbox"){
      evnt.target.value = evnt.target.checked
    }

    this.props.onChange(evnt)
  }

  dateChange(evnt){
    this.props.onChange({target: {name: this.props.name, value: new Date(this.state.date.valueOf()).toISOString()}})
  }

  handleCanvasChange(){
    if(this.props.onEnd){
      this.props.onEnd(this.props.name, this.sigPad.toDataURL("base64string"))
    }
  }

  render() {
    const { date } = this.state

    if(this.props.disabled){
      return this.getDisabled()
    }
    if(this.props.type && this.props.type === "button"){
      return (
        <Button variant={this.props.variant || "primary"} type="submit" onClick={() => {(this.props.onClick !== undefined)? this.props.onClick() : console.log("Clicked button without action")}}>
          {this.props.label}
        </Button>
      )
    }
    if(this.props.type && this.props.type === "switch"){
      return(
        <Form.Group controlId={this.props.name}>
          <Form.Check
            type="switch"
            id={this.props.name}
            name={this.props.name}
            label={<Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>}
            onChange={this.props.onChange}
            defaultChecked={this.props.value || false}
            isValid={(this.props.feedback)? this.props.feedback.valid : undefined}
            isInvalid={(this.props.feedback)? !this.props.feedback.valid : undefined}
            required={this.props.required || undefined}
          />
          {(this.props.feedback &&
            <Form.Control.Feedback type={(this.props.feedback && this.props.feedback.type)? this.props.feedback.type : undefined}>{this.props.feedback.message}</Form.Control.Feedback>
          )}
        </Form.Group>
      )
    }
    if(this.props.type && this.props.type === "signaturecanvas"){
      return(
        <div key={this.props.name} >
          <div className="date-picker-header">{this.props.label}</div>
          <SignatureCanvas
            canvasProps={{className: 'signatureCanvas'}}
            ref={(ref) => { this.sigPad = ref }}
            onEnd={() => { this.handleCanvasChange() }}
          />
        </div>
      )
    }
    if(this.props.type && this.props.type === "textbox"){
      return(
        <Form.Group controlId={this.props.name} key={this.props.name}>
          {(this.props.label) &&
            <Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>
          }
          <InputGroup key={this.props.name} controlId={this.props.name} hasValidation={this.props.required || undefined}>
            <div style={{width: "100%"}}>
              <Form.Control
                title={this.props.title}
                name={this.props.name}
                as="textarea"
                rows={this.props.rows || "2"}
                defaultValue={this.props.value}
                placeholder={this.props.placeholder}
                onChange={this.onChange}
                readOnly={this.props.readOnly || false}
              />
            </div>
            {(this.props.feedback &&
              <Form.Control.Feedback type={(this.props.feedback && this.props.feedback.type)? this.props.feedback.type : undefined}>{this.props.feedback.message}</Form.Control.Feedback>
            )}
            {(this.props.infoText &&
              <Form.Text className="text-muted">{this.props.infoText}</Form.Text>
            )}
          </InputGroup>
        </Form.Group>
      )
    }
    if(this.props.type && this.props.type === "time"){
      return (
        <div key={this.props.name} >
          <div className="timePicker">
            <div className="timePickerLable">
              <div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div>
            </div>
            <TimePicker
              style={{ width: 100 }}
              showSecond={false}
              defaultValue={(this.props.value)? moment(this.props.value) : moment()}
              className="time-picker"
              onChange={(value) => {
                this.onChange({
                  target: {
                    name: this.props.name,
                    value: (value)? value.valueOf() : null
                  }
                })
              }}
            />
          </div>
        </div>
      )
    }
    if(this.props.type && this.props.type === "date"){
      return (
        <div key={this.props.name} >
          <div className="date-picker-header">{this.props.label}</div>
            <div className="handoverDateField">
              <div className="date-picker">
                  <div className={(this.props.feedback && this.props.feedback.valid === false) ? "invalidDate" : ""}>
                    <SingleDatePicker
                      placeholder="Valitse..."
                      date={date} // momentPropTypes.momentObj or null
                      onDateChange={date => {this.setState({ date }, () => this.dateChange())}} // PropTypes.func.isRequired
                      focused={this.state.datePickerFocused} // PropTypes.bool
                      onFocusChange={({ focused }) => this.setState({ datePickerFocused: focused })} // PropTypes.func.isRequired
                      showDefaultInputIcon={true}
                      required={true}
                      numberOfMonths={1}
                      inputIconPosition="after"
                      id="taskDatePicker" // PropTypes.string.isRequired,
                      displayFormat="DD.MM.YYYY"
                      hideKeyboardShortcutsPanel={true}
                      openDirection="down"
                      required={this.props.required || undefined}
                      firstDayOfWeek={1}
                      isOutsideRange={(day) => {
                        const pastIsOutsideRange = (this.props.pastIsOutside !== undefined && this.props.pastIsOutside !== null)? this.props.pastIsOutside : true
                        const futureIsOutsideRange = (this.props.futureIsOutside !== undefined && this.props.futureIsOutside !== null)? this.props.futureIsOutside : false
                        const startDate = (this.props.rangeStart)? moment().add(this.props.rangeStart, 'days') : moment().add(-1, 'days');
                        const endDate = (this.props.rangeEnd)? moment().add(this.props.rangeEnd, 'days') : moment().add(1, 'days');
                        const pastValue = (pastIsOutsideRange)? day.isBefore(startDate) : false
                        const futureValue = (futureIsOutsideRange)? day.isAfter(endDate) : false
                        return (this.props.isOutsideRange)? this.props.isOutsideRange(day) : pastValue;
                      }}
                      isDayBlocked={(day) => {
                        const futureIsOutsideRange = (this.props.futureIsOutside !== undefined && this.props.futureIsOutside !== null)? this.props.futureIsOutside : false
                        const endDate = (this.props.rangeEnd)? moment().add(this.props.rangeEnd, 'days') : moment().add(0, 'days');
                        const futureValue = (futureIsOutsideRange)? day.isAfter(endDate) : false
                        if(futureIsOutsideRange && futureValue){
                          return true
                        }

                        const pastIsOutsideRange = (this.props.pastIsOutside !== undefined && this.props.pastIsOutside !== null)? this.props.pastIsOutside : true
                        const startDate = (this.props.rangeStart)? moment().add(this.props.rangeStart, 'days') : moment().add(-1, 'days');
                        const pastValue = (pastIsOutsideRange)? day.isBefore(startDate) : false
                        if(pastIsOutsideRange && pastValue){
                          return true
                        }

                        const isWeekend = (day.day() === 0 || day.day() === 6)
                        const weekendIsBlocked = (this.props.weekendIsBlocked !== undefined && this.props.weekendIsBlocked !== null)? this.props.weekendIsBlocked : false
                        const defaultValue = (weekendIsBlocked)? isWeekend : false
                        return (this.props.isDayBlocked)? this.props.isDayBlocked(day) : defaultValue;
                      }}
                    />
                </div>
                <div className={(this.props.feedback && this.props.feedback.valid === false)? "invalidFeedbackText" : "feedbackText"}>
                  {(this.props.feedback)? this.props.feedback.message : null}
                </div>
              </div>
            </div>
        </div>
      )
    }
    if(this.props.type && this.props.type === "select-searchable"){
      const options = (this.props.searchableFilter && this.props.searchableFilter !== "" && this.props.searchableFilter !== "Valitse..." && this.props.searchableOptions)? this.props.searchableOptions.filter((row) => {
        if(row.filter && row.filter.includes(this.props.searchableFilter)){
          return true;
        }
        if(row.label && row.label.includes(this.props.searchableFilter)){
          return true;
        }
        if(row.value && row.value.includes(this.props.searchableFilter)){
          return true;
        }
        return false;
      }) : this.props.searchableOptions
      return (
        <div className="selectSearchableContainer">
          {(this.props.label) &&
            <div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div></div>
          }
          <div className="selectSearchableField">
            <Select
              name={this.props.name}
              options={options}
              onChange={(evnt) => this.onChange({
                target: {
                  name: this.props.name,
                  value: evnt.value,
                  type: "select-searchable"
                }
              })}
            />
        </div>
        </div>
      )
    }
    if(this.props.type && this.props.type === "select"){
      return (
        <Form.Group key={this.props.name} controlId={this.props.name}>
          {(this.props.label)?
          <Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>
          :
          ""
          }
          <div className={(this.props.isInvalid && this.props.validationError && this.props.validationError === this.props.isInvalid) ? "invalid" : ""}>
            {(this.props.forceValue)?
              <Form.Control
                size={this.props.size || ""}
                name={this.props.name}
                value={this.props.value || this.props.placeholder || translate("OPTION_EMPTY")}
                style={{height: "100%"}} as="select"
                onChange={this.onChange}
                isValid={(this.props.feedback)? this.props.feedback.valid : undefined}
                isInvalid={(this.props.feedback)? !this.props.feedback.valid : undefined}
                required={this.props.required || undefined}
              >
                <option>{this.props.placeholder || translate("OPTION_EMPTY")}</option>
                {(this.props.options) ? this.props.options.map((row) => { return <option>{row}</option>}) : ""}
              </Form.Control>
              :
              <Form.Control
                size={this.props.size || ""}
                name={this.props.name}
                defaultValue={this.props.value || this.props.placeholder || translate("OPTION_EMPTY")}
                style={{height: "100%"}}
                as="select"
                onChange={this.onChange}
                isValid={(this.props.feedback)? this.props.feedback.valid : undefined}
                isInvalid={(this.props.feedback)? !this.props.feedback.valid : undefined}
                required={this.props.required || undefined}
                searchable={(this.props.searchable)? "Search..." : undefined}
              >
                <option>{this.props.placeholder || translate("OPTION_EMPTY")}</option>
                {(this.props.options) ? this.props.options.map((row) => { return <option>{row}</option>}) : ""}
              </Form.Control>
            }
            {(this.props.feedback &&
              <Form.Control.Feedback type={(this.props.feedback && this.props.feedback.type)? this.props.feedback.type : undefined}>{this.props.feedback.message}</Form.Control.Feedback>
            )}
          </div>

        </Form.Group>
      )
    }
    if(this.props.type && this.props.type === "number"){
      return (
        <Form.Group key={this.props.name} controlId={this.props.name}>
          <Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>
          <div className={(this.props.isInvalid && this.props.validationError && this.props.validationError === this.props.isInvalid) ? "invalid" : ""}>
            <Form.Control name={this.props.name} title={this.props.value} type="number" defaultValue={this.props.value} placeholder={this.props.placeholder} onChange={this.onChange} />
          </div>
        </Form.Group>
      )
    }
    if(this.props.type && this.props.type === "text-pair"){
      return (
        <Form.Group key={this.props.name} controlId={this.props.name}>
          <Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>
          <div className="textPairContainer">
            <div className={(this.props.isInvalid && this.props.validationError && this.props.validationError === this.props.isInvalid) ? "invalid" : ""}>
              <Form.Control name={this.props.box1} title={this.props.value} type="number" defaultValue={this.props.value} placeholder={this.props.placeholder} onChange={this.onChange} />
            </div>
            <div className={(this.props.isInvalid && this.props.validationError && this.props.validationError === this.props.isInvalid) ? "invalid" : ""}>
              <Form.Control name={this.props.box2} title={this.props.value} type="number" defaultValue={this.props.value} placeholder={this.props.placeholder} onChange={this.onChange} />
            </div>
          </div>
        </Form.Group>
      )
    }
    if(this.props.type && this.props.type === "search"){
      return (
        <InputGroup className="custom-search">
          <FormControl
            name={this.props.name}
            title={this.props.value}
            value={this.props.value}
            placeholder={this.props.placeholder}
            onChange={this.onChange}
          />
          <InputGroup.Append>
            <Button bsPrefix="btn btn-primary btn-zerotop" onClick={() => this.props.onClick()} variant={this.props.variant || "primary"}>{this.props.buttonText || "Search"}</Button>
          </InputGroup.Append>
        </InputGroup>
      );
    }
    if(this.props.type && this.props.type === "openablelink"){
      return (
        <InputGroup className="custom-link">
          <FormControl
            name={this.props.name}
            title={this.props.value}
            value={this.props.value}
            placeholder={this.props.placeholder}
            onChange={this.onChange}
            disable={true}
          />
          <InputGroup.Append>
            <Button bsPrefix="btn btn-primary btn-zerotop" onClick={() => window.open(this.props.value, "_blank")} variant={this.props.variant || "primary"}>{this.props.buttonText || "Open"}</Button>
          </InputGroup.Append>
        </InputGroup>
      );
    }
    if(this.props.type && this.props.type === "copy"){
      return (
        <Form.Group key={this.props.name} controlId={this.props.name} hasValidation={this.props.required || undefined} >
          {(this.props.label)?
            (
              <Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>
            )
            :
            ""
          }
          <InputGroup key={this.props.name} controlId={this.props.name} hasValidation={this.props.required || undefined} className="mb-3">
              <FormControl
                name={this.props.name}
                title={(this.props.feedback && this.props.feedback.title)? this.props.feedback.title : this.props.value}
                type="text"
                value={this.props.value}
                placeholder={this.props.placeholder}
                isValid={(this.props.feedback)? this.props.feedback.valid : undefined}
                isInvalid={(this.props.feedback)? !this.props.feedback.valid : undefined}
                required={this.props.required || undefined}
                ref={(textarea) => this.textArea = textarea}
                readOnly={true}
              />
              <InputGroup.Append>
                <div className="justifyMarginForButton">
                  <Button variant="outline-secondary" onClick={() => {this.copyToClipboard()}}>{(this.state.copied)? <div className="iconInButton"><Icon>check</Icon></div> : "Copy"}</Button>
                </div>
              </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      );
    }
    if(this.props.type && this.props.type === "currency"){
      return (
        <Form.Group key={this.props.name} controlId={this.props.name} hasValidation={this.props.required || undefined} >
          {(this.props.label)?
            (
              <Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>
            )
            :
            ""
          }
          <InputGroup key={this.props.name} controlId={this.props.name} hasValidation={this.props.required || undefined} className="mb-3">
            <FormControl
              name={this.props.name}
              title={(this.props.feedback && this.props.feedback.title)? this.props.feedback.title : this.props.value}
              type="text"
              value={this.state.currencyValue}
              placeholder={this.props.placeholder}
              onChange={this.onChange}
              isValid={(this.props.feedback)? this.props.feedback.valid : undefined}
              isInvalid={(this.props.feedback)? !this.props.feedback.valid : undefined}
              required={this.props.required || undefined}
            />
            <InputGroup.Append>
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup.Append>
            {(this.props.feedback &&
              <Form.Control.Feedback type={(this.props.feedback && this.props.feedback.type)? this.props.feedback.type : undefined}>{this.props.feedback.message}</Form.Control.Feedback>
            )}
          </InputGroup>
        </Form.Group>
      );
    }
    return (
      <Form.Group key={this.props.name} controlId={this.props.name} hasValidation={this.props.required || undefined} >
        {(this.props.label)?
          (
            <Form.Label><div className="labelRow"><div className={this.props.labelClass || ""}>{this.props.label}</div> <div className="labelTooltip">{this.props.tooltip}</div></div> </Form.Label>
          )
          :
          ""
        }
        <InputGroup key={this.props.name} controlId={this.props.name} hasValidation={this.props.required || undefined}>
          <div style={{width: "100%"}}>
            <Form.Control
              name={this.props.name}
              title={(this.props.feedback && this.props.feedback.title)? this.props.feedback.title : this.props.value}
              type={this.props.type || this.props.subType || "text"}
              defaultValue={this.props.value}
              placeholder={this.props.placeholder}
              onChange={this.onChange}
              isValid={(this.props.feedback)? this.props.feedback.valid : undefined}
              isInvalid={(this.props.feedback)? !this.props.feedback.valid : undefined}
              required={this.props.required || undefined}
            />
            {(this.props.feedback &&
              <Form.Control.Feedback type={(this.props.feedback && this.props.feedback.type)? this.props.feedback.type : undefined}>{this.props.feedback.message}</Form.Control.Feedback>
            )}
          </div>
          {(this.props.infoText &&
            <Form.Text className="text-muted">{this.props.infoText}</Form.Text>
          )}
        </InputGroup>
      </Form.Group>
    );
  }
}

export default Field;

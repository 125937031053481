import React from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap'

import translate  from '../../../utils/translate'
import Field from '../../../components/field'

const modal = (props) => {
  return (
    <Modal style={{color:"black"}} size="lg" show={props.visible} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title style={{color:"black"}}>{translate("DEMO_MODAL_HEADER")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>{translate("DEMO_MODAL_FIELD_HEADER")}</h3>
        <Field
          label={translate("DEMO_MODAL_FIELD_VEHICLE")}
          name={`DEMO_MODAL_FIELD_VEHICLE`}
          onChange={(evnt) => props.onChange({key: "vehicle", value: evnt.target.value})}
          value={(props.data && props.data.vehicle)? props.data.vehicle : ""}
          type="text"
        />
        <Field
          label={translate("DEMO_MODAL_FIELD_INCLUDEDKM")}
          name={`DEMO_MODAL_FIELD_INCLUDEDKM`}
          onChange={(evnt) => props.onChange({key: "includedKm", value: evnt.target.value})}
          value={(props.data && props.data.includedKm)? props.data.includedKm : ""}
          type="text"
        />
        <Field
          label={translate("DEMO_MODAL_FIELD_ESTDELIVERY")}
          name={`DEMO_MODAL_FIELD_ESTDELIVERY`}
          onChange={(evnt) => props.onChange({key: "estDelvivery", value: evnt.target.value})}
          type="date"
        />
        <Field
          label={translate("DEMO_MODAL_FIELD_RECIPIENT")}
          name={`DEMO_MODAL_FIELD_RECIPIENT`}
          onChange={(evnt) => props.onChange({key: "recipient", value: evnt.target.value})}
          value={(props.data && props.data.recipient)? props.data.recipient : ""}
          type="text"
        />
        <Field
          label={translate("DEMO_MODAL_FIELD_RIM")}
          name={`DEMO_MODAL_FIELD_RIM`}
          onChange={(evnt) => props.onChange({key: "rim", value: evnt.target.value})}
          value={(props.data && props.data.rim)? props.data.rim : ""}
          type="text"
        />
      </Modal.Body>
      {(props.created || props.creating)?
        <Modal.Footer>
          <Button variant="secondary" disabled>{translate("BUTTON_CLOSE")}</Button>
          <Button variant="primary" disabled>{translate("BUTTON_CREATE")}</Button>
        </Modal.Footer>
        :
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.onHide()}>{translate("BUTTON_CLOSE")}</Button>
          <Button variant="primary" onClick={() => props.onCreate()}>{translate("BUTTON_CREATE")}</Button>
        </Modal.Footer>
      }

    </Modal>
  )
}

export default modal;

import React, { useState } from 'react';
import Lottie from 'react-lottie';

import Logo from '../../../../resources/Mutko_Favicon_1.png'
import Done from '../../../../resources/done.json'
import Loading from '../../../../resources/loading.json'


import Icon from '@material-ui/core/Icon';
import { Badge } from "react-bootstrap";

import translate from '../../../../utils/translate'

function PostSubmit(props) {
  const [animationEnded, setAnimationEnded] = useState(false);
  if(props.submitting){
    return (
      <div className="contentContainer" style={props.theme.container || {}}>
          <div className="formContainer containerBox" style={props.theme.formContainer}>
            <Lottie options={{
                  loop: true,
                  autoplay: true,
                  animationData: Loading,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  },
                }}
                height={200}
                width={200}
              />
          </div>
      </div>
    );
  }
  return (
    <div className="contentContainer" style={props.theme.container || {}}>
        <div className="formContainer containerBox" style={props.theme.formContainer}>
          <Lottie options={{
                loop: false,
                autoplay: true,
                animationData: Done,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                },
                eventListener: [
                  {
                    eventName: 'complete',
                    callback: () => console.log('the animation completed:'),
                  },
                ]
              }}
              height={200}
              width={200}
            />
        </div>
    </div>
  );
}

export default PostSubmit;

import React, { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import { Badge } from "react-bootstrap";

import Activity from "./activity"
import translate from '../../../utils/translate'
import { formatDate } from '../../../utils/helper'

function Activities(props) {
  const activities = props.logs.filter(row => row.logType != "admin").map((row, index) => {
    return Activity(row, index)
  })
  return (
    <div className="activityPath">
      <div className="basicInfoHeader">
        {translate("ACTIVITYLOG")}
      </div>
      <div className="activityPathActivites">
        {activities}
        <div className="activity activityStart">
          <div className="startActivityPath">
            <span className="basicActivityObject basicActivityObjectStart"/>
          </div>
          <div className="basicActivityContent">
            <div className="basicActivityField basicActivityTimestamp">
              {formatDate(new Date(props.createdOn))}
            </div>
            <div className="basicActivityField basicActivityName">
              {translate("CREATED_ACTIVITY_SHORT")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Activities;

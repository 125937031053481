import React from 'react';
import { Button, Modal } from 'react-bootstrap'

import BasicData from './basicData'
import Security from './security'

import translate  from '../../../utils/translate'
import Field from '../../../components/field'

const modal = (props) => {
  return (
    <Modal style={{color:"black"}} size="lg" show={props.visible} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title style={{color:"black"}}>{translate("INFOMODAL_HEADER")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BasicData
          state={props.state}
          setState={props.setState}
          organisation={props.organisation}
          themeChanged={props.themeChanged}
          setTemplateProp={props.setTemplateProp}
        />
        <Security
          state={props.state}
          setState={props.setState}
          organisation={props.organisation}
          setTemplateProp={props.setTemplateProp}
        />

      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => props.onHide()}>{translate("BUTTON_CLOSE")}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default modal;

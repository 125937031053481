import React, { Component } from 'react';
import { Spinner, Button, Popover, OverlayTrigger, ButtonGroup, Form, Card, Modal } from 'react-bootstrap'
import Icon from '@material-ui/core/Icon';
import { v4 } from 'uuid';

import Field from '../../components/field'
import Attachment from '../../components/attachment'
import { apiRequest } from '../../utils/api'
import { createWelcomeMessage, formatDate, formatDateToMonth } from '../../utils/helper'
import translate, { FieldTypes, FieldTypeValue, FieldTypeName, AutomationTriggers, AutomationTriggerName, AutomationTriggerValue }  from '../../utils/translate'
import EmailEditor from '../../components/emaileditor'
import Logo from '../../resources/Latentti-logo-musta.svg'

import BasicData from './components/basicData'
import Security from './components/security'
import InfoModal from './components/infoModal'
import CreateModal from './components/createModal'
import ActiveField from './components/activeField'
import AdvancedSettingsModal from './components/advancedSettingsModal'

//Org specific automation views
import secto_update_erp from './organisation/secto/updateErp'

import FormPreview from '../form'

import './style.css';

import Example from './new.json';


class Create extends Component {
  constructor(){
    super();

    this.state = {
      data: {},
      submit: {},
      loading: true,
      error: null,
      submitting: false,
      message: null,
      validationError: null,
      approving: false,
      form: null,
      theme: null,
      focus: null,
      preview: false,
      automations: null,
      emailEditorContentHtml: null,
      emailEditorContentRaw: null,
      saving: false,
      name: "",
      eid: null,
      changed: false,
      secureHash: true,
      editInfo: false,
      automationTypes: [],
      createData: {},
      created: null,
      creating: false,
      dragging: false,
      advancedFieldSettings: false,
      activeField: null,
    }

  }

  async componentDidMount(){
    let automationTypes = [
      "email",
      "webhook",
    ]

    this.setState({
      automationTypes: this.props.organisation.automationTypes || automationTypes
    })

    try {
      const eid = this.props.args[1]
      if(eid){
        const template = await apiRequest({
          method: "GET",
          url: `/admin/template/${eid}`
        })
        template.template.fields = template.template.fields.map((row) => { if(!row.id) row.id = v4(); return row })
        return this.setState({
          form: template.template,
          theme: template.theme,
          data: template.data || {},
          submit: template.submit || template.data || {},
          automations: template.automations,
          name: template.name,
          eid: template.eid,
          secureHash: template.secureHash || true,
          publicUrl: template.publicUrl || null,
          loading: false,
          focus: (template.template && template.template.fields && template.template.fields.length > 0)? template.template.fields[0].id : null
        })
      }else{
        this.setState({
          form: Example.template,
          theme: Example.theme,
          data: Example.data,
          submit: Example.submit || Example.data,
          automations: Example.automations,
          name: "Template",
          loading: false,
          focus: (Example.template && Example.template.fields && Example.template.fields.length > 0)? Example.template.fields[0].id : null,
          editInfo: true,
        })
      }

    }catch(ex){
      console.log(ex)
      this.setState({
        error: ex,
        loading: false
      })
    }
  }

  formatKey(str){
    return str
            .replace(/ /g, '_')
            .replace(/å/g, 'a')
            .replace(/ä/g, 'a')
            .replace(/ö/g, 'o')
            .replace(/[^a-zA-Z0-9_]/g, '')
  }

  setTemplateProp(prop, value){
    this.setState((state) => {
      return {
        form: {...state.form, [prop]: value},
        changed: true
      }
    })
  }

  onChange(key, prop, value){
    this.setState((state) => {
      const fields = state.form.fields.map((row) => {
        if(row.key === key || row.id === key){
          if(prop === "label" && !row.cKey){
            row.key = this.formatKey(value)
          }
          if(prop === "key"){
            row.cKey = true
            value = this.formatKey(value)
          }
          row[prop] = value
        }
        return row
      })
      return {
        form: {...state.form, fields},
        changed: true
      }
    })
  }

  add(){
    this.setState((state) => {
      const id = v4()
      return {
        focus: id,
        form: {...state.form, fields: [...state.form.fields, {id, key: `field_${state.form.fields.length}`, label: `field_${state.form.fields.length}`, disabled: false}]},
        changed: true
      }
    })
  }

  preview(){
    this.setState({
      preview: true
    })
  }

  async save(){
    const { form, automations, theme, name, eid, secureHash } = this.state
    console.log({secureHash})
    this.setState({
      saving: true
    })
    try{
      if(eid){
        const update = await apiRequest({
          method: "POST",
          url: `/admin/template/${eid}`,
          data: {
            template: form,
            automations,
            theme,
            name,
            secureHash,
          }
        })
        return this.setState({
          saving: false,
          changed: false
        })
      }
      const create = await apiRequest({
        method: "POST",
        url: "/admin/template",
        data: {
          template: form,
          automations,
          theme,
          name,
          secureHash,
        }
      })

      this.setState({
        eid: create.eid,
        saving: false,
        changed: false
      })
    }catch(ex){
      console.log(ex)
    }
  }

  async create(){
    const { createData, createRecipient, eid } = this.state
    this.setState({
      creating: true
    })
    if(!eid){
      console.log("NO EID FOUND!!!!")
      return;
    }
    try{
      const create = await apiRequest({
        method: "POST",
        url: "/admin/form",
        data: {
          recipient: createRecipient || this.props.user.username,
          templateId: eid,
          data: createData,
        }
      })

      this.setState({
        created: create,
        creating: false,
      })
    }catch(ex){
      console.log(ex)
    }
  }

  openCreate(){
    this.setState({
      creating: false,
      created: null,
      createModalVisible: true
    })
  }

  remove(id){
    this.setState((state) => {
      return {
        form: {...state.form, fields: state.form.fields.filter((row) => row.id !== id)},
        changed: true
      }
    }, () => {
      this.setState(state => {
        return {
          focus: (state.form.fields.length > 0)? id : null,
        }
      })
    })
  }

  setFieldsAdvancedSettings(){
    this.setState((state) => {
      return {
        advancedFieldSettings: true,
        activeField: state.form.fields.find(row => row.id === state.focus)
      }
    })
  }

  createAutomations(){
    const { automations } = this.state
    if(!automations){
      return;
    }
    const automationList = automations.map((row) => {
      return (
        <div style={{marginBottom: "5px"}}>
          <Card>
            <Card.Header><strong>{row.type}</strong> on <strong>{row.trigger}</strong></Card.Header>
            <Card.Body>
              {(row.type === "webhook")?
                (
                  <div>
                    <Field
                      label={translate("WEBHOOK_METHOD")}
                      name="method"
                      value={row.method}
                      disabled={true}
                    />
                    <Field
                      label={translate("WEBHOOK_URL")}
                      name="url"
                      value={row.url}
                      disabled={true}
                    />
                  </div>
                )
                :
                (row.type === "camunda-start")?
                (
                  <div>
                    <Field
                      label={translate("CAMUNDA_BASEURL")}
                      name="baseUrl"
                      value={row.baseUrl}
                      disabled={true}
                    />
                    <Field
                      label={translate("CAMUNDA_PROCESSID")}
                      name="processId"
                      value={row.processId}
                      disabled={true}
                    />
                  </div>
                )
                :
                (row.type === "secto_update_erp")?
                (
                  <div>
                    <Field
                      label="Update Container"
                      name="container"
                      value={row.container}
                      disabled={true}
                    />
                  </div>
                )
                :
                (
                  <div>
                    <Field
                      label={translate("EMAIL_TO_ADDRESS")}
                      name="address"
                      value={row.to}
                      disabled={true}
                    />
                    <Field
                      label={translate("EMAIL_HEADER")}
                      name="header"
                      value={row.header}
                      disabled={true}
                    />
                  </div>
                )
              }
              <Button variant="primary" type="submit" onClick={() => this.editAutomation(row.key)}>
                {translate("BUTTON_EDIT")}
              </Button>
              <Button variant="danger" type="submit" onClick={() => this.removeAutomation(row.key)}>
                {translate("BUTTON_REMOVE")}
              </Button>
            </Card.Body>
          </Card>
        </div>
      )
    })

    return automationList
  }

  editAutomation(key){
    this.setState((state) => {
      return {
        focusedAutomation: state.automations.find((row) => row.key === key),
        automationVisible: true
      }
    })
  }

  removeAutomation(key){
    this.setState((state) => {
      return {
        automations: state.automations.filter((row) => row.key !== key),
        changed: true
      }
    })
  }

  handleAutomationSubmit(){
    this.setState((state) => {
      const automation = (state.focusedAutomation.type === "email" || state.focusedAutomation.type === "templated-email")? {...state.focusedAutomation, body: { raw: (state.emailEditorContentRaw)? state.emailEditorContentRaw : (state.focusedAutomation.body)? state.focusedAutomation.body.raw : null, html: (state.emailEditorContentHtml && state.emailEditorContentHtml !== "")? state.emailEditorContentHtml : (state.focusedAutomation.body)? state.focusedAutomation.body.html : "" }} : state.focusedAutomation
      console.log({automation})
      if(state.focusedAutomation.key){
        return {
          automations: state.automations.map((row) => {
            if(row.key === state.focusedAutomation.key){
              return automation
            }
            return row
          }),
          automationVisible: false,
          changed: true
        }
      }
      return {
        automations: [...state.automations, { ...automation, key: v4() }],
        automationVisible: false,
        changed: true
      }
    })
  }

  addAutomation(){
    this.setState({
      focusedAutomation: null,
      automationVisible: true
    })
  }

  handleNewAutomationClose(){
    this.setState({
      automationVisible: false
    })
  }

  handleModalOnChange(evnt){
    let { value, name } = evnt.target
    if(evnt.target.type === "checkbox"){
      value = evnt.target.checked
    }
    console.log({value, name})
    this.setState((state) => {
      return {
        focusedAutomation: {...state.focusedAutomation, [name]: value}
      }
    })
  }

  handleModalHeaderChange(pairEvent){
    this.setState((state) => {
      let headers = state.focusedAutomation.headers
      headers[pairEvent.index] = {...headers[pairEvent.index], [pairEvent.name]: pairEvent.value}
      return {
        focusedAutomation: {...state.focusedAutomation, headers }
      }
    })
  }

  handleModalMappingChange(pairEvent){
    this.setState((state) => {
      let map = state.focusedAutomation.map || {}
      map[pairEvent.name] = (pairEvent.value !== translate("OPTION_EMPTY"))? pairEvent.value : undefined
      return {
        focusedAutomation: {...state.focusedAutomation, map }
      }
    })
  }

  handleModalMappingUniqueChange(pairEvent){
    this.setState((state) => {
      let unique = state.focusedAutomation.unique || {}
      unique[pairEvent.name] = (pairEvent.value !== translate("OPTION_EMPTY"))? pairEvent.value : undefined
      return {
        focusedAutomation: {...state.focusedAutomation, unique }
      }
    })
  }

  handleModalAddHeader(){
    this.setState((state) => {
      return {
        focusedAutomation: {...state.focusedAutomation, headers: (state.focusedAutomation.headers)? [...state.focusedAutomation.headers, {header: "", value: ""}] : [{header: "", value: ""}]}
      }
    })
  }

  emailEditorOnChange(raw, html){
    this.setState({
      emailEditorContentHtml: html,
      emailEditorContentRaw: raw
    })
    console.log(JSON.stringify(raw))
  }

  getAutomationModal(){
    const { focusedAutomation } = this.state
    return (
      <Modal style={{color:"black"}} size="lg" show={this.state.automationVisible} onHide={() => this.handleNewAutomationClose()}>
        <Modal.Header closeButton>
          <Modal.Title style={{color:"black"}}>{!focusedAutomation || !focusedAutomation.key ? translate("NEW_AUTOMATION_TITLE_PREFIX") : ""}{(focusedAutomation)? <span><strong>{focusedAutomation.type}</strong> on <strong>{focusedAutomation.trigger}</strong></span> : translate("NEW_AUTOMATION_TITLE")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="modalAutomationContentContainer">
            <Field
              label={translate("FIELD_TYPE_LABEL")}
              name="type"
              value={(focusedAutomation)? focusedAutomation.type : ""}
              type="select"
              options={this.state.automationTypes}
              onChange={(evnt) => {this.handleModalOnChange(evnt)}}
            />
            <Field
              label={translate("FIELD_TRIGGER_LABEL")}
              name="trigger"
              value={(focusedAutomation)? AutomationTriggerName(focusedAutomation.trigger) : ""}
              type="select"
              options={AutomationTriggers}
              onChange={(evnt) => {this.handleModalOnChange({...evnt, target: {...evnt.target, name: evnt.target.name, value: AutomationTriggerValue(evnt.target.value)}})}}
            />
            {(focusedAutomation && focusedAutomation.type === "webhook")?
            (
              <div className="webhookSettings">
                <Field
                  label={translate("FIELD_URL_LABEL")}
                  name="url"
                  value={(focusedAutomation)? focusedAutomation.url : ""}
                  onChange={(evnt) => {this.handleModalOnChange(evnt)}}
                />
                <Field
                  label={translate("FIELD_METHOD_LABEL")}
                  name="method"
                  value={(focusedAutomation)? focusedAutomation.method || null : null}
                  type="select"
                  options={[
                    "POST",
                    "PATCH",
                    "GET",
                    "PUT",
                    "DELETE",
                  ]}
                  onChange={(evnt) => {this.handleModalOnChange(evnt)}}
                />
                <h5>HTTP Headers</h5>
                <div className="textPairContainer">
                  <div className="headerNameLabel">
                    Header
                  </div>
                  <div className="pairSpacer" />
                    Value
                </div>
                {(focusedAutomation.headers)?
                    focusedAutomation.headers.map((row, index) => {
                      return (
                        <Form.Group key={this.props.name} controlId={`header_control_${index}`}>
                          <div className="textPairContainer">
                            <div className="headerName">
                              <Form.Control name={`header_header_${index}`} defaultValue={row.header} onChange={(evnt) => this.handleModalHeaderChange({ index, name: "header", value: evnt.target.value })} />
                            </div>
                            <div className="pairSpacer" />
                            <Form.Control name={`header_value_${index}`} defaultValue={row.value} onChange={(evnt) => this.handleModalHeaderChange({ index, name: "value", value: evnt.target.value })} />
                          </div>
                        </Form.Group>
                      )
                    })
                  :
                    ""
                }
                <Button variant="primary" onClick={() => this.handleModalAddHeader()}>{translate("BUTTON_ADD")}</Button>
              </div>
            )
            :
            (focusedAutomation && focusedAutomation.type === "email")?
            (
              <div className="emailSettings">
                <Field
                  label={translate("FIELD_TO_LABEL")}
                  name="to"
                  value={(focusedAutomation)? focusedAutomation.to : ""}
                  onChange={(evnt) => {this.handleModalOnChange(evnt)}}
                />
                <Field
                  label={translate("FIELD_HEADER_LABEL")}
                  name="header"
                  value={(focusedAutomation)? focusedAutomation.header : ""}
                  onChange={(evnt) => {this.handleModalOnChange(evnt)}}
                />
              <EmailEditor
                value={(focusedAutomation && focusedAutomation.body)? focusedAutomation.body.raw || null : null}
                onChange={(raw, html) => this.emailEditorOnChange(raw, html)}
                customFields={[
                  "recipient",
                  "url",
                  "approve_url",
                  ...this.state.form.fields.map((row) => row.key)
                ]}
              />
              </div>
            )
            :
            (focusedAutomation && focusedAutomation.type === "templated-email")?
            (
              <div className="emailSettings">
                <Field
                  label={translate("FIELD_TO_LABEL")}
                  name="to"
                  value={(focusedAutomation)? focusedAutomation.to : ""}
                  onChange={(evnt) => {this.handleModalOnChange(evnt)}}
                />
                <Field
                  label={translate("FIELD_HEADER_LABEL")}
                  name="header"
                  value={(focusedAutomation)? focusedAutomation.header : ""}
                  onChange={(evnt) => {this.handleModalOnChange(evnt)}}
                />
                <Field
                  label={translate("FIELD_PREHEADER_LABEL")}
                  name="preheader"
                  value={(focusedAutomation)? focusedAutomation.preheader : ""}
                  onChange={(evnt) => {this.handleModalOnChange(evnt)}}
                />
              <EmailEditor
                value={(focusedAutomation && focusedAutomation.body)? focusedAutomation.body.raw || null : null}
                onChange={(raw, html) => this.emailEditorOnChange(raw, html)}
                customFields={[
                  "recipient",
                  "url",
                  "approve_url",
                  ...this.state.form.fields.map((row) => row.key)
                ]}
              />
              </div>
            )
            :
            (focusedAutomation && focusedAutomation.type === "camunda-start")?
            (
              <div className="camundaStartSettings">
                <Field
                  label={translate("CAMUNDA_BASEURL")}
                  name="baseUrl"
                  value={(focusedAutomation)? focusedAutomation.baseUrl : ""}
                  onChange={(evnt) => {this.handleModalOnChange(evnt)}}
                />
                <Field
                  label={translate("CAMUNDA_PROCESSID")}
                  name="processId"
                  value={(focusedAutomation)? focusedAutomation.processId : ""}
                  onChange={(evnt) => {this.handleModalOnChange(evnt)}}
                />
                <Field
                  label={translate("CAMUNDA_USERNAME")}
                  name="username"
                  value={(focusedAutomation)? focusedAutomation.username : ""}
                  onChange={(evnt) => {this.handleModalOnChange(evnt)}}
                />
                <Field
                  label={translate("CAMUNDA_PASSWORD")}
                  name="password"
                  type="password"
                  value={(focusedAutomation)? focusedAutomation.password : ""}
                  onChange={(evnt) => {this.handleModalOnChange(evnt)}}
                />
              </div>
            )
            :
            (focusedAutomation && focusedAutomation.type === "secto_update_erp")?
              secto_update_erp({
                focusedAutomation,
                state: this.state,
                props: this.props,
                handleModalOnChange: (evnt) => this.handleModalOnChange(evnt),
                handleModalMappingChange: (props) => this.handleModalMappingChange(props),
                handleModalMappingUniqueChange: (props) => this.handleModalMappingUniqueChange(props),
              })
            :
            ""
            }
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.handleNewAutomationClose()}>{translate("BUTTON_CANCEL")}</Button>
          <Button variant="primary" onClick={() => this.handleAutomationSubmit()}>{(focusedAutomation && focusedAutomation.key !== undefined)? translate("BUTTON_UPDATE") : translate("BUTTON_ADD")}</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  addColumn(key){
    const form = this.state.form
    const field = form.fields.find((row) => row.key === key)
    const newColumn = {
      name: (field.columns && field.columns.length > 0)? `Column ${field.columns.length + 1}` : "Column 1",
      key: (field.columns && field.columns.length > 0)? `prop_${field.columns.length + 1}` : "prop_1",
      width: 220
    }
    if(field.columns && field.columns.length > 0){
      field.columns = [...field.columns, newColumn]
    }else{
      field.columns = [newColumn]
    }
    this.setState({
      form,
      changed: true
    })
  }

  removeColumn(key, index){
    const form = this.state.form
    const field = form.fields.find((row) => row.key === key)
    const columns = field.columns.filter((row, i) => i !== index)
    field.columns = []
    this.setState((state) => {
      return {
        form,
        changed: true
      }
    }, () => {
      field.columns = columns
      this.setState({
        form,
        changed: true
      })
    })
  }

  setColumnValue(key, index, value){
    const form = this.state.form
    const field = form.fields.find((row) => row.key === key)
    field.columns[index] = value
    this.setState({
      form,
      changed: true
    })
  }

  addOption(key){
    const form = this.state.form
    const field = form.fields.find((row) => row.key === key)
    if(field.options && field.options.length > 0){
      field.options = [...field.options, `Option ${field.options.length + 1}`]
    }else{
      field.options = ["Option 1"]
    }
    this.setState({
      form,
      changed: true
    })
  }

  removeOption(key, index){
    const form = this.state.form
    const field = form.fields.find((row) => row.key === key)
    const options = field.options.filter((row, i) => i !== index)
    field.options = []
    this.setState((state) => {
      return {
        form,
        changed: true
      }
    }, () => {
      field.options = options
      this.setState({
        form,
        changed: true
      })
    })
  }

  setOptionValue(key, index, value){
    const form = this.state.form
    const field = form.fields.find((row) => row.key === key)
    field.options[index] = value
    this.setState({
      form,
      changed: true
    })
  }

  mouseEntered(key, last){
    const { dragging, focus, form } = this.state

    console.log("Fired mouse enter")

    if(!dragging){
      return;
    }

    const draggingIndex = form.fields.findIndex(row => row.id === focus)
    const hoverIndex = form.fields.findIndex(row => row.id === key)

    if(last){
      return this.setState({
        form: {
          ...form,
          fields: [
            ...form.fields.filter(row => row.id !== focus),
            form.fields.find(row => row.id === focus)
          ]
        },
        changed: true
      })
    }

    if(draggingIndex == (hoverIndex - 1)){
      return;
    }

    const insertIndex = (draggingIndex < hoverIndex)? hoverIndex - 1 : hoverIndex;

    //Set current focused item above hovered item
    const focusedItem = form.fields.find(row => row.id === focus);

    let fields = form.fields.filter(row => row.id !== focus)

    fields.splice(insertIndex, 0, {...focusedItem});

    this.setState({
      form: {
        ...form,
        fields
      },
      changed: true
    })
  }

  createForm(){
    const { form, focus, data, submit, disabled, validationError, submitted, approving, dragging } = this.state
    return form.fields.map((row) => {
      if(row.id === focus){
        return (
          ActiveField({
            ...row,
            state: this.state,
            onChange: (key, field, value) => this.onChange(key, field, value),
            remove: (key) => this.remove(key),
            setOptionValue: (key, index, value) => this.setOptionValue(key, index, value),
            addOption: (key) => this.addOption(key),
            removeOption: (key, index) => this.removeOption(key, index),
            setColumnValue: (key, index, value) => this.setColumnValue(key, index, value),
            addColumn: (key) => this.addColumn(key),
            removeColumn: (key, index) => this.removeColumn(key, index),
            setDragging: (state) => this.setState({dragging: state}),
            openAdvancedOptions: () => this.setState({advancedFieldSettings: true}),
            dragging,
          })
        );
      }
      if(row.type === "line"){
        return (
          <div className="line" />
        )
      }

      let tooltip = ""

      if(row.tooltip){
        let popover = (
          <Popover >
            <Popover.Content>
              {row.tooltip.text}
            </Popover.Content>
          </Popover>
        )

        tooltip = (
          <OverlayTrigger placement={row.tooltip.placement || "right"} overlay={popover}>
            <Icon fontSize="small" color="primary">{row.tooltip.icon}</Icon>
          </OverlayTrigger>
        )
      }

      let link = ""

      if(row.link){
        link = (
          <div className="labelLink" title={row.link.title}>
            <a target="_blank" rel="noopener noreferrer" href={row.link.url} title={row.link.title}>
              <Icon fontSize="small" color="primary">{row.link.icon}</Icon>
            </a>
          </div>
        )
      }

      if(row.type === "label"){
        return (
          <div className="editableField" onMouseEnter={(evnt) => this.mouseEntered(row.id)} onMouseUp={(evnt) => {this.setState({dragging: false})}} onClick={() => {
              this.setState({focus: row.id})
            }}>
            <div className="label">
              <h5>
                {row.label}
                <div className="labelTooltip">
                  {tooltip}
                </div>
                {link}
              </h5>
            </div>
          </div>
        )
      }

      if(row.type === "paragraph"){
        return (
          <div className="editableField" onMouseEnter={(evnt) => this.mouseEntered(row.id)} onMouseUp={(evnt) => {this.setState({dragging: false})}} onClick={() => {
              this.setState({focus: row.id})
            }}>
            <div className="formParagraph">
              {row.label}
            </div>
          </div>
        )
      }

      if(row.type === "attachment"){
        return (
          <div className="editableField" onMouseEnter={(evnt) => this.mouseEntered(row.id)} onMouseUp={(evnt) => {this.setState({dragging: false})}} onClick={() => {
              this.setState({focus: row.id})
            }}>
            <div className="fieldList">
              <div className="label">
                {row.label} {tooltip}
              </div>
              <div className="editableAttachment">
                <Attachment approving={approving} disabled={true} value={submit[row.key]} onChange={this.onChange} name={row.key} />
              </div>
            </div>
          </div>
        )
      }

      if(row.type === "list"){
        const items = data[row.key].map((subValue) => {
          let value = subValue.value || ""
          if(submit[row.key] !== undefined){
            const find = submit[row.key].find((elm) => elm.name === subValue.name)
            if(find !== undefined && find.value !== undefined){
              value = find.value
            }
          }

          return(
            <Field
              sub={true}
              name={subValue.name}
              suffix={row.suffix}
              options={row.options}
              label={subValue.name}
              title={row.title}
              value={value}
              disabled={(submitted && submitted === true)? true : disabled || row.disabled}
              onChange={(evnt) => this.onSubChange(row.key, evnt)}
              transformation={row.transformation}
              type={row.subtype || "text"}
              isInvalid={row.isInvalid || null}
              validationError={validationError}
              choiceValue={(row.type === "date-confirm")? submit[row.key + "_choice"] || data[row.key + "_choice"] || "None" : ""}
            />
          )
        })
        return (
          <div onMouseEnter={(evnt) => this.mouseEntered(row.id)} onMouseUp={(evnt) => {this.setState({dragging: false})}} className={(row.isInvalid && row.isInvalid === validationError)? "fieldList invalid" : "fieldList"}>
            <div className="label">
              <h5>{row.label}<div className="labelTooltip">{tooltip}</div></h5>
            </div>
            {items}
          </div>
        )
      }

      return (
        <div className="editableField" onClick={() => {
            this.setState({focus: row.id})
          }}
          onMouseEnter={(evnt) => this.mouseEntered(row.id)}
          onMouseUp={(evnt) => {this.setState({dragging: false})}}
        >
          <Field
            name={row.key}
            suffix={row.suffix}
            options={[translate("DROPDOWN")]}
            label={row.label}
            tooltip={tooltip}
            link={link}
            labelClass={row.labelClass}
            value={(row.type)? FieldTypeName(row.type) : FieldTypeName(`text`)}
            disabled={true}
            onChange={() => {}}
            transformation={row.transformation}
            title={row.title}
            type={row.type || "text"}
            rows={row.rows}
            isInvalid={row.isInvalid || null}
            validationError={validationError}
            choiceValue={(row.type === "date-confirm")? submit[row.key + "_choice"] || data[row.key + "_choice"] || "None" : ""}
            variant={row.variant}
            onClick={row.onClick}
          />
        </div>
      )
    })
  }

  getActionButtons(){
    return(
      <div className="ActionButtonsFloat">
        <Button variant="primary" type="submit" onClick={() => this.setState((state) => {return {preview: !state.preview}})}>
          {(this.state.preview)? translate("BUTTON_PREVIEW_BACK") : translate("BUTTON_PREVIEW")}
        </Button>
        {(this.props.organisation && this.props.organisation.active)?
          (this.state.preview)?
          <div className="saveButtonContainer">
            <Button variant="success" type="submit" onClick={() => this.openCreate()}>
              <div className="saveButtonContainer">
                {(this.state.saving)?
                  <div className="saveSpinner">
                    <Spinner animation="border" size="sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                  :
                  ""
                }
                {translate("BUTTON_CREATE")}
              </div>
            </Button>
          </div>
          :
          <div className="saveButtonContainer">
            <Button variant="success" type="submit" onClick={() => this.save()} disabled={this.state.saving || !this.state.changed}>
              <div className="saveButtonContainer">
                {(this.state.saving)?
                  <div className="saveSpinner">
                    <Spinner animation="border" size="sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                  :
                  ""
                }
                {translate("BUTTON_SAVE")}
              </div>
            </Button>
          </div>
          :
          <div>
            <Button variant="success" type="submit" disabled title={translate("ACCOUNT_NOT_ACTIVATED")} onClick={() => this.save()}>
              {translate("BUTTON_SAVE")}
            </Button>
          </div>
        }
        {(this.state.preview)?
          ""
          :
          <div className="settingsButtonContainer">
            <div className="settingsButton" title={translate("FORM_SETTINGS_BUTTON")} onClick={() => this.setState({editInfo: true})}>
              <Icon style={{fontSize: "1.8rem"}}>settings</Icon>
            </div>
          </div>
        }
      </div>
    )
  }

  render() {
    const { error, doc, loading, theme, submitted, data, validationError, message, submitting, approving, approved, env, form, multipart, stage } = this.state
    if(loading){
      return(
        <div className="loading center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    }
    if(error !== null){
      return(
        <div className="loading center">
          <div className="errorMessage">
            {error.message}
          </div>
          {(error.requestId !== null)?
            <div className="errorCode">
              Virhekoodi: '{error.requestId}'
            </div>
            :
            ""
          }
        </div>
      )
    }

    if(this.state.preview){
      return(
        <div className="contentContainer" style={theme.container || {}} onMouseUp={(evnt) => {this.setState({dragging: false})}}>
          {CreateModal({
            ...this.props,
            state: this.state,
            setState: (state) => this.setState(state),
            visible: this.state.createModalVisible,
            onHide: () => this.setState({createModalVisible: false}),
            onCreate: () => this.create(),
            created: this.state.created,
            creating: this.state.creating,
          })}
          <div className="previewContainer">
            <FormPreview form={{
                form: this.state.form,
                theme: this.state.theme,
                data: this.state.data,
                submit: this.state.submit,
              }}
              isPreview={true}
              approved={true}
            />
          </div>
          { this.getActionButtons() }
          <div className="formSpacer" />
        </div>
      )
    }
    return (
      <div className="contentContainer" style={(this.state.dragging)? {userSelect: "none !important"} : {}} onMouseUp={(evnt) => {this.setState({dragging: false})}} >
        {InfoModal({
          ...this.props,
          state: this.state,
          setState: (state) => this.setState(state),
          visible: this.state.editInfo,
          onHide: () => this.setState({editInfo: false}),
          themeChanged: (theme) => this.setState({theme, changed: true}),
          setTemplateProp: (prop, value) => this.setTemplateProp(prop, value),
        })}
        {CreateModal({
          ...this.props,
          state: this.state,
          setState: (state) => this.setState(state),
          visible: this.state.createModalVisible,
          onHide: () => this.setState({createModalVisible: false}),
          onCreate: () => this.create(),
          created: this.state.created,
          creating: this.state.creating,
        })}
        {AdvancedSettingsModal({
          ...this.props,
          state: this.state,
          setState: (state) => this.setState(state),
          visible: this.state.advancedFieldSettings,
          onChange: (key, field, value) => this.onChange(key, field, value),
          onHide: () => this.setState({advancedFieldSettings: false}),
        })}
        {this.getAutomationModal()}
        <div onMouseUp={(evnt) => {this.setState({dragging: false})}} className="formContainerAdmin editContainer containerBox" style={(multipart)? form.abnormalStyle || multipart.abnormalStyle || {} : form.abnormalStyle || {}}>
          <div className="headerAdmin">
            <div className="headerLogoMutko">
              <img className="mutkoadminlogo" src={Logo} alt="Mustakotelo" />
            </div>
          </div>
          {(message !== null)?
            <h5 className="message boldedLabel">{message}</h5>
            :
            ""
          }

          {(validationError !== null)?
            (<div className="validationError">
              {validationError}
            </div>)
            :
            ""
          }

          <h3>{translate("HEADER_FIELDS")}</h3>
          {this.createForm()}
          <div className="AddButton" onMouseUp={(evnt) => {this.setState({dragging: false})}} onMouseEnter={(evnt) => this.mouseEntered(null, true)} >
            <Button variant="primary" type="submit" onClick={() => this.add()}>
              {translate("BUTTON_ADD")}
            </Button>
          </div>
          <div style={{marginTop: "3px"}} onMouseUp={(evnt) => {this.setState({dragging: false})}} />
          <h3>{translate("HEADER_AUTOMATIONS")}</h3>
          {this.createAutomations()}
          <div className="AddButton">
            <Button variant="primary" type="submit" onClick={() => this.addAutomation()}>
              {translate("BUTTON_ADD_AUTOMATION")}
            </Button>
          </div>
          { this.getActionButtons() }
        </div>
        <div className="formSpacer" />
      </div>
    );
  }
}

export default Create

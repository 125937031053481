import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap'
import Icon from '@material-ui/core/Icon';

import Field from '../../../components/field'
import translate, { FieldTypes, FieldTypeValue, FieldTypeName, AutomationTriggers, AutomationTriggerName, AutomationTriggerValue }  from '../../../utils/translate'

function getToolTip(text) {
  let popover = (
    <Popover >
      <Popover.Content>
        {text}
      </Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger placement={"right"} overlay={popover}>
      <Icon fontSize="small" color="primary">info</Icon>
    </OverlayTrigger>
  )
}

/*
<Field
  label={translate("FIELD_FORM_ENCRYPTION_AT_REST")}
  name="sms2fa"
  onChange={() => {}}
  type="switch"
  tooltip={getToolTip(translate("FIELD_FORM_ENCRYPTION_AT_REST_INFO"))}
  disabled={true}
  value={true}
  title="AES256"
/>
*/

function NotFound(props) {
  return (
    <div className="securityData">
      <h3>{translate("HEADER_SECURITY")}</h3>
      <Field
        label={translate("FIELD_FORM_PUBLIC")}
        name="publicEnabled"
        type="switch"
        tooltip={getToolTip(translate("FIELD_FORM_PUBLIC_INFO"))}
        onChange={(evnt) => {props.setTemplateProp("publicEnabled", evnt.target.checked)}}
        value={props.state.form.publicEnabled}
      />
      {(props.state.form.publicEnabled &&
        <Field
          name="publicURL"
          type="copy"
          value={props.state.publicUrl || translate("SAVE_TO_SHOW")}
        />
      )}
      <Field
        label={translate("FIELD_FORM_KONTTORI")}
        name="konttoriEnabled"
        type="switch"
        tooltip={getToolTip(translate("FIELD_FORM_KONTTORI_INFO"))}
        onChange={(evnt) => {props.setTemplateProp("konttoriEnabled", evnt.target.checked)}}
        value={props.state.form.konttoriEnabled}
        disabled={(!props.state.form.publicEnabled)}
      />
      <Field
        label={translate("FIELD_FORM_SMS2FA")}
        name="sms2fa"
        onChange={() => {}}
        type="switch"
        tooltip={getToolTip(translate("FEATURE_COMING_SOON"))}
        disabled={true}
        title="Ominaisuus tulossa"
      />
      <div className="editableFieldRow">
        <div className="editableFieldContainer">
          <div className="editableFieldField">
            <Field
              label={translate("FIELD_FORM_EXPIRY")}
              name="exp_raw"
              type="text"
              tooltip={getToolTip(translate("FIELD_FORM_EXPIRY_INFO"))}
              onChange={(evnt) => {props.setTemplateProp("exp_raw", evnt.target.value)}}
              value={props.state.form.exp_raw}
            />
          </div>
        </div>
        <div className="editableFieldContainer">
          <div className="editableFieldField">
            <Field
              label={translate("FIELD_FORM_TTL")}
              name="ttl_raw"
              type="hasText"
              tooltip={getToolTip(translate("FIELD_FORM_TTL_INFO"))}
              onChange={(evnt) => {props.setTemplateProp("ttl_raw", evnt.target.value)}}
              value={props.state.form.ttl_raw}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;

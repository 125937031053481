import React from 'react';
import { Form } from 'react-bootstrap'

import Field from '../../../../../components/field'
import translate from '../../../../../utils/translate'

//Containers
import Account from './containers/account'
import Contract from './containers/contract'

function getSectoERPMappings(container){
  switch(container){
    case "Account":
      return Account.fields.map((row) => {
        return (<option>{row}</option>)
      })
    case "Contract":
      return Contract.fields.map((row) => {
        return (<option>{row}</option>)
      })
    default:
      return []
  }
}

function getSectoUniqueERPMappings(container){
  switch(container){
    case "Account":
      return Account.unique_fields.map((row) => {
        return (<option>{row}</option>)
      })
    case "Contract":
      return Contract.unique_fields.map((row) => {
        return (<option>{row}</option>)
      })
    default:
      return []
  }
}

function updateErp(props) {
  return (
    <div className="webhookSettings">
      <Field
        label="Container"
        name="container"
        value={(props.focusedAutomation)? props.focusedAutomation.container || "Valitse..." : "Valitse..."}
        type="select"
        options={[
          "Account",
          "Contract"
        ]}
        onChange={(evnt) => {props.handleModalOnChange(evnt)}}
      />
      {(props.focusedAutomation.container)?
        (<div>
        <h5>Unique field</h5>
        <Form.Group key={props.props.name} controlId={`header_control_unique`}>
          <div className="textPairContainer">
            <div className="headerName">
              <Form.Control
                name={`mapping_value_unique`}
                as="select"
                onChange={(evnt) => props.handleModalMappingUniqueChange({ name: "name", value: evnt.target.value })}
                defaultValue={(props.focusedAutomation.unique)? props.focusedAutomation.unique.name : translate("OPTION_EMPTY")}
              >
                <option>{translate("OPTION_EMPTY")}</option>
                {getSectoUniqueERPMappings(props.focusedAutomation.container)}
              </Form.Control>
            </div>
            <div className="pairSpacer" />
            <Form.Control
              name={`mapping_value_unique`}
              as="select"
              onChange={(evnt) => props.handleModalMappingUniqueChange({ name: "value", value: evnt.target.value })}
              defaultValue={(props.focusedAutomation.unique)? props.focusedAutomation.unique.value : translate("OPTION_EMPTY")}
            >
              <option>{translate("OPTION_EMPTY")}</option>
              {props.state.form.fields.map((row) => {return <option>{row.key}</option>})}
            </Form.Control>
          </div>
        </Form.Group>
        <h5>Mappings</h5>
        {props.state.form.fields.map((row) => row.key).map((row, index) => {
        return (
          <Form.Group key={props.props.name} controlId={`header_control_${index}`}>
            <div className="textPairContainer">
              <div className="headerName">
                <Form.Control name={`mapping_header_${index}`} defaultValue={row} disabled/>
              </div>
              <div className="pairSpacer" />
              <Form.Control
                name={`mapping_value_${index}`}
                as="select"
                onChange={(evnt) => props.handleModalMappingChange({ name: row, value: evnt.target.value })}
                defaultValue={(props.focusedAutomation.map)? props.focusedAutomation.map[row] : translate("OPTION_EMPTY")}
              >
                <option>{translate("OPTION_EMPTY")}</option>
                {getSectoERPMappings(props.focusedAutomation.container)}
              </Form.Control>
            </div>
          </Form.Group>
        )
        })}
        </div>)
        :
        ""
      }
    </div>
  );
}

export default updateErp;

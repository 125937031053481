import React from 'react';
import { Spinner } from 'react-bootstrap';

import Header from '../../components/header'
import Sidebar from '../../components/sidebar'

function Loading(props) {
  return (
    <div className="loading center">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}

export default Loading;

import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import logo from '../../resources/Latentti-logo-musta.svg'

import './style.css';

class Header extends Component {

  render() {
    return (
      <div className="adminHeaderContainer">
        <div className="adminHeaderLogo">
          <div className="adminHeaderName">
            MUTKO
          </div>
          <div className="adminHeaderSlogan">
            by LATENTTI
          </div>
        </div>
        <div className="adminHeaderContent">
          <h1>{this.props.title}</h1>
        </div>
        {(this.props.user) &&
          (
            <div className="headerUsernameContainer">
              <div className="headerUsernameContainerIcon"><Icon>account_circle</Icon></div>
              <div className="headerUsernameContainerName">{this.props.user.username}</div>
            </div>
          )
        }
      </div>
    );
  }
}

export default Header;

import React, { Component } from 'react';

import Config from '../../Config.js'

import MsSignin from '../../resources/ms-symbollockup_signin_light.svg'
import LatenttiLogo from '../../resources/Latentti-logo.svg'

import './style.css';

class Login extends Component {
  redirectMS(){
    window.location.href = Config.apiEndpoint + "auth/ms/login?ref=" + window.location.pathname
  }

  render() {
    return (
      <div className="loginContainer">
        <div className="loginContent">
          <div className="loginHeaderLogo">
            <img className="latenttiLoginLogo" src={LatenttiLogo} alt="Latentti" />
          </div>
          <div className="loginOptions">
            <h2>Tunnistaudu palveluun</h2>
            <div className="microsoftLogin" onClick={() => { this.redirectMS() }}>
              <img className="microsoftLoginLogo" src={MsSignin} alt="Login with Microsoft" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;

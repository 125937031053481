import React, { Component } from 'react';
import { v4 } from 'uuid';
import { Spinner, Button, Popover, OverlayTrigger, ButtonGroup } from 'react-bootstrap';
import tinycolor from 'tinycolor2';
import Icon from '@material-ui/core/Icon';
import { DataGrid } from '@material-ui/data-grid';
import Logo from '../../resources/Latentti-logo-valkoinen.svg';
import LogoBlack from '../../resources/Latentti-logo-musta.svg';

import Submitting from './components/submitting';
import Field from '../../components/field';
import Attachment from '../../components/attachment';
import { apiRequest } from '../../utils/api';
import { createWelcomeMessage, formatDate, formatDateToMonth } from './helper';
import translate, { setLanguage } from '../../utils/translate';

import './style.css';

//Organisation containers
import './organisation/secto/style.css';
import './organisation/rolly/style.css';

import Example from './example.json';

const ANIMATION_STALL_DELAY = 3000;

const FETCH_DELAY = 1500;

class Form extends Component {
  constructor(){
    super();

    this.state = {
      data: {},
      submit: {},
      feedback: {},
      invalid: false,
      loading: true,
      error: null,
      submitting: false,
      message: null,
      validationError: null,
      approving: false,
      form: null,
      theme: null,
    }

    this.onChange = this.onChange.bind(this)
    this.onSubChange = this.onSubChange.bind(this)
    this.submit = this.submit.bind(this)
    this.timeouts = {};
  }

  async componentDidMount(){
    /*
      setTimeout(() => {
        this.setState({
          submitting: false,
          submitted: true,
        })
      }, 3000)
    */

    if(this.props.form){
      return this.setState({
        form: this.props.form.form,
        theme: this.props.form.theme,
        data: this.props.form.data,
        submit: this.props.form.submit || this.props.form.data,
        loading: false,
        inspect: this.props.inspect,
        submitted: this.props.submitted || this.props.inspect,
        message: this.props.welcome || null
      })
    }
    try {
      const eid = this.props.args[1]
      const hash = this.props.args[2]
      const fetch = await apiRequest({
        method: "GET",
        url: `/form/${eid}/${hash}`
      })
      console.log({lang: fetch.template.lang})
      if(fetch.template.lang && fetch.template.lang === "en"){
        setLanguage("en")
      }
      let defaultData = {}
      fetch.template.fields.forEach((field) => {
        try{
          if(field.type === "json" && field.content){
              defaultData[field.key] = JSON.parse(field.content)
          }else if(field.content){
            defaultData[field.key] = JSON.parse(field.content)
          }
        }catch(ex){
          console.log(ex)
        }
      })
      const data = {...defaultData, ...fetch.data}
      this.setState({
        form: fetch.template,
        message: (fetch.template.welcome)? createWelcomeMessage(fetch.template.welcome, fetch.data) : null,
        theme: fetch.theme,
        data,
        submit: fetch.submit || data,
        loading: false,
        eid
      })
    }catch(ex){
      console.log(ex)
      this.setState({
        error: ex,
        loading: false
      })
    }
  }

  fieldFetch(field, value){
    const eid = this.props.args[1]
    const hash = this.props.args[2]
    this.onChange({
      target: {
        name: field.fetchDataInsert,
        value: "Haetaan..."
      }
    })
    if(this.timeouts && this.timeouts[field.key]){
      clearTimeout(this.timeouts[field.key])
    }
    this.timeouts[field.key] = setTimeout(
      async () => {
        try{
          const fetch = await apiRequest({
            method: "POST",
            url: `/form/fetch/${eid}/${hash}/${field.id}`,
            data: {
              ...this.state.submit,
              [field.key]: value
            }
          })
          this.onChange({
            target: {
              name: field.fetchDataInsert,
              value: fetch
            }
          })
        }catch(ex){
          if(ex.status === 404){
            this.onChange({
              target: {
                name: field.fetchDataInsert,
                value: "Ei löydetty"
              }
            })
          }else{
            console.log(ex)
            this.onChange({
              target: {
                name: field.fetchDataInsert,
                value: `${ex.status}: ${ex.message}`
              }
            })
          }
        }
      },
      FETCH_DELAY
    )

  }

  invalidFields(){
    const { feedback } = this.state
    let invalid = false

    for(const field in feedback){
      if(feedback[field]){
        invalid = true
      }
    }

    return invalid
  }

  validateField(field, value){
    let feedback = null;

    if(field.required){
      if((field.type !== "switch" && !value) || (typeof value === "string" && value.trim() === "")){
        feedback = {
          valid: false,
          type: "invalid",
          title: translate("FIELD_IS_REQUIRED_TITLE"),
          message: translate("FIELD_IS_REQUIRED")
        }
      }
      if(field.type === "select" && value === "Valitse..."){
        feedback = {
          valid: false,
          type: "invalid",
          title: translate("FIELD_IS_REQUIRED_TITLE"),
          message: translate("FIELD_IS_REQUIRED")
        }
      }
    }

    if(field.type === "number" || field.subType === "number"){
      if(!(new RegExp('^[0-9]*$').test(value))){
        feedback = {
          valid: false,
          type: "invalid",
          title: translate("VALIDATION_FAILED_INVALID_NUMBER_TITLE"),
          message: translate("VALIDATION_FAILED_INVALID_NUMBER")
        }
      }
    }

    //Check if field has a fetch attached
    if(field.fetchData){
      this.fieldFetch(field, value)
    }

    return feedback
  }

  validateFields(){
    const { form, submit, data } = this.state
    const feedback = {}
    let invalid = false

    form.fields.forEach(field => {
      const fieldFeedback = this.validateField(field, submit[field.key] || data[field.key])

      if(field.type === "switch" && (submit[field.key] === undefined || submit[field.key] === null)){
        return;
      }

      feedback[field.key] = fieldFeedback

      if(fieldFeedback){
        console.log("Field with invalid content", field.key)
        invalid = true
      }
    })

    this.setState({
      feedback,
      invalid
    })

    return invalid
  }

  async postSubmit(){
    const { form, submit } = this.state

    if(form.postSubmitRedirect){
      console.log("Redirecting client to ", form.postSubmitRedirect)
      window.location.href = form.postSubmitRedirect
    }
  }

  async submit(){
    const { form, submit } = this.state

    if(this.validateFields()){
      return console.log({invalid: true, feedback: this.state.feedback})
    }

    try {
      if(this.props.isPreview){
        return this.setState({
          submitting: true
        })
      }
      this.setState({
        submitting: true
      })
      const eid = this.props.args[1]
      const hash = this.props.args[2]
      const req = await apiRequest({
        method: 'post',
        url: `/form/${eid}/${hash}`,
        data: submit,
        headers: {'Accept': 'application/json'}
      })
      return this.setState({
        message: form.postSubmit,
        validationError: null,
        submitting: false,
        submitted: true,
        loading: false,
      }, () => {
        setTimeout(() => {
          this.setState({
            stopAnimation: true
          })
          //post submit funcions
          this.postSubmit()
        }, ANIMATION_STALL_DELAY)
      })
    }catch(ex){
      console.log(ex)
    }
  }

  onChange(evnt){
    let { value, name } = evnt.target
    if(evnt.target.type === "checkbox"){
      value = evnt.target.checked
    }

    this.setState((state) => {
      const field = state.form.fields.find(row => row.key === name)
      const fieldFeedback = this.validateField(field, value)

      return {
        validationError: null,
        submit: {...state.submit, [name]: value},
        feedback: {...state.feedback, [name]: fieldFeedback}
      }
    })
  }

  onSubChange(parent, evnt){
    let { value, name } = evnt.target
    if(evnt.target.type === "checkbox"){
      value = evnt.target.checked
    }
    this.setState((state) => {
      if(state.submit[parent] !== undefined){
        const obj = state.submit[parent].map(elm => {
          if(elm.name === name){
            elm.value = value
          }
          return elm
        })
        return {
          validationError: null,
          submit: {...state.submit, [parent]: obj}
        }
      }
      return {
        validationError: null,
        submit: {...state.submit, [parent]: [ {name, value} ]}
      }
    })
  }

  async onSignatureChange(name, image){
    const response = await apiRequest({
      method: 'post',
      url: `/form/attachment`,
      headers: {'Accept': 'application/json'},
      data: {content: image, filename: `${this.state.eid}_${name}.png`},
    })
    this.setState((state) => {
      return {
        submit: {...state.submit, [name]: response.key}
      }
    })
  }

  createForm(){
    const { form, data, submit, disabled, validationError, submitted, submitting, approving, feedback } = this.state
    return form.fields.map((row) => {
      if(row.hidden){
        return;
      }

      //Check visibility
      if(row.visibleOnTerm){
        const visibleOnTermField = form.fields.find(f => f.key === row.visibleOnTermField)
        if(visibleOnTermField){
          switch(row.visibleOnTermCondition){
            case "equals":
              if(!submit[visibleOnTermField.key] || submit[visibleOnTermField.key] !== row.visibleOnTermConditionValue){
                return;
              }
              break;
            case "notequals":
              if(submit[visibleOnTermField.key] && submit[visibleOnTermField.key] === row.visibleOnTermConditionValue){
                return;
              }
              break;
            case "notempty":
              if(!submit[visibleOnTermField.key] || (typeof submit[visibleOnTermField.key] === "string" && submit[visibleOnTermField.key].trim() === "")){
                return;
              }
              break;
            case "includes":
              if(!submit[visibleOnTermField.key] || (typeof submit[visibleOnTermField.key] === "string" && !submit[visibleOnTermField.key].toLowerCase().includes(row.visibleOnTermConditionValue.toLowerCase()))){
                return;
              }
              break;
          }
        }
      }

      if(row.type === "line"){
        return (
          <div className="line" />
        )
      }

      let tooltip = ""

      if(row.tooltip){
        let popover = (
          <Popover >
            <Popover.Content>
              {row.tooltip.text}
            </Popover.Content>
          </Popover>
        )

        tooltip = (
          <OverlayTrigger placement={row.tooltip.placement || "right"} overlay={popover}>
            <Icon fontSize="small" color="primary">{row.tooltip.icon}</Icon>
          </OverlayTrigger>
        )
      }

      let link = ""

      if(row.link){
        link = (
          <div className="labelLink" title={row.link.title}>
            <a target="_blank" rel="noopener noreferrer" href={row.link.url} title={row.link.title}>
              <Icon fontSize="small" color="primary">{row.link.icon}</Icon>
            </a>
          </div>
        )
      }

      if(row.type === "label"){
        return (
          <div className="label">
            <h5>
              {row.label}
              <div className="labelTooltip">
                {tooltip}
              </div>
              {link}
            </h5>
          </div>
        )
      }

      if(row.type === "paragraph"){
        return (
          <div className="formParagraph">
            {row.label}
          </div>
        )
      }

      if(row.type === "attachment"){
        return (
          <div className="fieldList">
            <div className="label">
              {row.label} {tooltip}
            </div>
            <Attachment admin={this.props.admin || false} approving={approving} disabled={(submitted || submitting)? true : disabled || row.disabled} value={submit[row.key]} onChange={this.onChange} name={row.key} />
          </div>
        )
      }

      if(row.type === "list"){
        const items = data[row.key].map((subValue) => {
          let value = subValue.value || ""
          if(submit[row.key] !== undefined){
            const find = submit[row.key].find((elm) => elm.name === subValue.name)
            if(find !== undefined && find.value !== undefined){
              value = find.value
            }
          }

          return(
            <Field
              sub={true}
              name={subValue.name}
              suffix={row.suffix}
              options={row.options}
              label={subValue.name}
              title={row.title}
              value={value}
              disabled={ (submitted || submitting)? true : disabled || row.disabled }
              onChange={ (evnt) => this.onSubChange(row.key, evnt) }
              onEnd={ (name, image) => this.onSignatureChange(name, image) }
              transformation={row.transformation}
              type={row.subtype || "text"}
              isInvalid={row.isInvalid || null}
              validationError={validationError}
              choiceValue={(row.type === "date-confirm")? submit[row.key + "_choice"] || data[row.key + "_choice"] || "None" : ""}
            />
          )
        })
        return (
          <div className={(row.isInvalid && row.isInvalid === validationError)? "fieldList invalid" : "fieldList"}>
            <div className="label">
              <h5>{row.label}<div className="labelTooltip">{tooltip}</div></h5>
            </div>
            {items}
          </div>
        )
      }

      if(row.type === "table"){
        const isTableDisabled = (submitted || submitting)? true : disabled || row.disabled;
        return (
          <div>
            <div className="label">
              <h5>
                {row.label}
                <div className="labelTooltip">
                  {tooltip}
                </div>
                {link}
              </h5>
            </div>
            <div style={{ height: 300, width: '100%' }}>
              <DataGrid
                rows={submit[row.key] || data[row.key] || row.content || []}
                columns={row.columns.map((row) => { return { field: row.key, headerName: row.name, width: row.width, editable: (isTableDisabled)? false : true } })}
                pageSize={5}
                disableColumnSelector={true}
                disableSelectionOnClick={true}
                onEditCellChangeCommitted={(evnt) => {
                  const { field, id, props } = evnt
                  const { value } = props
                  const currentValue = submit[row.key] || data[row.key] || row.content || []
                  let newRow = currentValue.find(row => row.id === id)
                  newRow[field] = value
                  this.onChange({
                    target: {
                      name: row.key,
                      value: [...currentValue.filter(row => row.id !== id), newRow]
                    }
                  })
                }}
              />
            </div>
            {((!isTableDisabled) && (
              <Button variant="secondary" type="submit" onClick={() => {
                  const currentValue = submit[row.key] || data[row.key] || row.content || null
                  const newRow = {
                    id: v4()
                  }
                  row.columns.forEach(row => {newRow[row.key] = row.name})
                  this.onChange({
                    target: {
                      name: row.key,
                      value: (currentValue)? [...currentValue, newRow] : [newRow]
                    }
                  })
              }}>
                {translate("BUTTON_ADD_ROW")}
              </Button>
            ))

            }

          </div>
        )
      }

      return (
        <Field
          name={row.key}
          suffix={row.suffix}
          options={row.options}
          label={row.label}
          tooltip={tooltip}
          link={link}
          labelClass={row.labelClass}
          value={submit[row.key] || data[row.key] || row.content || ""}
          disabled={(submitted || submitting)? true : disabled || row.disabled}
          onChange={this.onChange}
          onEnd={ (name, image) => this.onSignatureChange(name, image) }
          transformation={row.transformation}
          title={row.title}
          type={row.type || "text"}
          rows={row.rows}
          isInvalid={row.isInvalid || null}
          validationError={validationError}
          choiceValue={(row.type === "date-confirm")? submit[row.key + "_choice"] || data[row.key + "_choice"] || "None" : ""}
          variant={row.variant}
          onClick={row.onClick}
          feedback={feedback[row.key] || null}
          required={row.required}
          pastIsOutside={row.pastIsOutside}
          futureIsOutside={row.futureIsOutside}
          rangeStart={row.rangeStart}
          rangeEnd={row.rangeEnd}
          weekendIsBlocked={row.weekendIsBlocked}
          infoText={row.infoText || null}
          readOnly={row.readOnly || undefined}
          searchable={row.searchable || undefined}
          searchableOptions={(row.dataVariable)? data[row.dataVariable] : undefined}
          searchableFilter={submit[row.dataFilter] || data[row.dataFilter] || undefined}
          subType={row.subType || undefined}
        />
      )
    })
  }

  render() {
    const { error, doc, inspect, loading, theme, submitted, data, validationError, message, submitting, approving, approved, env, form, multipart, stage } = this.state
    if(loading){
      return(
        <div className="loading center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    }
    if(error !== null){
      console.log(error)
      return(
        <div className="loading center">
          <div className="errorMessage">
            {translate(error.message)}
          </div>
          {(error.requestId !== null)?
            <div className="errorCode">
              Virhekoodi: '{error.trace}'
            </div>
            :
            ""
          }
        </div>
      )
    }

    if((submitting || submitted) && !this.props.admin && !this.state.stopAnimation){
      return (
        <Submitting
          submitting={submitting}
          submitted={submitted}
          form={form}
          theme={theme}
        />
      );

    }

    const footerColor = theme.container?.background ?? "#fff"
    const footerColorParsed = tinycolor(footerColor);
    const footerColorIsLight = footerColorParsed.isLight();

    return (
      <div className={(!this.props.inspect)? "contentContainer" : ""} style={theme.container || {}}>
        <div className="formContainer containerBox" style={theme.formContainer}>
          <div className={(this.props.inspect)? "inspectHeader" : theme.headerClass || (theme.logo)? "headerSimple" : "header"} style={theme.header || {}}>
            {(!theme.noLogo)?
              <div className="headerLogoMutko">
                <img className={theme.logoClass || "latenttilogo"} src={theme.logo || "/Latentti-logo.svg"} alt="Latentti" />
              </div>
              :
              ""
            }
            {(this.props.inspect)?
              <div className="basicInfoHeader minusMargin">
                {translate("INSPECT_FORM")}
              </div>
              :
              ""
            }
          </div>
          <div className="formContainerBody">

            {(message !== null)?
              <h5 className="message boldedLabel">{message}</h5>
              :
              ""
            }

            {(validationError !== null)?
              (<div className="validationError">
                {validationError}
              </div>)
              :
              ""
            }
            {this.createForm()}
            {(submitted && !approved && !inspect)?
              <div className="returnedText">{translate("TEXT_RETURNED_ON")}<span className="date"></span></div>
              :
              ""
            }
            {(approving && approved)?
              <div>
                <div className="approveText">
                  {translate("TEXT_RETURNED_ON")} <span className="date">{formatDateToMonth(new Date(doc.submittedOn))}</span>
                </div>
                <div className="approveLine">
                  <div className="approveText">
                    {translate("TEXT_APPROVED_ON")} <span className="date">{formatDateToMonth(new Date(doc.approvedOn))}</span> /<span className="username">{doc.approvedBy}</span>
                  </div>
                </div>
              </div>
              :
              (submitting || this.props.submitting)?
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                :
                (submitted || (form.submittable !== undefined && form.submittable === false))?
                ""
                :
                (this.props.inspect)?
                ""
                :
                (this.props.isPreview)?
                <Button variant="primary" type="submit" title={translate("NOT_AVAILABLE_INPREVIEW")} onClick={this.submit} disabled>
                  {translate("BUTTON_SEND")}
                </Button>
                :
                (this.invalidFields())?
                <Button variant="primary" type="submit" title={translate("INVALID_VALUES_CHECK_FIELDS")} disabled>
                  {translate("BUTTON_SEND")}
                </Button>
                :
                <Button variant="primary" type="submit" onClick={this.submit}>
                  {translate("BUTTON_SEND")}
                </Button>

            }
            {
              (this.props.isPreview && !this.props.approved && !this.props.submitting)?
                <Button variant="success" type="submit" onClick={this.props.approve}>
                  {translate("BUTTON_APPROVE")}
                </Button>
              :
                ""
            }
          </div>
        </div>
        {(!this.props.isPreview) &&
          <div className={(footerColorIsLight)? "formFooter formFooterLight" : "formFooter"}>
              <span>Powered by</span><a href="https://www.latentti.fi/mutko" target="_blank"><img className="latenttiFooterLogo" src={(footerColorIsLight)? LogoBlack : Logo} alt="Latentti" /></a>
          </div>
        }
        <div className="formSpacer" />
      </div>
    );
  }
}

export default Form

import React from 'react';
import Icon from '@material-ui/core/Icon';
import { Popover, Overlay, OverlayTrigger, Button } from 'react-bootstrap';

import Field from '../../../components/field'

import translate from '../../../utils/translate'
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  AreaSeries,
} from 'react-vis';

function SubmittedChart(props) {
  if(!props.stats){
    return(
      <div className="radiusChartLoading">
        Loading...
      </div>
    )
  }
  return (
      <div className="radiusChart" animation="gentle">
        <XYPlot width={1000} height={300} stackBy="y" xType="ordinal">
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis />
          <YAxis />
          <AreaSeries data={props.stats.submitted} color="#6c757d" />
          <AreaSeries data={props.stats.opened} color="#3c98b6" />
          <AreaSeries data={props.stats.created} color="rgb(121, 199, 227)" />
        </XYPlot>
      </div>
    );
}

export default SubmittedChart;

import React from 'react';
import Icon from '@material-ui/core/Icon';

import Field from '../../../components/field'
import translate from '../../../utils/translate'

function basicInfoBox(header, value){
  return(
    <div className="basicInfoData">
      <div className="basicInfoDataHeader">
        {header}
      </div>
      <div className="basicInfoDataValue">
        {value}
      </div>
    </div>
  )
}

function basicInfoModal(){
  return (
    <div>

    </div>
  )
}

function BasicInfo(props) {

  return (
    <div className="basicInfo">
      <div className="basicInfoHeader">
        {translate("ORGANISATION_BASIC_HEADER")}
        <div className="basicInfoDataHeaderAction">
          <div className="basicInfoDataHeaderActionButton" title={translate("ORGANISATION_BASIC_HEADER_EDIT")}>
            <Icon>edit</Icon>
          </div>
        </div>
      </div>
      <div className="basicInfoValues">
        {basicInfoBox(translate("ORGANISATION_BASIC_NAME"), props.organisation.name)}
        {basicInfoBox(translate("ORGANISATION_BUSINESSID"), props.organisation.businessId)}
        {basicInfoBox(translate("ORGANISATION_CONTACT"), props.organisation.contact)}
        {basicInfoBox(translate("ORGANISATION_CONTACT_EMAIL"), props.organisation.contactEmail)}
        {basicInfoBox(translate("ORGANISATION_CONTACT_ADDRESS"), props.organisation.contactAddress)}
      </div>
    </div>
  );
}

export default BasicInfo;

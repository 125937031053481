import React, { Component } from 'react';

import Config from '../../Config.js'

import BasicInfo from './components/basic'
import ApiKeys from './components/apiKeys'

import { apiRequest } from '../../utils/api'

import './style.css';

class Organisation extends Component {
  constructor(){
    super();
    this.state = {
      apiKeys: null,
      newApiKey: null,
    }
  }

  async componentDidMount(){
    this.fetchApiKeys()
  }

  async fetchApiKeys(){
    const apiKeys = await apiRequest({
      method: "GET",
      url: "admin/apikeys"
    })
    this.setState({
      apiKeys: apiKeys.sort((a, b) => {
        let aWeight = (a.active)? 1 : 0
        let bWeight = (b.active)? 1 : 0
        return bWeight - aWeight
      }),
    })
  }

  async deactivateApiKey(key){
    const apiKey = await apiRequest({
      method: "GET",
      url: "admin/apikey/deactivate/"+key
    })
    this.fetchApiKeys()
  }

  async addApiKey(){
    const apiKey = await apiRequest({
      method: "GET",
      url: (this.state.newApiName)? `admin/create-api-key?name=${this.state.newApiName}` : "admin/create-api-key"
    })
    this.setState({
      newApiKey: apiKey,
    })
    this.fetchApiKeys()
  }

  render() {
    return (
      <div className="organisationContainer">
        <div className="organisationBoxes">
          <BasicInfo organisation={this.props.organisation} />
          <ApiKeys
            organisation={this.props.organisation}
            apiKeys={this.state.apiKeys}
            deactivate={(key) => {this.deactivateApiKey(key)}}
            openAddApiKey={() => this.setState({apiModal: true})}
            closeAddApiKey={() => this.setState({apiModal: false, newApiKey: null})}
            addApiKey={() => this.addApiKey()}
            apiModalVisible={this.state.apiModal}
            newApiKey={this.state.newApiKey}
            nameChange={(name) => this.setState({newApiName: name})}
          />
        </div>
      </div>
    );
  }
}

export default Organisation;

import React, { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import { Badge } from "react-bootstrap";

import Field from '../../../components/field'
import translate from '../../../utils/translate'
import { formatDate } from '../../../utils/helper'

const getName = (activity) => {
  switch(activity.logType){
    case "client":
      return translate((activity.userAgent && activity.userAgent.isMobile)? activity.action.toUpperCase() + "_ACTIVITY_SHORT_MOBILE" : activity.action.toUpperCase() + "_ACTIVITY_SHORT")
    case "automation":
      return (activity.failed)? <div>{translate(activity.type.toUpperCase() + "_ACTIVITY_SHORT")} <Badge variant="danger">ERROR</Badge></div> : translate(activity.type.toUpperCase() + "_ACTIVITY_SHORT")
    case "admin":
      return translate(activity.trigger.toUpperCase() + "_ACTIVITY_SHORT")
    default:
      return translate("UNKNOWN_ACTIVITY_SHORT")
  }
}

const activityObjectClass = (activity) => {
  switch(activity.logType){
    case "client":
      return (activity.action === "approve")? "basicActivityObject basicActivityObjectStart" : "basicActivityObject clientObject"
    case "automation":
      return (activity.failed)? "basicActivityObject automationObjectError" : "basicActivityObject automationObject"
    case "admin":
      return ""
    default:
      return "basicActivityObject"
  }
}

const activityContentClass = (activity) => {
  switch(activity.logType){
    case "client":
      return "basicActivityContent basicActivityContentClient"
    case "automation":
      return "basicActivityContent"
    case "admin":
      return ""
    default:
      return "basicActivityContent"
  }
}

const getExtendedAutomationCard = (activity) => {
  switch(activity.type){
    case "email":
      return (
        <div className="activityContentExtended">
          <div className="basicActivityField basicActivityTimestamp">
            {formatDate(new Date(activity.ts))}
          </div>
          <div className="basicActivityField basicActivityName">
            {getName(activity)}
          </div>
          <div className="activityContentExtendedInner">
            <div className="basicInfoDataHeader">
              {translate("EMAIL_TO")}
            </div>
            <div className="basicInfoDataValue">
              {(activity.data && activity.data.content)? activity.data.content.to : "Unknown"}
            </div>
            {(activity.failed)?
              <div className="fieldSpace">
                <div className="basicInfoDataHeader">
                  {translate("AUTOMATION_WEBHOOK_ERRORMESSAGE")}
                </div>
                <div className="basicInfoDataValue fieldSpace">
                  <Field
                    name={`${activity.key}_error_field`}
                    value={activity.error}
                    groupStyle={{width: "100%"}}
                    disabled
                  />
                </div>
              </div>
              :
              ""
            }
          </div>
        </div>
      )
    case "webhook":
      return (
        <div className="activityContentExtended">
          <div className="basicActivityField basicActivityTimestamp">
            {formatDate(new Date(activity.ts))}
          </div>
          <div className="basicActivityField basicActivityName">
            {getName(activity)}
          </div>
          <div className="activityContentExtendedInner">
            <div className="basicInfoDataHeader">
              {translate("AUTOMATION_WEBHOOK_URL")}
            </div>
            <div className="basicInfoDataValue">
              <Badge variant="secondary" style={{marginRight: "6px"}}>{(activity.method)? activity.method.toUpperCase() : ""}</Badge> {(activity.url)? activity.url.split("://")[1] : "-"}
            </div>
            {(activity.failed)?
              <div className="fieldSpace">
                <div className="basicInfoDataHeader">
                  {translate("AUTOMATION_WEBHOOK_ERRORMESSAGE")}
                </div>
                <div className="basicInfoDataValue fieldSpace">
                  <Field
                    name={`${activity.key}_error_field`}
                    value={activity.error}
                    groupStyle={{width: "100%"}}
                    disabled
                  />
                </div>
              </div>
              :
              ""
            }
          </div>
        </div>
      )
    default:
      return (
        <div className="activityContentExtended">
          <div className="basicActivityField basicActivityTimestamp">
            {formatDate(new Date(activity.ts))}
          </div>
          <div className="basicActivityField basicActivityName">
            {getName(activity)}
          </div>
        </div>
      )
  }
}

const getExtendedContent = (activity) => {
  switch(activity.logType){
    case "automation":
      return getExtendedAutomationCard(activity)
    case "client":
      return (
        <div className="activityContentExtended">
          <div className="basicActivityField basicActivityTimestamp">
            {formatDate(new Date(activity.ts))}
          </div>
          <div className="basicActivityField basicActivityName">
            {getName(activity)}
          </div>
          {(activity.action === "approve")?
            <div className="activityContentExtendedInner">
              <div className="basicInfoDataHeader">
                {translate("APPROVED_BY_USER")}
              </div>
              <div className="basicInfoDataValue">
                {activity.user}
              </div>
            </div>
            :
            <div className="activityContentExtendedInner">
              <div className="basicInfoDataHeader">
                {translate("CLIENT_DEVICE")}
              </div>
              <div className="basicInfoDataValue">
                {(activity.userAgent.isMobile)? translate("CLIENT_DEVICE_PHONE") : translate("CLIENT_DEVICE_COMPUTER")} - {activity.userAgent.browser} v.{activity.userAgent.version}
              </div>
            </div>
          }
        </div>
      )
    case "admin":
    default:
      return (
        <div className="activityContentExtended">
          <div className="basicActivityField basicActivityTimestamp">
            {formatDate(new Date(activity.ts))}
          </div>
          <div className="basicActivityField basicActivityName">
            {getName(activity)}
          </div>
        </div>
      )
  }
}

function BasicInfo(props, index) {
  const [extended, setExtended] = useState(false);
  if(!extended){
    return (
      <div className="activity" onClick={() => setExtended(!extended)}>
        <div className="basicActivityPath">
          <span className={activityObjectClass(props)}/>
          <div className="activityObject-after" />
        </div>
        <div className={activityContentClass(props)}>
          <div className="basicActivityField basicActivityTimestamp">
            {formatDate(new Date(props.ts))}
          </div>
          <div className="basicActivityField basicActivityName">
            {getName(props)}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="activity activityExtended" onClick={() => setExtended(!extended)}>
      <div className="basicActivityPath">
        <span className={activityObjectClass(props)}/>
        <div className="activityObject-after" />
      </div>
      {getExtendedContent(props)}
    </div>
  );
}

export default BasicInfo;

import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap'

import translate from '../../utils/translate'

function ConfirmationModal(props) {
  return (
    <Modal style={{color:"black"}} size={props.size || "sm"} show={props.visible} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title style={{color:"black"}}>{props.title || translate("CONFIRMATIONMODAL_HEADER")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.body || translate("CONFIRMATIONMODAL_DEFAULT_BODY")}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onCancel()}>{props.cancelText || translate("CONFIRMATIONMODAL_CANCEL")}</Button>
        <Button variant="primary" onClick={() => props.onConfirm()}>{props.continueText || translate("CONFIRMATIONMODAL_CONTINUE")}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationModal;

import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import { Button, Spinner } from 'react-bootstrap';

import { apiRequest } from '../../utils/api';
import translate from '../../utils/translate';

import CreateModal from './components/createModal';

import './style.css';

const defaultModalData = {
  vehicle: "Skoda Octavia 2.0 RS",
  includedKm: "120 000 km",
  rim: `Dezent TR Black 18"`
}

const FORM_POLL_TIMEOUT = 15000; //Timeout for form polling in ms

class Demo extends Component {
  constructor(){
    super();
    this.state = {
      data: [],
      modalData: defaultModalData,
      modalVisible: false,
      creating: false,
      error: null
    }
  }

  async pollForm(props){
    const { hash } = props
    console.log("Checking form", props.hash)
    try{
      const req = await apiRequest({
        method: "get",
        url: `demo/form/${hash}`
      })

      if(req.state > 1){
        return (props.onComplete)? props.onComplete() : true
      }

      if(req.state > 0 && props.onOpened){
        await props.onOpened()
      }

      setTimeout(() => {this.pollForm(props)}, FORM_POLL_TIMEOUT)
    }catch(ex){
      console.log(ex)
      this.setState({ error: ex.message })
    }
  }

  async createForm(){
    const { recipient, vehicle, estDelvivery, rim, includedKm } = this.state.modalData
    this.setState({
      creating: true,
    })
    try{
      const req = await apiRequest({
        method: "post",
        url: "demo/create",
        data: {
          recipient,
          data: {
              Vehicle: vehicle,
              EstimatedDeliveryDate: estDelvivery,
              RimModel: rim
          }
        }
      })
      const newVehicleObj = {
        hash: req.hash,
        recipient,
        vehicle,
        estDelvivery,
        rim,
        includedKm,
        state: 0
      }
      this.setState((state) => {
        return {
          modalData: defaultModalData,
          modalVisible: false,
          creating: false,
          data: (state.data)? [...state.data, newVehicleObj] : [newVehicleObj]
        }
      })
      setTimeout(() => {
        const onComplete = () => {
          this.setState((state) => {
            const forms = state.data
            const form = forms.find(row => row.hash === newVehicleObj.hash)
            form.state = 2
            return {
              data: forms
            }
          })
        }
        const onOpened = () => {
          this.setState((state) => {
            const forms = state.data
            const form = forms.find(row => row.hash === newVehicleObj.hash)
            form.state = 1
            return {
              data: forms
            }
          })
        }
        this.pollForm({
          hash: newVehicleObj.hash,
          onComplete,
          onOpened
        })
      },FORM_POLL_TIMEOUT)
    }catch(ex){
      console.log(ex)
      this.setState({ error: ex.message, creating: false })
    }
  }

  getTableRows(){
    const { data } = this.state
    if(!data){
      return;
    }

    return data.map((vehicle, i) => {
      const estDeliveryDate = new Date(vehicle.estDelvivery)
      return (
        <div className={(i % 2 === 0)? "demoTableRowEven" : "demoTableRowUneven"}>
          <div className="demoTableField">
            {vehicle.vehicle}
          </div>
          <div className="demoTableField">
            {`${estDeliveryDate.getDate()}.${estDeliveryDate.getMonth()}.${estDeliveryDate.getFullYear()}`}
          </div>
          <div className="demoTableField">
            {vehicle.rim}
          </div>
          <div className="demoTableField">
            {(vehicle.state === 0)?
              <div>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="info"
                />
                Odottaa
              </div>
              :
              (vehicle.state === 1)?
                <div>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="info"
                  />
                  Odottaa (Avattu)
                </div>
               : "Valmis"}
          </div>
        </div>
      )
    })
  }

  getTable(){
    return (
      <div className="demoTable">
        <div className="demoTableHeader">
          <div className="demoTableField">
            {translate("DEMO_TABLE_COLUMN_VEHICLE")}
          </div>
          <div className="demoTableField">
            {translate("DEMO_TABLE_COLUMN_ESTDELIVERY")}
          </div>
          <div className="demoTableField">
            {translate("DEMO_TABLE_COLUMN_RIM")}
          </div>
          <div className="demoTableField">
            {translate("DEMO_TABLE_COLUMN_RIMDELIVERYCONFIRMED")}
          </div>
        </div>
        <div className="templateTableContent">
          {this.getTableRows()}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="demoContainer">
        <CreateModal
          data={this.state.modalData}
          visible={this.state.modalVisible}
          onCreate={() => {this.createForm()}}
          onHide={() => {this.setState({ modalData: defaultModalData, modalVisible: false, creating: false })}}
          onChange={({key, value}) => {this.setState(state => {
            return {
              modalData: {...state.modalData, [key]: value}
            }
          })}}
          creating={this.state.creating}
        />
        {this.getTable()}
        <div className="demoNewButton">
          <Button
            variant="primary"
            type="submit"
            onClick={() => this.setState({modalVisible: true})}
          >
            <div className="templatesNewButton">
              <div className="templatesNewButtonIcon">
                <Icon>add</Icon>
              </div>
              {translate("BUTTON_NEW")}
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default Demo;
